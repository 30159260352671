export const FileStatusEnum = Object.freeze({
    Uploaded: 0,
    LaunchContainer: 1,
    ProcessingObjects: 2,
    ProcessingPropertiesAndGeometries: 3,
    Failed: 4,
    FailedValidation: 5,
    Succeeded: 6,
    Deleted: 7,
    Generating: 8,
    PartiallySucceeded: 9
});

export const FileTypeEnum = Object.freeze({
    IFC: 0,
    Excel: 1
});

export const PropertyDataTypeEnum = Object.freeze({
    String: 0,
    Int32: 1,
    Int64: 2,
    Decimal: 3,
    DateTime: 4,
    Bool: 5
});

export const ParameterTypeEnum = Object.freeze({
    Tenant: 0,
    System: 1
});

export const PropertyDataTypeForLookupsEnum = Object.freeze({
  String: 0,
  Int32: 1,
  Int64: 2,
  Decimal: 3
});

export const PropertyValueTypeEnum = Object.freeze({
    Int32: 0,
    Int64: 1,
    Decimal: 2,
    DateTime: 3,
    String: 4,
    Bool: 5
});

export default {
    FileStatusEnum,
    FileTypeEnum,
    PropertyDataTypeEnum
};
