import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxExport = _resolveComponent("DxExport")!
  const _component_DxSearchPanel = _resolveComponent("DxSearchPanel")!
  const _component_DxPaging = _resolveComponent("DxPaging")!
  const _component_DxPager = _resolveComponent("DxPager")!
  const _component_DxFilterPanel = _resolveComponent("DxFilterPanel")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString($options.getTranslate("ListCategories")), 1),
    _createVNode(_component_DxDataGrid, {
      id: "categoriesListGrid",
      "data-source": $data.categories,
      "show-borders": true,
      "column-resizing-mode": 'nextColumn',
      "column-auto-width": "true",
      width: "100%",
      "column-min-width": 150,
      "show-column-lines": true,
      "show-row-lines": true,
      "allow-column-resizing": true,
      onExporting: $options.onExporting
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxExport, { enabled: true }),
        _createVNode(_component_DxSearchPanel, {
          visible: true,
          width: 240
        }),
        _createVNode(_component_DxPaging, { "page-size": 25 }),
        _createVNode(_component_DxPager, {
          "allowed-page-sizes": [25, 50, 100, 200],
          "display-mode": "full",
          "show-page-size-selector": true,
          "show-info": true,
          "show-navigation-buttons": true,
          visible: true,
          "info-text": $data.labels.reusablePaginationLabel
        }, null, 8, ["info-text"]),
        _createVNode(_component_DxFilterPanel, { visible: true }),
        _createVNode(_component_DxHeaderFilter, {
          "allow-search": true,
          visible: true
        }),
        _createVNode(_component_DxFilterRow, {
          visible: true,
          "apply-filter": 'auto'
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "CategoryName",
          caption: $options.getTranslate('CategoryName')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "IfcTypeValue",
          caption: $options.getTranslate('FileTypeIFC')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "InteraxoBimData",
          caption: $options.getTranslate('InteraxoBIMData')
        }, null, 8, ["caption"])
      ]),
      _: 1
    }, 8, ["data-source", "onExporting"])
  ]))
}