
import store from "../../store";
import { formatMessage } from "devextreme/localization";
import { defaultAPI, parameterAPI } from "@/utils/api";
import { PublishedDataService } from "../data-grid/PublishedDataService";
import { ParameterWithSourceNames } from "@swg/api"

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxPager,
  DxPaging,
  DxColumnChooser,
  DxSorting
} from "devextreme-vue/data-grid";

export default {
  name: "ComparisonResults",
  components: {
    DxSearchPanel,
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxColumnChooser,
    DxSorting
  },
  props:  {
    comparisonResultsProp: {
      type:  Function,
      required: true,
    }
  },
  emits: [],
  data() {
    return {
      details: false,
      results: [],
      comparisonResults:[],
      comparisonDetails:[],
      userCreatedProperties: [],
      assetParameters: [] as ParameterWithSourceNames[],
      flatNameToPropertyName: new Map<string, string>(),
      propertyNameToFlatName: new Map<string, string>(),
      defaultDisplayedColumns: [
        "revisionType",
        "complexCommonName",
        "buildingCommonName",
        "modelCommonName",
        "levelCommonName",
        "levelName",
        "ifcTypeValue",
        "commonName",
        "designation",
        "fmGuid",
        "inRoomCommonName",
        "inRoomDesignation",
        "roomName",
        "roomNumber",
        "dateCreated",
        "dateModified",
        "dateExpired",
        "createdInModel"
      ],
      defaultHiddenColumns: [
        "tenantId",
        "_id",
        "roomChanged",
        "levelChanged",
        "commonNameChanged",
        "designationChanged",
        "nameChanged",
        "bimObjectId",
        "revisionId",
        "externalId",
        "complexFmGuid",
        "complexDesignation",
        "buildingFmGuid",
        "buildingDesignation",
        "modelBimObjectId",
        "modelDisciplineId",
        "levelDesignation",
        "levelFmGuid",
        "levelBimObjectId",
        "levelNumber",
        "inRoomBimObjectId",
        "inRoomFmGuid",
        "fromRoomDesignation",
        "fromRoomCommonName",
        "toRoomDesignation",
        "toRoomCommonName",
        "recordStatus"
      ],
      dataGridPageSize: 25,
      columnsFromAssetParametersLoaded: false
    }
  },
  computed: {
    dataGrid: function() {
      return this.$refs["dataGridRefKey"].instance;
    }
  },
  watch: {
    comparisonResultsProp: {
      immediate: true,
      handler (comparisonResults) {
        this.results = comparisonResults;
        store.dispatch("setLoader", false);
      }
    }
  },
  methods: {
    getTranslate(text) {
      return formatMessage(text);
    },
    async onCellClick(e) {
      if(!this.columnsFromAssetParametersLoaded)
      {
        await this.getAssetParameters();
      }
      this.setFlatPropertyNames();

      if (e.rowType !== "header") {
        store.dispatch("setLoader", true);
        this.userCreatedProperties.forEach(col => {
          this.dataGrid.deleteColumn(col)
        });

        const recordStatusForPost = this.transformRecordStatus(e.column.name);
        const payload = this.createQueryPayload(e.data.modelId, e.data.firstRevisionId, e.data.secondRevisionId, recordStatusForPost, e.data.ifcTypeValue);

        await defaultAPI.postPublisheddataservicegetcomparison({
          postPublisheddataservicegetcomparisonRequest: payload
        })
          .then((response) => {
            let publishedDataServiceObject = {} as PublishedDataService;
            const comparisonData = Object.assign(response, publishedDataServiceObject);
            this.comparisonDetails = comparisonData.data;

            let headings = [];
            this.comparisonDetails.forEach(data => {
              const dataKey = Object.keys(data);

              dataKey.forEach(key => {
                let val;
                if(headings.indexOf(key) === -1) {
                  headings.push(key);
                  val = data[key] === null ? "undefined" : data[key];
                }

                const isObject = typeof val === "object";
                if (isObject) {
                  this.dataGrid.deleteColumn(key);
                }
              });
            });

            let addedCols = {};

            headings.forEach(element => {
              let value;

              comparisonData.data.forEach(elem => {
                const headingDataValue = elem[element];
                if(headingDataValue !== undefined && headingDataValue !== null && typeof headingDataValue === "object") {
                  value = headingDataValue
                }
              })
              const caption = value?.name.substring(value?.name.indexOf("|") + 1, value?.name.length);
              if (!this.dataGrid.columnOption(element) && !this.dataGrid.columnOption(value?.name) && !this.dataGrid.columnOption(caption) && !Object.values(this.defaultHiddenColumns).includes(element)) {
                if (typeof value === "object" && value != null) {
                  comparisonData.data.forEach(dataRow => {
                    const cell = dataRow[element];
                    if (cell !== undefined) {
                      dataRow[element] = cell.value;
                    }
                  });
                  addedCols[value.name] = value.value;
                }
              }
            });
            this.dataGrid.getVisibleColumns().forEach(column => {
              if (Object.keys(addedCols).includes(column.name)) {
                this.userCreatedProperties.push(column.name);
              }
            });
          })
          .catch((error) => {
            store.dispatch("setLoader", false);
            console.error(error);
          });
      }
    },
    setFlatPropertyNames() {
      this.assetParameters.forEach((x: ParameterWithSourceNames) => {
        const parameter = x.parameter;
        const flatPropertyName = x.flatPropertyName;

        this.flatNameToPropertyName.set(flatPropertyName, parameter.name);
        this.propertyNameToFlatName.set(parameter.name, flatPropertyName);
      });
    },
    transformRecordStatus(status) {
      const dict = {
        "_new": "N",
        "changed": "C",
        "unchanged": "U",
        "deleted": "D",
        "revived": "R"
      };

      return dict[status];
    },
    createQueryPayload(modelId, revisionId1, revisionId2, recordStatus, ifcTypeValue) {
      const payload = {} as any;

      payload.modelId = modelId;
      payload.revisionId1 = revisionId1;
      payload.revisionId2 = revisionId2;
      payload.recordStatus = recordStatus;
      payload.ifcTypeValue = ifcTypeValue;

      return payload;
    },
    onCellPrepared(e) {
      if (e.data && e.column && e.column.name != undefined) {
        const recordStatus = e.data.recordStatus;
        const cellStatus = e.column.name.substring(0, e.column.name.indexOf("|") - 1);
        const colourDict = {
          "N": "greenColumn",
          "C": "amberColumn",
          "U": "grayColumn",
          "D": "redColumn",
          "R": "blueColumn"
        };

        const excludeList = [
          "revisionType",
          "id",
          "bimObjectId",
          "bimObjectId",
          "dateCreated",
          "dateModified",
          "revisionId"
        ]

        const dbData = this.comparisonDetails.find(x => x.externalGuid == e.data.externalGuid);
        let dbValue;

        if(dbData !== undefined) {
          dbValue = dbData[e.column.name];
        }

        const cellValue = e.data[e.column.name]

        if(dbValue !== cellValue && !excludeList.includes(e.column.name)) {
          e.cellElement.classList.add(colourDict[recordStatus]);
        }

        if (recordStatus !== "C") {
          e.cellElement.classList.add(colourDict[recordStatus]);
        }

        if (cellStatus) {
          let dataRequired = cellStatus === "N" ? true : false;
          const conditionForNew = dataRequired && e.data[e.column.dataField] !== null && e.data[e.column.dataField] !== undefined;

          if (conditionForNew || !dataRequired) {
            e.cellElement.classList.add(colourDict[cellStatus]);
          }
        }

        const roomChanged = e.data.roomChanged && this.isRoomProperty(e.column.name);
        const levelChanged = e.data.levelChanged && this.isLevelProperty(e.column.name);
        const commonNameChanged = e.data.commonNameChanged && e.column.name === "commonName";
        const designationChanged = e.data.designationChanged && e.column.name === "designation";
        const nameChanged = e.data.nameChanged && e.column.name === "name";

        if (roomChanged || levelChanged || commonNameChanged || designationChanged || nameChanged) {
          e.cellElement.classList.add(colourDict[recordStatus]);
        }
      }
    },
    hideSpinner() {
      store.dispatch("setLoader", false);
    },
    async customizeColumns(cols) {
      cols.forEach((column) => {
        const captionToUse = this.flatNameToPropertyName.get(column.dataField);
        // if the name of the column is in the hidden list, hide it away into the column picker.
        if (this.defaultHiddenColumns.includes(column.name)) {
          column.visible = false;
        }
        // if we have a caption from the property name list, use it. If not it will default to the dataGrid's default behaviour (attempts to split words based on case).
        if (captionToUse !== undefined) {
          column.caption = captionToUse;
        }
      })
    },
    async getAssetParameters() {
      return parameterAPI.getAllParameters()
        .then((response) => {
          this.assetParameters = response.sort((a, b) => (a.parameter.name.toLowerCase() > b.parameter.name.toLowerCase()) ? 1 : -1);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    isRoomProperty(colName) {
      const roomProperties = [
        "inRoomBimObjectId",
        "inRoomFmGuid",
        "inRoomDesignation",
        "inRoomCommonName",
        "roomName",
        "roomNumber",
        "fromRoomBimObjectId",
        "fromRoomFmGuid",
        "fromRoomDesignation",
        "fromRoomCommonName",
        "toRoomBimObjectId",
        "toRoomFmGuid",
        "toRoomDesignation",
        "toRoomCommonName"
      ];

      return roomProperties.includes(colName);
    },
    isLevelProperty(colName) {
      const levelProperties = [
        "levelBimObjectId",
        "levelCommonName",
        "levelDesignation",
        "levelFmGuid",
        "levelName",
        "levelNumber"
      ];

      return levelProperties.includes(colName);
    },
  }
}
