<template>
  <div class="content-block">
    <div class="dx-field">
      <div class="dx-field-label">
        {{ switchLabel }}
      </div>
      <div class="dx-field-value">
        <DxSwitch
          :value="true"
          @valueChanged="switchChange"
        />
      </div>
    </div>
    <div class="grid-container">
      <DxDataGrid
        id="grid"
        :data-source="filesMetadata"
        :show-borders="true"
        :column-resizing-mode="nextColumn"
        :column-auto-width="true"
        :allow-column-resizing="true"
        key-expr="fileId"
      >
        <DxSearchPanel :visible="true" />
        <DxPaging :enabled="false" />
        <DxEditing mode="popup">
          <DxTexts confirm-delete-message="" />
        </DxEditing>
        <DxPaging :page-size="dataGridPageSize" />
        <DxPager
          :visible="true"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
          :allowed-page-sizes="[25, 50, 100, 200]"
        />
        <DxColumn
          data-field="name"
          :caption="columnHeaderTranslate('ReusableNameLabel')"
          :width="370"
        />
        <DxColumn
          data-field="size"
          :caption="columnHeaderTranslate('Size')"
          :calculate-cell-value="convertDataSize"
        />
        <DxColumn
          :width="125"
          :caption="columnHeaderTranslate('Validate')"
          data-field="numberOfObjects"
          cell-template="validateTemplate"
        />
        <DxColumn
          data-field="dateUploaded"
          :caption="columnHeaderTranslate('DateUploaded')"
          data-type="datetime"
        />
        <DxColumn
          data-field="fileStatus"
          :caption="columnHeaderTranslate('FileStatus')"
          :calculate-cell-value="getFileStatusTranslate"
        />
        <DxColumn
          :caption="columnHeaderTranslate('Progress')"
          data-field="numberOfObjectsProcessed"
          cell-template="progressBarTemplate"
        />
        <DxColumn
          :caption="columnHeaderTranslate('DownloadFile')"
          cell-template="downloadFileTemplate"
          :width="100"
        />
        <DxColumn
          data-field="fileType"
          :caption="columnHeaderTranslate('FileType')"
          :calculate-cell-value="getFileType"
          :width="120"
        />
        <DxColumn
          data-field="modelId"
          cell-template="errorLogTemplate"
          :caption="columnHeaderTranslate('ErrorLog')"
          :width="80"
        />
        <template #progressBarTemplate="{ data }">
          <DxProgressBar
            id="progress-bar-status"
            :class="{ complete: progressValue(data) >= 100 }"
            :min="0"
            :max="100"
            :value="progressValue(data)"
            width="100%"
          />
        </template>
        <template #errorLogTemplate="{ data }">
          <DxButton
            class="button-no-margin"
            icon="download"
            :disabled="!permissions.ifc.canLogDownload"
            @click="downloadLogs(data.data.fileId,data.data.tenantId, data.data.filePath, data.data.name,data.data.fileStatus)"
          />
        </template>
        <template #validateTemplate="{ data }">
          <DxButton
            v-if="data.data.fileStatus === Enums.FileStatusEnum.Succeeded"
            :visible="showValidateButton(data)"
            class="button-no-margin"
            icon="showpanel"
            :disabled="!permissions.ifc.canValidate"
            @click="redirectToMappings(data.data.modelId)"
          />
        </template>
        <template #downloadFileTemplate="{ data }">
          <DxButton
            v-if="data.data.fileStatus === Enums.FileStatusEnum.Succeeded"
            class="button-no-margin"
            icon="download"
            :disabled="!permissions.ifc.canDownload"
            @click="downloadExportFile(data.data.tenantId, data.data.filePath, data.data.name)"
          />
        </template>
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxSearchPanel,
  DxColumn,
  DxPaging,
  DxEditing,
  DxTexts,
} from "devextreme-vue/data-grid";
import store from "../store";
import Enums from "../enums/index";
import ConfigValues from "../utils/configValues";
import fileDownload from "js-file-download";
import notify from "devextreme/ui/notify";
import DxButton from "devextreme-vue/button";
import { DxProgressBar } from "devextreme-vue/progress-bar";
import { formatMessage } from "devextreme/localization";
import { DxSwitch } from "devextreme-vue/switch";
import { useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { logAPI, fileAPI } from "../utils/api";
import { DownloadFileFromAzure } from "../utils/fileDownload";
import auth from "../authsrc/auth";
import FileUploadOnPremise from "../utils/fileUploadOnPremise";

export default {
  components: {
    DxDataGrid,
    DxSearchPanel,
    DxColumn,
    DxPaging,
    DxEditing,
    DxTexts,
    DxProgressBar,
    DxButton,
    DxSwitch,
  },
  setup() {
    const router = useRouter();
    function redirectToMappings(modelId) {
      store.dispatch("setLoader", true);
      router.push({
        path: "/mappings",
        query: { modelId: modelId },
        params: { modelId: modelId },
      });
    }

    onMounted(() => {
      // Clear data grid state before rendering
      store.dispatch("setFilesMetadata", null);
    });

    return {
      redirectToMappings,
    };
  },
  data() {
    return {
      activeFilesSelected: true,
      refreshDataIntervalValue:
        ConfigValues.GetNr("VUE_APP_FILEPAGE_REFRESH_INTERVAL_SECONDS") || 10,
      refreshDataInterval: "",
      numberOfItemsInResponse: 0,
      switchLabel: formatMessage("ShowInactiveFiles"),
      switchData: false,
      Enums,
      FileStatusLabelTranslate: formatMessage("FileStatus"),
      filesMetadata: store.getters.getFilesMetadata,
      permissions: auth.getPermissions(),
      dataGridPageSize: 20,
    };
  },
  computed: {
    getFiles() {
      return store.getters.getFilesMetadata;
    },
  },
  watch: {
    getFiles(files) {
      this.filesMetadata = files;
    },
  },
  created() {
    store.dispatch("setLoader", true);
    this.setRefreshDataInterval();
  },
  unmounted() {
    clearInterval(this.refreshDataInterval);
  },
  methods: {
    progressValue(fileMetadata) {
      // consider setting as global variable
      const startString = "numberOf";
      const endString = "Processed";
      const instanceString = "numberOfGeometries"

      let objects = 0,
          objectsProcessed = 0;

      if (fileMetadata.data.isExport){
        if(fileMetadata.data.fileStatus == Enums.FileStatusEnum.Generating)
          return 10;
        if(fileMetadata.data.fileStatus == Enums.FileStatusEnum.ProcessingObjects)
          return 50;
        if (fileMetadata.data.fileStatus == Enums.FileStatusEnum.Succeeded)
          return 100;
      }

      for (let item in fileMetadata.data) {
        if(item.startsWith(instanceString)) { continue; }
        if (item.startsWith(startString) && !item.endsWith(endString)) {
          objects += parseInt(fileMetadata.data[item]) || 0;
        }
        if (item.startsWith(startString) && item.endsWith(endString)) {
          objectsProcessed += parseInt(fileMetadata.data[item]) || 0;
        }
      }

      if (fileMetadata.data.numberOfObjects)
        return (objectsProcessed / objects) * 100;

      return 0;
    },
    getFileStatusTranslate(data) {
      switch (data.fileStatus) {
        case Enums.FileStatusEnum.Uploaded:
          return formatMessage("FileStatusUploaded");
        case Enums.FileStatusEnum.LaunchContainer:
          return formatMessage("FileStatusLaunchContainer");
        case Enums.FileStatusEnum.ProcessingObjects:
          return formatMessage("FileStatusProcessingObjects");
        case Enums.FileStatusEnum.ProcessingPropertiesAndGeometries:
          return formatMessage("FileStatusProcessingPropertiesAndGeometries");
        case Enums.FileStatusEnum.Failed:
          return formatMessage("FileStatusFailed");
        case Enums.FileStatusEnum.Succeeded:
          return formatMessage("FileStatusSucceeded");
        case Enums.FileStatusEnum.Deleted:
          return formatMessage("FileStatusDeleted");
        case Enums.FileStatusEnum.Generating:
          return formatMessage("FileStatusGenerating");
        case Enums.FileStatusEnum.PartiallySucceeded:
          return formatMessage("FileStatusPartiallySucceeded");
        case Enums.FileStatusEnum.FailedValidation:
          return formatMessage("FileStatusFailedValidation");
      }
    },
    getFileType(data) {
      if (data.fileType === Enums.FileTypeEnum.IFC) {
        return formatMessage("FileTypeIFC");
      }
      else if (data.fileType === Enums.FileTypeEnum.Excel) {
        return formatMessage("FileTypeExcel");
      }
      else {
        return formatMessage("FileTypeUnknown");
      }
    },
    columnHeaderTranslate(label) {
      return formatMessage(label);
    },
    convertDataSize(data) {
      if (data.size < 1000000) {
        return parseFloat(data.size / 1024).toFixed(2) + "KB";
      } else {
        return parseFloat(data.size / 1024 / 1024).toFixed(2) + "MB";
      }
    },
    async getFilesMetadata(active) {
      await fileAPI.files({ active: active })
        .then((response) => {
          const objectsInResponse = typeof response == "object";
          let dataHasChanged = false;
          store.dispatch("setLoader", false);

          if (objectsInResponse) {
            dataHasChanged =
              JSON.stringify(this.getFiles) != JSON.stringify(response);

            if (dataHasChanged) {
              store.dispatch("setFilesMetadata", response);
            }
          }
        })
        .catch((error) => {
          store.dispatch("setLoader", false);
          notify(formatMessage("GetFilesError"), "error", 3000);
          console.error(error);
        });
    },
    switchChange(switchChange) {
      // Clear data grid on toggle switch
      store.dispatch("setFilesMetadata", null);

      if (switchChange.value) {
        this.switchLabel = formatMessage("ShowActiveFiles");
      } else {
        this.switchLabel = formatMessage("ShowInactiveFiles");
      }

      this.activeFilesSelected = switchChange.value;
      this.setRefreshDataInterval();
      store.dispatch("setLoader", true);
    },
    async downloadLogs(fileId,tenantId,filePath,fileName,fileStatus) {
      store.dispatch("setLoader", true);
      if(fileName.includes(".xlsx") && fileStatus != Enums.FileStatusEnum.FailedValidation){
        fileName = fileName.replace(".xlsx",".txt")
        this.downloadExportFile(tenantId, filePath, fileName)
      }else{
        await logAPI.getIFCFileLogs({ file: fileId } )
          .then((response) => {
            fileDownload(response, `log_${fileId}.txt`);
          })
          .catch((error) => {
            store.dispatch("setLoader", false);
            notify(formatMessage("DownloadLogsError"), "error", 5000);
            console.error(error);
          });
      }
      store.dispatch("setLoader", false);
    },
    async downloadExportFile(tenantId, filePath, fileName){
      if(!ConfigValues.onPremise){
        const sas = await fileAPI.getToken();
        await DownloadFileFromAzure(tenantId, sas.sasToken, filePath, fileName)
      }
      else{
        await FileUploadOnPremise.GetFile(filePath, fileName)
      }
    },
    setRefreshDataInterval() {
      clearInterval(this.refreshDataInterval);
      this.getFilesMetadata(this.activeFilesSelected);
      this.refreshDataInterval = setInterval(() => {
        this.getFilesMetadata(this.activeFilesSelected);
      }, this.refreshDataIntervalValue * 1000);
    },
    showValidateButton(data){
      return !data.data.isExport && !data.data.name.includes(".xlsx")
    }
  },
};
</script>

<style scoped lang="scss">
.complete .dx-progressbar-range {
  background-color: green;
}
.dx-progressbar-range {
  background-color: #3f4aee;
  transition: none !important;
  transition-duration: 0s;
}
.dx-progressbar-container {
  background-color: #c6c5ff !important;
}
.complete .dx-progressbar-status {
  color: green !important;
}
.dx-progressbar-status {
  color: #3f4aee !important;
}
.button-no-margin {
  margin: 0 !important;
}
.dx-field {
  max-width: 500px;
}
.dx-field-value {
  max-width: 50px;
}
#grid {
  min-width: 800px;
}
</style>
