import { UserRoleEnum } from "@swg/api";

export interface Roles {
    [key: string]: Permissions,
}

export interface FlatPermission{
    key: string,
    name: string,
    viewer: string,
    editor: string,
    admin: string
}

export interface Permissions
{
    complex: {
        canView: boolean,
        canCreate: boolean,
        canEdit: boolean,
        canDelete: boolean
    },
    entities: {
        canView: boolean,
        canCreate: boolean,
        canEdit: boolean,
        canDelete: boolean
    },
    models: {
        canView: boolean,
        canCreate: boolean,
        canEdit: boolean,
        canDelete: boolean
    },
    users: {
        canView: boolean,
        canCreate: boolean,
        canEdit: boolean,
        canDelete: boolean
    },
    userGroups: {
        canView: boolean,
        canCreate: boolean,
        canEdit: boolean,
        canDelete: boolean
    },
    permissions: {
        canView: boolean,
        canCreate: boolean,
        canEdit: boolean,
        canDelete: boolean
    },
    parameters: {
        canView: boolean,
        canCreate: boolean,
        canEdit: boolean,
        canDelete: boolean
    },
    parameterGroups: {
        canView: boolean,
        canCreate: boolean,
        canEdit: boolean,
        canDelete: boolean
    },
    ifc: {
        canUpload: boolean,
        canUpdate: boolean,
        canDownload: boolean,
        canValidate: boolean,
        canLogDownload: boolean
    },
    dataGrid: {
        canView: boolean,
        canUpdate: boolean
    },
    twoDimensional: {
        canView: boolean,
        canCreate: boolean,
        canEdit: boolean,
        canDelete: boolean
    },
    threeDimensional: {
        canView: boolean,
        canCreate: boolean,
        canEdit: boolean,
        canDelete: boolean
    },
    backupRestore: {
        canRestoreBackup: boolean
    },
    categories: {
      canView: boolean,
      canCreate: boolean,
      canEdit: boolean,
      canDelete: boolean
    }
}

const emptyPermissions: Permissions = {
    complex: {
        canView: false,
        canCreate: false,
        canEdit: false,
        canDelete: false
    },
    entities: {
        canView: false,
        canCreate: false,
        canEdit: false,
        canDelete: false
    },
    models: {
        canView: false,
        canCreate: false,
        canEdit: false,
        canDelete: false
    },
    users: {
        canView: false,
        canCreate: false,
        canEdit: false,
        canDelete: false
    },
    userGroups: {
        canView: false,
        canCreate: false,
        canEdit: false,
        canDelete: false
    },
    permissions: {
        canView: false,
        canCreate: false,
        canEdit: false,
        canDelete: false
    },
    parameters: {
        canView: false,
        canCreate: false,
        canEdit: false,
        canDelete: false
    },
    parameterGroups: {
        canView: false,
        canCreate: false,
        canEdit: false,
        canDelete: false
    },
    ifc: {
        canUpload: false,
        canUpdate: false,
        canDownload: false,
        canValidate: false,
        canLogDownload: false
    },
    dataGrid: {
        canView: false,
        canUpdate: false
    },
    twoDimensional: {
        canView: false,
        canCreate: false,
        canEdit: false,
        canDelete: false
    },
    threeDimensional: {
        canView: false,
        canCreate: false,
        canEdit: false,
        canDelete: false
    },
    backupRestore: {
        canRestoreBackup: false
    },
    categories: {
      canView: false,
      canCreate: false,
      canEdit: false,
      canDelete: false
    }
};

const rolesPermissions: Roles = {
    [UserRoleEnum.Admin]: {
        complex: {
            canView: true,
            canCreate: true,
            canEdit: true,
            canDelete: true
        },
        entities: {
            canView: true,
            canCreate: true,
            canEdit: true,
            canDelete: true
        },
        models: {
            canView: true,
            canCreate: true,
            canEdit: true,
            canDelete: true
        },
        users: {
            canView: true,
            canCreate: true,
            canEdit: true,
            canDelete: true
        },
        userGroups: {
            canView: true,
            canCreate: true,
            canEdit: true,
            canDelete: true
        },
        permissions: {
            canView: true,
            canCreate: true,
            canEdit: true,
            canDelete: true
        },
        parameters: {
            canView: true,
            canCreate: true,
            canEdit: true,
            canDelete: true
        },
        parameterGroups: {
            canView: true,
            canCreate: true,
            canEdit: true,
            canDelete: true
        },
        ifc: {
            canUpload: true,
            canUpdate: true,
            canDownload: true,
            canValidate: true,
            canLogDownload: true,
        },
        dataGrid: {
            canView: true,
            canUpdate: true
        },
        twoDimensional: {
            canView: true,
            canCreate: true,
            canEdit: true,
            canDelete: true
        },
        threeDimensional: {
            canView: true,
            canCreate: true,
            canEdit: true,
            canDelete: true
        },
        backupRestore: {
            canRestoreBackup: true
        },
        categories: {
          canView: true,
          canCreate: true,
          canEdit: true,
          canDelete: true
        }
    },
    [UserRoleEnum.Editor]: {
        complex: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        entities: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        models: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        users: {
            canView: false,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        userGroups: {
            canView: false,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        permissions: {
            canView: false,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        parameters: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        parameterGroups: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        ifc: {
            canUpload: false,
            canUpdate: false,
            canDownload: false,
            canValidate: false,
            canLogDownload: false
        },
        dataGrid: {
            canView: true,
            canUpdate: true
        },
        twoDimensional: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        threeDimensional: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        backupRestore: {
            canRestoreBackup: false
        },
        categories: {
          canView: true,
          canCreate: false,
          canEdit: false,
          canDelete: false
        }
        },
        [UserRoleEnum.Viewer]: {
        complex: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        entities: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        models: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        users: {
            canView: false,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        userGroups: {
            canView: false,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        permissions: {
            canView: false,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        parameters: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        parameterGroups: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        ifc: {
            canUpload: false,
            canUpdate: false,
            canDownload: false,
            canValidate: false,
            canLogDownload: false
        },
        dataGrid: {
            canView: true,
            canUpdate: false
        },
        twoDimensional: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        threeDimensional: {
            canView: true,
            canCreate: false,
            canEdit: false,
            canDelete: false
        },
        backupRestore: {
            canRestoreBackup: false
        },
        categories: {
          canView: true,
          canCreate: false,
          canEdit: false,
          canDelete: false
        }
    }
};

export function getPermissionsByRole(role: UserRoleEnum): Permissions {
    if (role === null) {
        return emptyPermissions;
    }
    return rolesPermissions[role];
}

export function getFlattenedPermissions() {
    const flattedPermissions = Object.keys(rolesPermissions[UserRoleEnum.Viewer]).map(x => ({ key: x, name: _unCamelCase(x) })) as FlatPermission[];

    for(const flattedPermission of flattedPermissions) {
        for(const role of Object.keys(UserRoleEnum)) {
            if (rolesPermissions[UserRoleEnum[role]][flattedPermission.key].canEdit) {
                flattedPermission[role.toLocaleLowerCase()] = "Edit";
            } else if (rolesPermissions[UserRoleEnum[role]][flattedPermission.key].canUpload) {
                flattedPermission[role.toLocaleLowerCase()] = "All";
            } else if (rolesPermissions[UserRoleEnum[role]][flattedPermission.key].canUpdate) {
                flattedPermission[role.toLocaleLowerCase()] = "Update";
            } else if (rolesPermissions[UserRoleEnum[role]][flattedPermission.key].canView) {
                flattedPermission[role.toLocaleLowerCase()] = "View";
            } else {
                flattedPermission[role.toLocaleLowerCase()] = "-";
            }
        }
    }

    return flattedPermissions;
}

function _unCamelCase (str){
    return str
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
        .replace(/^./, (str) => str.toUpperCase())
}
