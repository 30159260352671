<template>
  <div class="parameter-mapping-menu-items">
    <DxButton
      id="removeAllMappings"
      :key="actionsKey"
      class="next-btn"
      type="danger"
      :text="getTranslate('RemoveMappings')"
      :disabled="isRemoveAllDisabled()"
      @click="showRemoveAllPopover()"
    />
  </div>
  <DxDataGrid
    id="parametersMappingGrid"
    ref="dataGridRefKey"
    :data-source="availableParameters"
    :show-borders="false"
    key-expr="parameterId"
    :allow-column-resizing="true"
    :column-resizing-mode="'nextColumn'"
    :column-min-width="150"
    :column-auto-width="true"
  >
    <DxSearchPanel
      :visible="true"
    />
    <DxPaging
      :enabled="true"
    />
    <DxEditing mode="popup">
      <DxTexts
        confirm-delete-message=""
      />
    </DxEditing>
    <DxHeaderFilter
      :visible="true"
    />
    <DxColumn
      data-field="property"
      :allow-header-filtering="false"
      :caption="getTranslate('ExternalParameters')"
    />
    <DxColumn
      data-field="propertySet"
      :allow-header-filtering="false"
      :caption="getTranslate('ExternalParameterSets')"
    />
    <DxColumn
      :allow-header-filtering="false"
      :caption="getTranslate('IsLookup')"
      :calculate-cell-value="getIsLookup"
    />
    <DxColumn
      :key="actionsKey"
      :allow-header-filtering="true"
      :allow-sorting="true"
      data-field="mapping"
      :caption="getTranslate('AssetParameters')"
      :calculate-cell-value="getParametersNames"
      :width="400"
    >
      <DxHeaderFilter
        :visible="true"
        :data-source="mappingFilter"
      />
    </DxColumn>

    <DxColumn
      :key="actionsKey"
      :allow-header-filtering="false"
      data-field="actions"
      :caption="getTranslate('Actions')"
      cell-template="actionsTemplate"
      :width="125"
    />
    <template #actionsTemplate="{ data }">
      <div class="">
        <DxButton
          v-if="!Object.prototype.hasOwnProperty.call(data.data, 'mapping')"
          class="button-no-margin"
          icon="add"
          @click="addAction(data.data)"
        />
        <DxButton
          v-if="Object.prototype.hasOwnProperty.call(data.data, 'mapping')"
          class="button-no-margin"
          icon="preferences"
          @click="editAction(data.data)"
        />
        <DxButton
          v-if="Object.prototype.hasOwnProperty.call(data.data, 'mapping')"
          class="button-no-margin"
          icon="remove"
          @click="removeAction(data.data)"
        />
      </div>
    </template>
  </DxDataGrid>

  <DxPopup
    id="mappingRulesPopup"
    v-model:visible="isPopupVisible"
    :title="getTranslate('Mappings')"
    height="auto"
    max-width="900px"
    max-height="800px"
    content-template="popup-content"
    :close-on-outside-click="false"
    :show-close-button="true"
    @hidden="onPopupHiding()"
  >
    <template #popup-content>
      <div>
        <MappingRules
          :key="mappingRulesKey"
          :selected-parameter-prop="selectedParameter"
          :parameters-prop="availableAssetParameters"
          @saveMappingToParameter="upsertMapping"
          @onCancelClick="closePopup"
        />
      </div>
    </template>
  </DxPopup>
  <DxPopover
    :width="300"
    :visible="removeAllMappingsPopoverVisible"
    target="#removeAllMappings"
    position="top"
    :shading="true"
    @hidden="onRemoveAllPopoverHide"
  >
    {{ getTranslate("ConfirmRemovingAll") }}
    <div>
      <DxButton
        id="nextButton"
        class="next-btn"
        :text="getTranslate('Yes')"
        type="danger"
        @click="removeAllMappings"
      />
      <DxButton
        id="nextButton"
        class="next-btn"
        :text="getTranslate('No')"
        type="normal"
        @click="removeAllMappingsPopoverVisible=false"
      />
    </div>
  </DxPopover>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxPopup from "devextreme-vue/popup";
import { formatMessage } from "devextreme/localization";
import MappingRules from "../sync/MappingRules.vue";
import notify from "devextreme/ui/notify";
import { DxPopover } from "devextreme-vue/popover";
import {
  DxColumn,
  DxDataGrid,
  DxEditing,
  DxHeaderFilter,
  DxPaging,
  DxSearchPanel,
  DxTexts
} from "devextreme-vue/data-grid";
import store from "../../store";
import Enums from "../../enums/index";
import { parameterAPI, propertyMappingAPI, validationAPI } from "@/utils/api";
import { getAllParameterMappingCategories } from "../../utils/categories"

export default {
  name: "ParameterMapping",
  components: {
    DxButton, DxDataGrid, DxColumn, DxSearchPanel,
    DxPaging, DxEditing, DxTexts,
    DxPopup, MappingRules, DxPopover,
    DxHeaderFilter
  },
  props: {
    parametersProp: {
      type: Array,
      required: true
    },
    availableCategoriesProp: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      mappingFilter: [
        {
          text: this.getTranslate("Mapped"),
          value: ["mapping", "<>", ""],
        },
        {
          text: this.getTranslate("Unmapped"),
          value: [
            ["mapping", "=", ""],
          ],
        }],
      actionsKey: 0,
      mappingRulesKey: 0,
      gridKey: 0,
      isPopupVisible: false,
      selectedParameter: {},
      oldStateParameter: {},
      availableParameters: [],
      removeAllMappingsPopoverVisible: false,
      assetParameters: [],
      availableAssetParameters: [],
      parameterMappingCategories : [],
    }
  },
  computed: {
    dataGrid() {
      return this.$refs["dataGridRefKey"].instance;
    }
  },
  watch: {
    parametersProp: {
      immediate: true,
      handler(parameters) {
        this.availableParameters = parameters;
        setTimeout(() => {
          store.dispatch("setLoader", false);
        }, 500);
      }
    },
    availableCategoriesProp: {
      immediate: true,
      handler(categories) {
        this.availableCategories = categories;
        this.allCategories = categories;
      }
    }
  },
  created() {
    this.getAssetParameters();
    this.getAllCategoriesForParameterMapping();
  },
  methods: {
    _parseRawcategories(element) {
      return {
        categoryId: element
      };
    },
    closePopup() {
      this.isPopupVisible = false;
    },
    addAction(data) {
      this.editAction(data);
    },
    editAction(data) {
      const matchedParameter = this.assetParameters.find((parameter) => parameter.parameter.name === data?.mapping?.name);
      if (matchedParameter) {
        data.mapping.setRule = matchedParameter.parameter.rule.setRule;
      }

      this.selectedParameter = JSON.parse(JSON.stringify(data));
      this.rerenderMappingRules();
      this.getAvailableAssetParameters();
      this.isPopupVisible = true;
    },
    removeAction(parameter) {
      if (parameter.mapping.isFmGuid === true) {
        store.dispatch("setFMGuidParameter", {});
      }
      this.deletePropertyMapping(parameter);
    },
    getParametersNames(parameter) {
      if (Object.prototype.hasOwnProperty.call(parameter, "mapping")) {
        if (parameter.mapping.isFmGuid === true) {
          return parameter.mapping.name + "(FMGUID)";
        }
        return parameter.mapping.name;
      }
      return "";
    },
    getIsLookup(x) {
      const matchedParameter = this.assetParameters.find((parameter) => parameter.parameter.name === x?.mapping?.name);

      return !!matchedParameter?.parameter?.rule?.setRule;
    },
    getTranslate(text) {
      return formatMessage(text);
    },
    onPopupHiding() {
      this.isPopupVisible = false;
    },
    async upsertMapping(parameter) {
      await store.dispatch("setLoader", true);
      let response;
      if (Object.prototype.hasOwnProperty.call(parameter.mapping, "id")) {
        response = await this.updateMapping(parameter);
        this.isPopupVisible = false;
      } else {
        response = await this.addMapping(parameter);
        if (response) {
          parameter.mapping.id = response;
        }
        this.isPopupVisible = false;
      }
      if (response) {
        const parameterIndex = this.availableParameters.findIndex(
          (param) => param.parameterId === parameter.parameterId
        );
        this.availableParameters[parameterIndex] = parameter;
        this.rerenderActions();
        this.isPopupVisible = false;
      }
      setTimeout(() => {
        store.dispatch("setLoader", false);
      }, 500);
    },
    rerenderActions() {
      this.actionsKey += 1;
      this.dataGrid.refresh(true);
    },
    showRemoveAllPopover() {
      this.removeAllMappingsPopoverVisible = true;
    },
    onRemoveAllPopoverHide() {
      this.removeAllMappingsPopoverVisible = false;
    },
    isRemoveAllDisabled() {
      const isDisabled = this.availableParameters.find((param) => Object.prototype.hasOwnProperty.call(param, "mapping") === true);
      return isDisabled === undefined;
    },
    removeAllMappings() {
      store.dispatch("setLoader", true);
      propertyMappingAPI.deleteAllPropertyMappings({
        modelId: store.getters.getSelectedModel.bimObjectId,
        revisionId: store.getters.getSelectedModel.revisionId
      }).then(() => {
        this.availableParameters.forEach((parameter) => {
          delete parameter.mapping;
        });
        store.dispatch("setFMGuidParameter", {});
        this.removeAllMappingsPopoverVisible = false;
        setTimeout(() => {
          store.dispatch("setLoader", false);
        }, 500);
      }).catch((error) => {
        console.log(error);
      });
    },
    rerenderMappingRules() {
      this.mappingRulesKey += 1;
    },

    async checkAndCreateParameter(parameter) {
      const mappedCategories = this.parameterMappingCategories.filter(c=> parameter.categories.includes(c.IfcTypeValue))
        .filter(
          (thing, i, arr) => arr.findIndex(t => t.CategoryId === thing.CategoryId) === i
        ).map(({ CategoryId }) => CategoryId);
      const parsedvalue = [];
      mappedCategories.forEach(element => {
        parsedvalue.push(this._parseRawcategories(element));
      });
      const parameterToUpdate = this.assetParameters.filter((assetParameter) => assetParameter.parameter.name.toLowerCase() === parameter.mapping.name.toLowerCase());
      if(parameterToUpdate.length > 0 && parameterToUpdate[0].parameter.parameterId)
      {
        const sourceNameAlreadyExists = parameterToUpdate[0].sourceNames.filter((sourceName) => sourceName.set.toLowerCase() === parameter.propertySet.toLowerCase());
        if(sourceNameAlreadyExists.length < 1)
        {
          await parameterAPI.updateParameter({
            parameterWithSourceNames: {
              parameter: {
                ...parameterToUpdate[0].parameter,
              },
              sourceNames:[
                ...parameterToUpdate[0].sourceNames,
                {
                  name: parameter.property,
                  set: parameter.propertySet
                }],
              categories :parsedvalue
            }
          }).catch((error) => {
            console.log(error);
          });
        }
        else
        {
          console.log("Source Names already exists");
        }
      }
      else
      {
        await parameterAPI.createParameter({
          parameterWithSourceNames: {
            parameter: {
              name: parameter.mapping.name,
              dataType: Enums.PropertyDataTypeEnum[parameter.mapping.type],
              rule: {
                unique: parameter.mapping.unique === undefined || parameter.mapping.unique === null ? false : parameter.mapping.unique ,
                required: parameter.mapping.required === undefined || parameter.mapping.required == null ? false : parameter.mapping.required ,
                isFmGuid: parameter.mapping.isFmGuid === undefined || parameter.mapping.isFmGuid === null ? false : parameter.mapping.isFmGuid
              }
            },
            sourceNames: [{
              name: parameter.property,
              set: parameter.propertySet
            }],
            categories :parsedvalue
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    async addMapping(parameter) {
      await this.checkAndCreateParameter(parameter);
      return propertyMappingAPI.addPropertyMapping(this.createAddPropertyMappingRequest(parameter)
      ).then((response) => {
        this.validateParameterMapping(response.propertyMappingId);
        return response.propertyMappingId;
      }).catch((error) => {
        if (error.response.status == 422) {
          notify(this.getTranslate("ReusableDraftChangesValidationFail"), "error", 5000);
        }
        console.log(error);
        return false;
      });
    },
    async updateMapping(parameter) {
      await this.checkAndCreateParameter(parameter);
      return propertyMappingAPI.updatePropertyMapping(this.createUpdatePropertyMappingRequest(parameter, parameter.mapping.id)
      ).then((response) => {
        this.validateParameterMapping(parameter.mapping.id);
        return response;
      }).catch((error) => {
        console.log(error);
        return false;
      });
    },
    async validateParameterMapping(mappingId) {
      await validationAPI.validateParameterMapping({
        propertyMappingWithIds: {
          propertyMappingId: mappingId,
          modelId: store.getters.getSelectedModel.bimObjectId,
          revisionId: store.getters.getSelectedModel.revisionId,

        }
      })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePropertyMapping(parameter) {
      store.dispatch("setLoader", true);
      propertyMappingAPI.deletePropertyMapping(
        {
          bIMClassesModelsAPIPayloadPropertyMappingBaseRequest: {
            propertyMappingId: parameter.mapping.id,
          }
        },
      ).then(() => {
        delete this.availableParameters.find(
          (param) => param.parameterId === parameter.parameterId
        ).mapping;
        this.isRemoveAllDisabled();
        this.rerenderActions();
        store.dispatch("setLoader", false);
      }).catch((error) => {
        console.log(error);
      });
    },
    createUpdatePropertyMappingRequest(parameter, mappingId) {
      return {
        id: mappingId,
        propertyMappingWithIds: this.createMappingWithIdMessage(parameter, mappingId)
      };
    },

    createAddPropertyMappingRequest(parameter) {
      return {
        propertyMappingWithIds: this.createMappingWithIdMessage(parameter, "")
      };
    },
    createMappingWithIdMessage(parameter, mappingId) {
      return {
        propertyMappingId: mappingId,
        modelId: store.getters.getSelectedModel.bimObjectId,
        revisionId: store.getters.getSelectedModel.revisionId,

        propertyMapping: {
          category: parameter.category,
          sourcePropertyGroup: "",
          sourceProperty: parameter.property,
          sourcePropertySet: parameter.propertySet,
          destinationPropertyGroup: "",
          destinationProperty: parameter.mapping.name,
          destinationDataType: parameter.mapping.type,
          rules: this.defineRulesArray(parameter)
        }
      };
    },
    defineRulesArray(parameter) {
      const rules = [
        {
          required: parameter.mapping.required == null ? false : parameter.mapping.required,
        },
        {
          unique: parameter.mapping.unique == null ? false : parameter.mapping.unique
        },
        {
          isFmGuid: parameter.mapping.isFmGuid == null ? false : parameter.mapping.isFmGuid
        }
      ];

      if (parameter.mapping.setRule) {
        rules.push(parameter.mapping.setRule);
      }

      if (parameter.mapping.type) {
        rules.push({
          dataType: Enums.PropertyDataTypeEnum[parameter.mapping.type]
        });
      }

      return rules;
    },
    async getAssetParameters() {
      return parameterAPI.getAllParameters()
        .then((response) => {
          this.assetParameters = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAvailableAssetParameters() {
      const mappedParametersNames = this.availableParameters.filter((parameter) => Object.prototype.hasOwnProperty.call(parameter, "mapping")).map((parameter) => parameter.mapping.name);
      this.availableAssetParameters = this.assetParameters.filter((assetParameter) => assetParameter.parameter.canBeMapped && !mappedParametersNames.includes(assetParameter.parameter.name));
    },
    async getAllCategoriesForParameterMapping() {
      this.parameterMappingCategories = await getAllParameterMappingCategories()
    }
  }
}
</script>
<style scoped>
#parametersMappingGrid {
  height: calc(100vh - 210px);
}

#parametersMappingGrid .asset-params-label {
  margin-right: 30px;
  vertical-align: middle !important;
  float: left !important;
  line-height: 36px !important;
}

.no-margin {
  margin: 0 !important;
}

.parameter-mapping-menu-items {
  display: inline-block !important;
  width: 100% !important;
}

#removeAllMappings {
  float: right
}
:global(.dx-datagrid-rowsview .dx-row) {
  font-size: 12px !important;
  padding-bottom: 2px !important;
  padding-top:2px !important;
}
:global(.dx-datagrid .dx-row) {
  font-size: 12px !important;
  padding-bottom: 2px !important;
  padding-top:2px !important;
}
</style>
