import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "widget-container" }
const _hoisted_2 = { style: {"display":"inline-block","width":"100%","height":"100%"} }
const _hoisted_3 = { style: {"display":"inline-block","margin-top":"10px","float":"right"} }
const _hoisted_4 = { style: {"float":"left"} }
const _hoisted_5 = { style: {"float":"right"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTextArea = _resolveComponent("DxTextArea")!
  const _component_DxFileUploader = _resolveComponent("DxFileUploader")!
  const _component_DxButton = _resolveComponent("DxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DxTextArea, {
        label: "Please Read These Instructions Before Uploading Excel",
        "auto-resize-enabled": true,
        "read-only": true,
        value: _ctx.excelHints
      }, null, 8, ["value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_DxFileUploader, {
          id: "importButton",
          type: "success",
          class: "uploader-like-button actionButton",
          "select-button-text": "Select Excel File To Upload",
          accept: ".xlsx",
          multiple: false,
          text: _ctx.getTranslate('ImportExcelDataGrid'),
          "label-text": null,
          "upload-mode": "useButton",
          "show-file-list": false,
          onValueChanged: _ctx.onFileSelected
        }, null, 8, ["text", "onValueChanged"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_DxButton, {
          id: "submitBtn",
          type: "success",
          text: _ctx.submitButtonText,
          onClick: _ctx.onSubmitClicked
        }, null, 8, ["text", "onClick"])
      ])
    ])
  ]))
}