
import DxButton from "devextreme-vue/button";
import { formatMessage } from "devextreme/localization";
import { defineComponent } from "vue";
import { DxPopover } from "devextreme-vue/popover";
import {
  GetBimObjectsByTypeResponse,
  GetBimObjectsByTypeResponseModelRevisionStatusEnum,
  GetBimObjectsByTypeResponseModelTypeEnum,
  BimObjectObjectTypeEnum
} from "@swg/api";
import { bimAPI,publishedDataAPI } from "@/utils/api";
import DataSource from "devextreme/data/data_source";
import store from "@/store";
import auth from "@/authsrc/auth";

interface ModelObject {
  Parent: string;
  ParentName: string;
  Name: string;
  ExternalGuid?: string;
  ObjectType: number;
  RevisionId: string;
  TenantId: string;
  ModelMetaDataId: string;
  ModelMetaDataName: string;
  ExpiredDate?: Date,
  BimObjectId: string;
  ComplexId: string;
  RevisionStatus: GetBimObjectsByTypeResponseModelRevisionStatusEnum;
  ModelType: GetBimObjectsByTypeResponseModelTypeEnum;
}

type BimObject = ModelObject;

export default defineComponent({
  components: {
    DxButton,
    DxPopover
  },
  data() {
    return {
      dataSource: new DataSource({
        key: "BimObjectId",
        load: async () => {
          await store.dispatch("setLoader", true);
          const bimObjectsByType = await bimAPI.getBimObjectsByType()
            .catch((error) => {
              console.log(error);
            }).finally(() =>{
              store.dispatch("setLoader", false);
            });
          return this.$_mapData(bimObjectsByType);
        }
      }),
      restoreBackupPopoverVisible: false,
      modelCount: 0,
      modelIdList : [],
      permissions: auth.getPermissions()
    }
  },
  beforeMount() {
    this.dataSource.reload();
  },
  methods: {
    getTranslate(text) {
      return formatMessage(text);
    },
    getTranslateWithValue(text,val)
    {
      if(val=== undefined)
      {
        val = 0;
      }
      return formatMessage(text,val);
    },
    async restoreBackup(){
      await store.dispatch("setLoader", true);
      const result = await publishedDataAPI.restoreRevisionAndXktData(
        {
          restoreRevisionAndXktDataRequest :{
            modelIds : this.modelIdList
          }
        }
      )
        .catch((error) => {
          console.log(error);
        }).finally(() =>{
          store.dispatch("setLoader", false);
          this.onRestoreBackupPopoverHide();
          console.log(result);
        });
    },
    showBackupRestorePopover() {
      this.getModelCount();
      this.restoreBackupPopoverVisible = true;
    },
    showModelCount(){
      return this.modelcount;
    },
    onRestoreBackupPopoverHide() {
      this.restoreBackupPopoverVisible = false;
    },
    $_mapData(bimObjectsByType: GetBimObjectsByTypeResponse[]): BimObject[] {
      if (!bimObjectsByType) {
        return null;
      }
      const models: ModelObject[] = [];
      bimObjectsByType.forEach((bimObject) => {
        const modelExists = bimObject.modelBimObjectId !== undefined && !models.find(
          (element) => element.BimObjectId == bimObject.modelBimObjectId
        );

        if (modelExists) {
          models.push({
            Parent: bimObject.buildingBimObjectId,
            ParentName: bimObject.buildingMetaDataName,
            Name: bimObject.modelMetaDataName,
            ExternalGuid: bimObject.modelExternalGuid,
            ObjectType: BimObjectObjectTypeEnum.Model,
            RevisionId: bimObject.revisionId,
            TenantId:bimObject.buildingExternalGuid,
            ModelMetaDataId: bimObject.modelMetaDataId,
            ModelMetaDataName: bimObject.modelMetaDataName,
            ExpiredDate: bimObject.modelExpiredDate,
            BimObjectId: bimObject.modelBimObjectId,
            ComplexId: bimObject.complexId,
            RevisionStatus: bimObject.modelRevisionStatus,
            ModelType: bimObject.modelType
          });
        }
      });
      return models;
    },
    async getModelCount() {
      let count = 0;
      this.modelIdList = [];
      for (const model of this.dataSource.items()) {
        if (model.BimObjectId)
        {
          this.modelIdList.push(model.BimObjectId);
          count += 1;
        }

      }
      this.modelcount = count;
    },
  },
})
