import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTagBox = _resolveComponent("DxTagBox")!

  return (_openBlock(), _createBlock(_component_DxTagBox, {
    value: $data.currentValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($data.currentValue) = $event)),
    "data-source": $props.dataSource,
    "show-selection-controls": true,
    "input-attr": { 'aria-label': 'Name' },
    "max-displayed-tags": 4,
    "show-multi-tag-only": false,
    "on-value-changed": (e) => $props.onValueChanged(e.value),
    "on-selection-changed": $options.onSelectionChanged,
    "search-enabled": true,
    "value-expr": "CategoryId",
    "display-expr": "CategoryName",
    "apply-value-mode": "useButtons"
  }, null, 8, ["value", "data-source", "on-value-changed", "on-selection-changed"]))
}