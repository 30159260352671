
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
  DxSearchPanel,
  DxFilterPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxExport,
} from "devextreme-vue/data-grid";
import { formatMessage } from "devextreme/localization";
import CustomStore from "devextreme/data/custom_store";
import { saveAs } from "file-saver";
import { getAllCategories } from "../../utils/categories"
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxFilterPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxExport,
  },
  props: {},
  data() {
    return {
      categories: new CustomStore({
        load: async () => {
          try {
            const response = await getAllCategories();
            return response;
          } catch (error) {
            console.error(error);
          }
        },
      }),
      labels: {
        exportExcelLabel: formatMessage("ExportExcel"),
        reusablePaginationLabel: formatMessage("ReusablePaginationLabel")
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    getTranslate(text) {
      return formatMessage(text);
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function(options) {
          options.excelCell.font = { name: "Arial", size: 12 };
          options.excelCell.alignment = { horizontal: "left" };
        }
      }).then(function() {
        workbook.xlsx.writeBuffer()
          .then(function(buffer) {
            saveAs(new Blob([buffer], { type: "application/octet-stream" }), "AssetCategories.xlsx");
          });
      });
    },
  },
};
