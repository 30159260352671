

import DxTagBox from "devextreme-vue/tag-box";

export default {
  components: { DxTagBox },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    onValueChanged: {
      type: Function,
      default: () => function() {},
    },
    dataSource: {
      type: Object,
      default: () => {},
    },
    dataGridComponent: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  methods: {
    onSelectionChanged() {
      this.dataGridComponent.updateDimensions();
    },
  },
};
