import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fea08bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxPopover = _resolveComponent("DxPopover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.getTranslate("BackupRestore")), 1)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_DxButton, {
        id: "BackupRestore",
        class: "next-btn",
        type: "danger",
        text: _ctx.getTranslate('BackupRestore'),
        disabled: !_ctx.permissions.backupRestore.canRestoreBackup,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showBackupRestorePopover()))
      }, null, 8, ["text", "disabled"]),
      _createVNode(_component_DxPopover, {
        width: 300,
        visible: _ctx.restoreBackupPopoverVisible,
        target: "#BackupRestore",
        position: "bottom",
        shading: true,
        onHidden: _ctx.onRestoreBackupPopoverHide
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getTranslateWithValue("ConfirmRestoreBackup",_ctx.showModelCount())) + " ", 1),
          _createElementVNode("div", null, [
            _createVNode(_component_DxButton, {
              id: "nextButton",
              class: "next-btn",
              text: _ctx.getTranslate('Yes'),
              type: "danger",
              onClick: _ctx.restoreBackup
            }, null, 8, ["text", "onClick"]),
            _createVNode(_component_DxButton, {
              id: "nextButton",
              class: "next-btn",
              text: _ctx.getTranslate('No'),
              type: "normal",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.restoreBackupPopoverVisible=false))
            }, null, 8, ["text"])
          ])
        ]),
        _: 1
      }, 8, ["visible", "onHidden"])
    ])
  ], 64))
}