import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ObjectDetails = _resolveComponent("ObjectDetails")!
  const _component_ImportExcel = _resolveComponent("ImportExcel")!
  const _component_DxPopup = _resolveComponent("DxPopup")!
  const _component_DxToolbar = _resolveComponent("DxToolbar")!
  const _component_DxCheckBox = _resolveComponent("DxCheckBox")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxSearchPanel = _resolveComponent("DxSearchPanel")!
  const _component_DxPaging = _resolveComponent("DxPaging")!
  const _component_DxPager = _resolveComponent("DxPager")!
  const _component_DxFilterPanel = _resolveComponent("DxFilterPanel")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxColumnFixing = _resolveComponent("DxColumnFixing")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxGrouping = _resolveComponent("DxGrouping")!
  const _component_DxExport = _resolveComponent("DxExport")!
  const _component_DxSelection = _resolveComponent("DxSelection")!
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_DxSorting = _resolveComponent("DxSorting")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxDropDownOptions = _resolveComponent("DxDropDownOptions")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_DxPosition = _resolveComponent("DxPosition")!
  const _component_ViewListName = _resolveComponent("ViewListName")!
  const _component_DxPopover = _resolveComponent("DxPopover")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ObjectDetails, {
      "popup-title": _ctx.formTitle,
      visible: _ctx.editingPopup,
      container: '.dx-viewport',
      "editing-data": _ctx.editingData,
      "parameter-groups": _ctx.parameterGroups,
      "editable-data-fields": _ctx.editableDataFields,
      "lookup-values": _ctx.lookupValues,
      "editing-items": _ctx.editingItems,
      "internal-selected-row-keys": _ctx.internalSelectedRowKeys,
      "batch-changes": _ctx.batchChanges,
      onHidePopup: _ctx.hidePopup,
      onChangesToSave: _ctx.changesToSave,
      onParsedChangesToSave: _ctx.parsedChangesToSave,
      onBatchChangesToSave: _ctx.batchChangesToSave,
      onPopupHidden: _ctx.popupHidden
    }, null, 8, ["popup-title", "visible", "container", "editing-data", "parameter-groups", "editable-data-fields", "lookup-values", "editing-items", "internal-selected-row-keys", "batch-changes", "onHidePopup", "onChangesToSave", "onParsedChangesToSave", "onBatchChangesToSave", "onPopupHidden"]),
    _createVNode(_component_DxPopup, {
      visible: _ctx.showImportPopup,
      name: "ImportExcelPopup",
      title: _ctx.getTranslate('ImportExcelDataGrid'),
      height: "auto",
      "max-width": "1500px",
      "max-height": "1200px",
      "hide-on-outside-click": true,
      "content-template": "import-popup-content",
      onShown: _ctx.onImportPopupShown,
      onHidden: _ctx.onImportPopupHidden
    }, {
      "import-popup-content": _withCtx(() => [
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_ImportExcel, {
            "model-id-prop": _ctx.modelIdForImport,
            "complex-id-prop": _ctx.complexId,
            "building-id-prop": _ctx.buildingId
          }, null, 8, ["model-id-prop", "complex-id-prop", "building-id-prop"])
        ], 512), [
          [_vShow, _ctx.showImportPopup]
        ])
      ]),
      _: 1
    }, 8, ["visible", "title", "onShown", "onHidden"]),
    _createVNode(_component_DxToolbar, {
      id: "actionToolbar",
      items: _ctx.toolbarItems
    }, null, 8, ["items"]),
    _createVNode(_component_DxDataGrid, {
      id: "dataGrid",
      ref: "dataGridRefKey",
      "data-source": _ctx.bimObjects,
      "show-borders": false,
      "column-resizing-mode": "widget",
      columns: [],
      "column-min-width": 150,
      "column-auto-width": true,
      "row-alternation-enabled": true,
      "allow-column-reordering": true,
      "allow-column-resizing": true,
      "hover-state-enabled": true,
      "allow-search": true,
      "selected-row-keys": _ctx.selectedRowKeys,
      "show-column-lines": true,
      "show-row-lines": true,
      "customize-columns": _ctx.customizeColumns,
      "remote-operations": true,
      "no-data-text": _ctx.noDataTextCustom,
      onExporting: _ctx.onExporting,
      onSelectionChanged: _ctx.onSelectionChanged,
      onContextMenuPreparing: _ctx.onContextMenuPreparing,
      onSaving: _ctx.onSaving,
      onEditCanceling: _ctx.onEditCanceling,
      onCellPrepared: _ctx.onCellPrepared,
      onEditingStart: _ctx.onEditingStart,
      onInitialized: _ctx.onDataGridInitialized,
      onContentReady: _ctx.onDataGridContentReady
    }, {
      selectGroupTemplate: _withCtx(({data}) => [
        _createVNode(_component_DxCheckBox, {
          text: _ctx.getCheckBoxText(data),
          value: _ctx.getCheckBoxValue(data),
          "element-attr": _ctx.getCheckBoxElementAttr(data),
          "on-value-changed": _ctx.checkBoxValueChanged
        }, null, 8, ["text", "value", "element-attr", "on-value-changed"])
      ]),
      headerCellTemplate: _withCtx(({data}) => [
        _createElementVNode("div", {
          class: "dx-datagrid-summary-item dx-datagrid-text-content dx-datagrid-group-text-content",
          style: _normalizeStyle({
            'border-bottom-style': 'solid',
            'border-bottom-color': _ctx.getHeaderColor(data),
            'border-bottom-width': '5px'
          })
        }, _toDisplayString(data.column?.caption), 5)
      ]),
      deleteCellValueTemplate: _withCtx(() => [
        _createVNode(_component_DxButton, {
          icon: "remove",
          type: "danger",
          disabled: !_ctx.editingCellHasNotNullValue,
          text: _ctx.getTranslate('DeleteCellValue'),
          onClick: _ctx.onDeleteCellValueButtonClick
        }, null, 8, ["disabled", "text", "onClick"])
      ]),
      lookupEditor: _withCtx(({ data: cell }) => [
        _createVNode(_component_DxLookup, {
          items: _ctx.getValidLookupValues(cell),
          value: cell.value,
          "search-enabled": true,
          "on-value-changed": (event) => _ctx.onLookupValueChanged(event, cell)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxDropDownOptions, { "show-title": false })
          ]),
          _: 2
        }, 1032, ["items", "value", "on-value-changed"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DxEditing, {
          changes: _ctx.batchChanges,
          "onUpdate:changes": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.batchChanges) = $event)),
          "allow-updating": _ctx.permissions.dataGrid.canUpdate,
          mode: "batch"
        }, null, 8, ["changes", "allow-updating"]),
        _createVNode(_component_DxLoadPanel, { shading: true }),
        _createVNode(_component_DxSearchPanel, { visible: true }),
        _createVNode(_component_DxPaging, { "page-size": _ctx.dataGridPageSize }, null, 8, ["page-size"]),
        _createVNode(_component_DxPager, {
          visible: true,
          "show-page-size-selector": true,
          "show-info": true,
          "show-navigation-buttons": true,
          "allowed-page-sizes": [25, 50, 100, 200]
        }),
        _createVNode(_component_DxFilterPanel, { visible: true }),
        _createVNode(_component_DxHeaderFilter, {
          "allow-search": true,
          visible: true
        }),
        _createVNode(_component_DxFilterRow, {
          visible: true,
          "apply-filter": 'auto'
        }),
        _createVNode(_component_DxColumnChooser, {
          id: "columnChooser",
          mode: "dragAndDrop",
          "sort-order": "asc",
          height: 550,
          "allow-search": true,
          title: _ctx.getTranslate('ColumnPicker'),
          enabled: _ctx.permissions.dataGrid.canUpdate
        }, null, 8, ["title", "enabled"]),
        _createVNode(_component_DxColumnFixing, { enabled: true }),
        _createVNode(_component_DxGroupPanel, { visible: true }),
        _createVNode(_component_DxGrouping, { "auto-expand-all": false }),
        _createVNode(_component_DxExport, {
          enabled: true,
          "allow-export-selected-data": true
        }),
        _createVNode(_component_DxSelection, { mode: "multiple" }),
        _createVNode(_component_DxStateStoring, {
          enabled: true,
          type: "custom",
          "custom-load": _ctx.onCustomLoad
        }, null, 8, ["custom-load"]),
        _createVNode(_component_DxSorting, { mode: "multiple" })
      ]),
      _: 1
    }, 8, ["data-source", "selected-row-keys", "customize-columns", "no-data-text", "onExporting", "onSelectionChanged", "onContextMenuPreparing", "onSaving", "onEditCanceling", "onCellPrepared", "onEditingStart", "onInitialized", "onContentReady"]),
    _createVNode(_component_DxPopover, {
      width: 300,
      height: 500,
      visible: _ctx.createViewNamePopoverVisible,
      shading: true,
      target: "#actionToolbar",
      onHidden: _ctx.onCreateViewNamePopoverHide
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxPosition, {
          my: "top right",
          at: "bottom right",
          of: "#actionToolbar"
        }),
        _createVNode(_component_ViewListName, {
          id: "createViewNamePopoverList",
          ref: "createViewNamePopoverListRefKey",
          "accept-button-text": _ctx.getTranslate('ReusableCreate'),
          "view-list-names": _ctx.chooseViewDropDownBoxItems,
          "view-list-selected": _ctx.chooseViewValue,
          onAccepted: _ctx.onCreateViewNamePopoverAccepted,
          onViewSelected: _ctx.onChooseViewListItemClick,
          onViewDeleted: _ctx.onRemoveViewButtonClick,
          onViewUpdated: _ctx.onSaveViewButtonClick,
          onViewCleared: _ctx.onClearViewButtonClick
        }, null, 8, ["accept-button-text", "view-list-names", "view-list-selected", "onAccepted", "onViewSelected", "onViewDeleted", "onViewUpdated", "onViewCleared"])
      ]),
      _: 1
    }, 8, ["visible", "onHidden"])
  ]))
}