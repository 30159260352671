export interface Category {
  CategoryId: string
  CategoryName: string
  HashCategoryName: string
  MappedExternalCategories: MappedExternalCategory[]
  CategoryType: string
}

export interface MappedExternalCategory {
  CategoryType: string
  CategoryName: string
  HashCategoryName?: string
}

export interface CategoryDisplay {
  CategoryName: string
  IfcTypeValue: string
  InteraxoBimData: string
}

export interface CategoryDropdownDisplay {
  CategoryId: string
  CategoryName: string
}

export interface CategoryParameterMapping {
  CategoryId: string
  IfcTypeValue: string
}

import categoriesJson from "./categories.json";

export async function getAllCategories() {
  const categories = categoriesJson;
  const flattenedCategories: CategoryDisplay[] = [];
  for (const category of categories) {
    const ifcCategories = category.MappedExternalCategories.filter(cat => cat.CategoryType === "IfcCategory");
    const interaxoCategory = category.MappedExternalCategories.filter(cat => cat.CategoryType === "InteraxoBimCategory");
    const ifcNames = ifcCategories.map(i => i.CategoryName).join(", ");
    const interaxoNames = interaxoCategory.map(i => i.CategoryName).join(", ");
    const flattenedCategory: CategoryDisplay = {
      CategoryName: category.CategoryName,
      IfcTypeValue: ifcNames,
      InteraxoBimData: interaxoNames,
    };
    flattenedCategories.push(flattenedCategory);
  }
  return flattenedCategories;
}

export async function getAllDropdownCategories() {
  const categories = categoriesJson;
  const CategoriesForDropdown: CategoryDropdownDisplay[] = [];
  for (const category of categories) {
    const flattenedCategory: CategoryDropdownDisplay = {
      CategoryId: category.CategoryId,
      CategoryName: category.CategoryName,
    };
    CategoriesForDropdown.push(flattenedCategory);
  }
  return CategoriesForDropdown;
}

export async function getAllParameterMappingCategories() {
  const categories = categoriesJson;
  const CategoriesForParameterMapping: CategoryParameterMapping[] = [];
  for (const category of categories) {
    const ifcName = category.MappedExternalCategories[0].CategoryName;
    for (let j = 1; j < category.MappedExternalCategories.length; j++) {
      const flattenedCategory: CategoryParameterMapping = {
        CategoryId: category.CategoryId,
        IfcTypeValue: ifcName
      };
      if (CategoriesForParameterMapping.filter(m => !m.CategoryId.includes(flattenedCategory.CategoryId))) {
        CategoriesForParameterMapping.push(flattenedCategory);
      }

    }
  }
  return CategoriesForParameterMapping;
}
