
/* eslint-disable no-debugger,no-unused-vars */
import {
  DxColumnChooser,
  DxColumnFixing,
  DxDataGrid,
  DxEditing,
  DxExport,
  DxFilterPanel,
  DxFilterRow,
  DxGrouping,
  DxGroupPanel,
  DxHeaderFilter,
  DxLoadPanel,
  DxPager,
  DxPaging,
  DxSearchPanel,
  DxSelection,
  DxSorting,
  DxStateStoring
} from "devextreme-vue/data-grid";
import DxToolbar from "devextreme-vue/toolbar";
import { DxButton } from "devextreme-vue/button";
import { DxPopover } from "devextreme-vue/popover";
import { DxPosition, DxPopup } from "devextreme-vue/popup";

import notify from "devextreme/ui/notify";
import ConfigValues from "../../utils/configValues";
import CustomStore, { ResolvedData } from "devextreme/data/custom_store";
import { formatMessage } from "devextreme/localization";
import { custom } from "devextreme/ui/dialog";
// @ts-ignore
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { PropertyDataTypeEnum,ParameterTypeEnum } from "@/enums";
import DataSource from "devextreme/data/data_source";
import DxCheckBox from "devextreme-vue/check-box";
import { bimAPI, defaultAPI, fileAPI, parameterAPI,viewListAPI } from "@/utils/api";
import { DxDropDownOptions, DxLookup } from "devextreme-vue/lookup";
import auth from "../../authsrc/auth";
// @ts-ignore
import { DownloadFileFromAzure } from "@/utils/fileDownload";
// @ts-ignore
import FileUploadOnPremise from "@/utils/fileUploadOnPremise";
import { defineComponent } from "vue";
import { CompoundObject, PublishedDataService, PublishedDataServiceContent } from "./PublishedDataService";
import { SimpleItem, SimpleItemTemplateData } from "devextreme/ui/form";
import { Column } from "devextreme/ui/data_grid";
import {
  BimObjectPropertyValueChanges,
  Parameter,
  ParameterGroup,
  ParameterWithSourceNames,
  RuleValue,
  UpsertBimGridViewList,BimGridViewList
} from "@swg/api";
import { ObjectDetails } from "@swg/lib";
import { ChangeEvent } from "devextreme/ui/text_box";
import { ChangeData } from "@/components/data-grid/ChangeData";
import  ViewListName  from "@/components/view-list/view-list-name.vue";
import { ValueChangedEvent } from "devextreme/ui/lookup";
import ImportExcel from "@/components/sync/ImportExcel.vue";
// @ts-ignore
import store from "../../store";
import moment from "moment"

const defaultGroupColor = "rgba(0,0,0,0)";

export default defineComponent({
  components: {
    DxButton,
    DxColumnChooser,
    DxColumnFixing,
    DxDataGrid,
    DxEditing,
    DxLoadPanel,
    DxPopup,
    DxPopover,
    DxSearchPanel,
    DxExport,
    DxFilterPanel,
    DxFilterRow,
    DxGrouping,
    DxGroupPanel,
    ImportExcel,
    DxHeaderFilter,
    DxPager,
    DxPaging,
    DxSelection,
    DxToolbar,
    DxSorting,
    DxCheckBox,
    DxLookup,
    DxDropDownOptions,
    DxPosition,
    DxStateStoring,
    ViewListName,
    ObjectDetails
  },
  emits: ["selection-changed",
          "filter-changed",
          "context-menu-preparing"],
  data() {
    return {
      lookupValues: {} as { [key: string]: RuleValue[] },
      dataGridSelectedRowKeys: [],
      internalSelectedRowKeys: [],
      editingData: {},
      editingItems: [] as SimpleItem[],
      bulkEditData: [],
      ifcSpaceIDList: [],
      boolList: [true, false],
      lineEdit: true,
      downloadIfcDisabled: false,
      uploadExcelDisabled: this.checkIfUploadExcel(),
      editingPopup: false,
      createViewNamePopoverVisible: false,
      chooseViewValue: null,
      chosenViewFilterValueJson: null,
      chosenViewColumnsJson: null,
      currentViewFilterValueJson: null,
      currentViewColumnsJson: null,
      previousViewFilterValueJson : null,
      previousViewColumnsJson:null,
      columnsMissingInView:[],
      onLoadGridColumns:[],
      isChooseViewOpened: true,
      editingView: null,
      createViewName: null,
      disableCreateView: true,
      editingCell: null,
      batchChanges: [] as ChangeData[],
      revertDraftRowChanges: [],
      editableDataFields: new Set<Column<PublishedDataService, string>>(),
      flatNameToPropertyName: new Map<string, string>(),
      propertyNameToFlatName: new Map<string, string>(),
      loadedChanges: false,
      changeUploadTimer: null,
      noDataTextCustom:this.getTranslate("NoData"),
      assetParameters: [] as ParameterWithSourceNames[],
      allAssetParameters: [] as ParameterWithSourceNames[],
      viewLists:[] as BimGridViewList[],
      getAllViewListsResponse:[] as BimGridViewList[],
      columnsFromAssetParametersLoaded: false,
      showImportPopup: false,
      complexId: null,
      modelIdForImport: null,
      buildingId: null,
      listOfSystemParameters : [],
      bimObjects: new DataSource<PublishedDataService, string>({
        store: new CustomStore<PublishedDataService, string>({
          key: "bimObjectId",
          load: async (loadOptions) => {
            loadOptions.filter ??= this.getIfcCategoriesAsFilters();
            const payload = this.createQueryPayload(loadOptions, this.getModelsFromQueryString(), this.showExpired);
            try {
              if(!this.columnsFromAssetParametersLoaded){
                await this.getAssetParameters();
              }
              const data = await defaultAPI.postPublisheddataserviceget({
                postPublisheddataservicegetRequest: payload
              }) as PublishedDataService;

              return {
                data: data.data,
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount
              } as ResolvedData<PublishedDataService>;
            } catch (err) {
              console.error(err);
            }
          }
        }),
        onChanged: () => {
          let filterExpr = (this.dataGrid as any).getCombinedFilter();
          filterExpr ??= this.getIfcCategoriesAsFilters();
          if (!this.loadedChanges) {
            this.$nextTick(() => this.reloadBatchChanges());
          }

          if (this.lastShowExpired !== this.showExpired) {
            this.lastShowExpired = this.showExpired;
          }
          (this.bimObjects as any).store().load({ filter: filterExpr, select: ["bimObjectId", "ifcTypeValue"]}).then((data) => {
            this.$emit("filter-changed", data.data.map(d => d.bimObjectId));
          });
        },
      }),
      lastShowExpired: false,
      dataGridPageSize: 50,
      selectionFilter: [],
      sortBySelected: function (rowData) {
        console.log(rowData);
      },
      permissions: auth.getPermissions(),
      showExpired: this.$route.query.showExpired === "true",
      parameterGroups: [] as ParameterGroup[]
    };
  },
  computed: {
    isViewChanged: function() {
      return this.chosenViewFilterValueJson !== this.previousViewFilterValueJson || this.chosenViewColumnsJson !== this.previousViewColumnsJson;
    },
    chooseViewDropDownBoxItems: function() {
      return this.viewLists.map(c => c.viewName);
    },
    chooseViewDropDownListSelectedItems: function() {
      return [this.chooseViewValue];
    },
    createViewNamePopoverList: function() {
      return this.$refs["createViewNamePopoverListRefKey"];
    },
    toolbarItems: function () {
      return [
        {
          location: "before",
          locateInMenu: "auto",
          widget: "dxButton",
          options: {
            icon: "rowproperties",
            text: this.editRowButtonText,
            disabled: !this.permissions.dataGrid.canUpdate || this.selectedRowsCount === 0,
            onClick: () => this.showPopup()
          }
        },
        {
          location: "before",
          locateInMenu: "auto",
          widget: "dxButton",
          options: {
            icon: "rowproperties",
            type: "danger",
            text: this.revertRowButtonText,
            disabled: !this.permissions.dataGrid.canUpdate || this.selectedRowsCount === 0,
            onClick: () => this.onRevertRowButtonClick()
          }
        },
        {
          location: "before",
          locateInMenu: "auto",
          widget: "dxButton",
          options: {
            icon: "cellproperties",
            type: "danger",
            text: this.getTranslate("RevertCell"),
            disabled: !this.permissions.dataGrid.canUpdate || !this.editingCellHasChange,
            onClick: () => this.onRevertCellButtonClick()
          }
        },
        {
          location: "before",
          locateInMenu: "auto",
          widget: "dxButton",
          options: {
            icon: "remove",
            type: "danger",
            text: this.getTranslate("DeleteCellValue"),
            disabled: !this.editingCellHasNotNullValue,
            onClick: () => this.onDeleteCellValueButtonClick()
          }
        },
        {
          location: "before",
          locateInMenu: "auto",
          widget: "dxButton",
          options: {
            icon: "trash",
            type: "danger",
            text: this.expireRowButtonText,
            disabled: !this.permissions.dataGrid.canUpdate || this.selectedRowsCount === 0,
            onClick: () => this.expireRowsClick()
          }
        },
        {
          location: "before",
          locateInMenu: "auto",
          widget: "dxButton",
          options: {
            icon: "download",
            text: this.getTranslate("DownloadIfc"),
            disabled: this.downloadIfcDisabled,
            hint: this.getTranslate("ObjectsNotOriginatedInIFC"),
            onClick: () => this.downloadIfc()
          }
        },
        {
          location: "before",
          locateInMenu: "auto",
          widget: "dxButton",
          options: {
            icon: "upload",
            text: this.getTranslate("ImportExcelDataGrid"),
            disabled: this.uploadExcelDisabled,
            hint: this.getTranslate("ImportExcelDataGrid"),
            onClick: () => this.onImportClick()
          }
        },
        {
          location: "before",
          locateInMenu: "auto",
          widget: "dxCheckBox",
          options: {
            text: this.getTranslate("ShowExpired"),
            value: this.showExpired,
            disabled: false,
            onValueChanged: (e) => this.onShowExpiredValueChanged(e)
          }
        },
        {
          location: "before",
          locateInMenu: "auto",
          widget: "dxButton",
          options: {
            icon: "add",
            type: "default",
            text: this.getTranslate("ViewListMenu"),
            disabled: this.disableCreateView,
            onClick: () => this.onCreateViewButtonClick()
          }
        },
      ];
    },
    dataGrid() {
      return this.$refs["dataGridRefKey"].instance;
    },
    selectedRowKeys: function () {
      return this.dataGridSelectedRowKeys;
    },
    editingCellHasChange: function () {
      if (this.editingCell?.key && this.editingCell?.field) {
        const dataField = this.editingCell?.field;
        const property = dataField.endsWith(".value") ? dataField.substring(0, dataField.length - ".value".length) : dataField;
        return this.batchChanges.some((c) => c.key === this.editingCell.key && (Object as any).hasOwn(c.data, property));
      } else {
        return false;
      }
    },
    editingCellHasNotNullValue: function () {
      return this.editingCell && this.editingCell.value !== null;
    },
    selectedRowsCount: function () {
      return this.internalSelectedRowKeys ? this.internalSelectedRowKeys.length : 0;
    },
    revertRowButtonText: function () {
      const length = this.selectedRowsCount;

      if (length === 1) {
        return this.getTranslate("RevertRowSingle");
      } else {
        return formatMessage("RevertRowMultiple", length);
      }
    },
    editRowButtonText: function () {
      const length = this.selectedRowsCount;

      if (length === 1) {
        return this.getTranslate("EditRowSingle");
      } else {
        return formatMessage("EditRowMultiple", length);
      }

    },
    expireRowButtonText: function () {
      const length = this.selectedRowsCount;

      if (length === 1) {
        return this.getTranslate("ExpireRowSingle");
      } else {
        return formatMessage("ExpireRowMultiple", length);
      }

    },
    selectedRowText: function () {
      const length = this.selectedRowsCount;

      if (length === 1) {
        return this.getTranslate("SelectedRowSingle")
      } else if (length > 1) {
        return this.getTranslate("SelectedRowMultiple").replace("%SELNO%", length);
      }
      return ""
    },
    formTitle: function () {
      return this.getTranslate("ObjectDetails");
    }
  },
  watch: {
    batchChanges(newValue) {
      if (!this.loadedChanges) {
        this.loadedChanges = true;
      } else {
        const parsedChanges = JSON.parse(JSON.stringify(newValue));
        this.sendChangesToServer(parsedChanges);
      }
    },
    revertDraftRowChanges(valueToRevert) {
      if (!this.loadedChanges) {
        this.loadedChanges = true;
      } else {
        const parsedChanges = JSON.parse(JSON.stringify(valueToRevert));
        this.sendRevertRowToServer(parsedChanges);
      }
    }
  },
  beforeMount() {
    this.$_setupModelsColumns();
  },
  methods: {
    async loadViewListsFromServer(){
      try {
        await viewListAPI.getAllViewLists()
          .then((response) => {
            this.getAllViewListsResponse = response.bimGridviewLists;
            this.unpackViewLists(this.getAllViewListsResponse);
          })
      } catch(error) {
        console.error(error);
      }
    },
    unpackViewLists(data) {
      try{
        if (Array.isArray(data)) {
          this.viewLists = data.map((v) => this.createViewListFromData(v)).filter((v) => v);
        } else {
          this.viewLists = [];
        }
      }catch (error) {
        console.error(error);
      }
    },
    createViewListFromData(data) {
      const colBuffer = typeof data.columns === "string" ? Buffer.from(data.columns, "base64"): data.columns;
      const colParsedData = typeof data.columns === "string" ? JSON.parse(colBuffer.toString()) : data.columns;
      const filterBuffer = typeof data.filterValue === "string" ? Buffer.from(data.filterValue, "base64") : data.filterValue;
      const filterParsedData = typeof data.filterValue === "string" ?  JSON.parse(filterBuffer.toString()): data.filterValue;
      return this.createViewList(data.viewId,data.viewName, typeof data.filterValue === "string" ? filterParsedData.filterValue : data.filterValue,
                                 typeof data.columns === "string" ? colParsedData.columns : data.columns );
    },
    saveViewListToServer(view): Promise<UpsertBimGridViewList> {
      const data = this.createViewListPayload(view.filterValue,view.columns);
      return viewListAPI.createViewList({
        upsertBimGridViewList: {
          bimGridViewList: {
            viewName : view.viewName,
            filterValue : data.filterValue,
            columns : data.columns         }
        }
      }).catch((error) => {
        console.error(error);
        if (error.response.status == 422) {
          notify(this.getTranslate("ReusableDraftChangesValidationFail"), "error", 5000);
        } else {
          notify(this.getTranslate("ReusableSaveFailed"), "error", 5000);
        }
        return null;
      })
    },
    updateViewListToServer(view): Promise<UpsertBimGridViewList> {
      const data = this.createViewListPayload(view.filterValue,view.columns);
      return viewListAPI.updateViewList({
        upsertBimGridViewList: {
          bimGridViewList: {
            viewId : view.viewId,
            viewName : view.viewName,
            filterValue : data.filterValue,
            columns : data.columns         }
        }
      });
    },
    async deleteViewListOnServer(viewId) {
      return await viewListAPI.deleteViewList({ viewId: viewId })
        .catch((error) => {
          console.error(error);
        });
    },
    createViewListPayload(filterValue, columns) {
      const colData = JSON.stringify({
        "columns": columns
      });
      const colBuffer = Buffer.from(colData);
      const colDataBase64 = colBuffer.toString("base64");
      const filData = JSON.stringify({
        "filterValue": filterValue
      });
      const filBuffer = Buffer.from(filData);
      const filDataBase64 = filBuffer.toString("base64");
      return {
        "columns": colDataBase64,
        "filterValue": filDataBase64
      };
    },
    createViewList(viewId,viewName, filterValue, columns) {
      return {
        "viewId" :viewId,
        "viewName": viewName,
        "filterValue": filterValue,
        "columns": columns
      };
    },
    popupHidden() {
      this.editingPopup = false;
    },
    batchChangesToSave(changes) {
      this.batchChanges = changes;
    },
    changesToSave(changes) {
      this.sendChangesToServerBulk(changes);
    },
    parsedChangesToSave(parsedChanges) {
      this.sendChangesToServer(parsedChanges);
    },
    isLookup(data): boolean {
      const propertyName = this.propertyNameWithoutValue(data);
      return !!this.lookupValues[propertyName];
    },
    propertyNameWithoutValue(data): string {
      return data.dataField.endsWith(".value") ? data.dataField.substring(0, data.dataField.length - ".value".length) : data.dataField;
    },
    objectPropertyShouldBeReadOnly(data): boolean {
      let shouldBeReadOnly = true;
      this.editableDataFields.forEach(editableField => {
        if (editableField.caption === data.editorOptions.label) {
          shouldBeReadOnly = false;
        }
      });
      return shouldBeReadOnly;
    },
    propertyGroupDoesNotContainEditingItem(): SimpleItem[] {
      return this.editingItems.filter(editingItem =>
        this.parameterGroups.every(parameterGroup => parameterGroup.parametersList.every(parameter => parameter.name !== editingItem.label.text)));
    },
    propertyGroupContainsEditingItem(parameterGroup: ParameterGroup): SimpleItem[] {
      return this.editingItems.filter(editingItem => parameterGroup.parametersList.some(parameter => parameter.name === editingItem.label.text));
    },
    calculateStyle(data): string | undefined {
      return `border-left: 3px solid ${this.calculateColor(data)}`;
    },
    calculateColor(data): string | undefined {
      const parameter = this.parameterGroups.find(x => x.parametersList.find(y => y.name === data.editorOptions.label));
      return parameter?.color;
    },
    onShowExpiredValueChanged(event) {
      this.showExpired = event.value;
      this.dataGrid.refresh();
    },
    onLookupValueChanged(event, cell) {
      cell.setValue(event.value);
      cell.component.updateDimensions();
    },
    async onDataGridInitialized() {
      this.createDefaultColumns();
    },
    reloadBatchChanges() {
      return bimAPI.getDraftChanges({
        modelsRequest: {
          modelIds: this.getModelsFromQueryString()
        }
      }).then((response) => {
        this._changeSelectedColumnsRendering(response.parameters);
        this.loadedChanges = false;
        this.batchChanges = this.mapBimObjectChangesToBatchChanges(response.changes);
      }).catch((error) => {
        console.error(error);
      });
    },
    createDefaultColumns() {

      //By default visible columns
      this.dataGrid.addColumn({ allowEditing: false, dataField: "complexCommonName", caption: this.getTranslate("ComplexCommonName"), encodeHtml: false , sortOrder: "asc" });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "buildingCommonName", caption: this.getTranslate("EntityCommonName"), encodeHtml: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "modelCommonName", caption: this.getTranslate("ModelName"), encodeHtml: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "levelCommonName", caption: this.getTranslate("LevelCommonName"), encodeHtml: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "levelName", caption: this.getTranslate("LevelName"), encodeHtml: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "category", caption: this.getTranslate("ColumnCategory"), encodeHtml: false  });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "ifcTypeValue", caption: this.getTranslate("IfcTypeValue"), encodeHtml: false });
      this.dataGrid.addColumn({ allowEditing: true, dataField: "commonName", caption: this.getTranslate("CommonName"), encodeHtml: false });
      this.dataGrid.addColumn({ allowEditing: true, dataField: "designation", caption: this.getTranslate("Designation"), encodeHtml: false });
      this.dataGrid.addColumn({ allowEditing: true, dataField: "fmGuid", caption: this.getTranslate("ReusableFmGuidLabel"), encodeHtml: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "inRoomCommonName", caption: this.getTranslate("InRoomCommonName"), encodeHtml: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "inRoomDesignation", caption: this.getTranslate("InRoomDesignation"), encodeHtml: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "roomName", caption: this.getTranslate("RoomName"), encodeHtml: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "roomNumber", caption: this.getTranslate("RoomNumber"), encodeHtml: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "dateCreated", caption: this.getTranslate("DateCreated") });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "dateModified", caption: this.getTranslate("DateModified") });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "dateExpired", caption: this.getTranslate("DateExpired") });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "createdInModel", caption: this.getTranslate("CreatedInModel") });

      // By default non visible columns
      this.dataGrid.addColumn({ allowEditing: false, dataField: "bimObjectId", caption: this.getTranslate("BimObjectId"), visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "revisionId", caption: this.getTranslate("RevisionId"), visible: false  });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "externalId", caption: this.getTranslate("ExternalId"), visible: false  });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "complexFmGuid", caption: this.getTranslate("ComplexFMGuid"), visible: false  });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "complexDesignation", caption: this.getTranslate("ComplexDesignation"), encodeHtml: false, visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "buildingFmGuid", caption: this.getTranslate("EntityFMGuid"), visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "buildingDesignation", caption: this.getTranslate("EntityDesignation"), encodeHtml: false, visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "modelBimObjectId", caption: this.getTranslate("ModelId"), visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "modelDisciplineId", caption: this.getTranslate("ModelDisciplineID"), visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "levelDesignation", caption: this.getTranslate("LevelDesignation"), encodeHtml: false, visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "levelFmGuid", caption: this.getTranslate("LevelFmGuid"), encodeHtml: false, visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "levelBimObjectId", caption: this.getTranslate("LevelBimObjectId"), encodeHtml: false, visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "levelNumber", caption: this.getTranslate("LevelNumber"), encodeHtml: false, visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "inRoomBimObjectId", caption: this.getTranslate("InRoomBimObjectId"), encodeHtml: false, visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "inRoomFmGuid", caption: this.getTranslate("InRoomFmGuid"), encodeHtml: false, visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "fromRoomDesignation", caption: this.getTranslate("FromRoomDesignation"), encodeHtml: false, visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "fromRoomCommonName", caption: this.getTranslate("FromRoomCommonName"), encodeHtml: false, visible: false  });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "toRoomDesignation", caption: this.getTranslate("ToRoomDesignation"), encodeHtml: false, visible: false });
      this.dataGrid.addColumn({ allowEditing: false, dataField: "toRoomCommonName", caption: this.getTranslate("ToRoomCommonName"), encodeHtml: false, visible: false  });

      this.editableDataFields.add({ name: "designation", caption: this.getTranslate("Designation"), dataType: PropertyDataTypeEnum.String, id: 4, isDefault: true });
      this.editableDataFields.add({ name: "commonName", caption: this.getTranslate("CommonName"), dataType: PropertyDataTypeEnum.String, id: 5, isDefault: true });
      this.editableDataFields.add({ name: "dateExpired", caption: this.getTranslate("dateExpired"), dataType: PropertyDataTypeEnum.DateTime, id: 6, isDefault: true });
      this.editableDataFields.add({ name: "fmGuid", caption: this.getTranslate("ReusableFmGuidLabel"), dataType: PropertyDataTypeEnum.String, id: 7, isDefault: true });
    },
    assetParametersColumnCreation(visible = false)
    {
      let existingColumns = [];
      this.dataGrid.getVisibleColumns().filter(function(x) {
        if (x.name !== undefined){
          existingColumns.push(x.name.toLowerCase());
        }
      })
      const validationRule= [{ type:"custom",validationCallback: this.isInteger,message:this.getTranslate("IntegerValidationMessage") }];

      if (!this.columnsFromAssetParametersLoaded) {
        this.columnsFromAssetParametersLoaded = true;

        this.assetParameters.forEach((x: ParameterWithSourceNames) => {
          const parameter = x.parameter;
          let flatPropertyName = x.flatPropertyName;

          //Set PropertyNameToFlatName for all Asset Parameters
          this.propertyNameToFlatName.set(parameter.name, flatPropertyName);

          if (!existingColumns.includes(parameter.name.replace(" ", "").toLowerCase())) {
            const name = flatPropertyName;
            const dataField = `${name}.value`;
            const caption = parameter.name;
            const column: Column<PublishedDataService, string> = {
              allowEditing: true,  name: name,  dataField: dataField,  caption: caption,  visible: false  };

            if (!this.dataGrid.columnOption(column.name)) {
              this.dataGrid.addColumn({
                allowEditing: true,
                name: column.name,
                dataField: column.dataField,
                caption: column.caption,
                encodeHtml: false,
                visible: visible,
                validationRules:parameter.dataType == PropertyDataTypeEnum.Int32 || parameter.dataType == PropertyDataTypeEnum.Int64 ? validationRule : undefined,
                dataType:this.mapEnumDataTypeToGrid(parameter.dataType),
                editorOptions:parameter.dataType == PropertyDataTypeEnum.DateTime ? { openOnFieldClick : true } : undefined
              });
            }
            this.editableDataFields.add({  name: name,  caption: caption,  dataType: parameter.dataType,  isDefault: false  });

            this.flatNameToPropertyName.set(name, parameter.name);

            if (this.$_isLookupParameter(parameter)) {
              this.$_setDropdownColumnEditorForLookupColumn(parameter, column);
            }
          }
        });
      }
    },
    isInteger(params){
      const value = params.value;
      const minInt64 =  -9223372036854775808;
      const maxInt64 =  9223372036854775807;
      if(value=== null || value === undefined || value ===""){
        return true;
      }
      if(value < minInt64 || value > maxInt64)
      {
        return false;
      }
      return Number.isInteger(value);
    },
    mapEnumDataTypeToGrid(dataType)
    {
      switch(dataType)
      {
        case PropertyDataTypeEnum.String:{
          return "string"
        }
        case PropertyDataTypeEnum.DateTime:{
          return "date"
        }
        case PropertyDataTypeEnum.Bool:{
          return "boolean"
        }
        case PropertyDataTypeEnum.Int32:{
          return "number"
        }
        case PropertyDataTypeEnum.Int64:{
          return "number"
        }
        case PropertyDataTypeEnum.Decimal:{
          return "number"
        }
        default: {
          return undefined;
        }
      }
    },
    setFind(set, predicateFn) {
      for (const item of set.values()) {
        if (predicateFn(item)) {
          return item;
        }
      }
      return null;
    },
    createQueryPayload(loadOptions, modelIds, onlyExpired) {
      const payload = {} as any;

      payload.modelId = modelIds?.join(",");
      payload.onlyExpired = onlyExpired;
      [
        "take",
        "skip",
        "sort",
        "filter",
        "searchExpr",
        "searchOperation",
        "searchValue",
        "select",
        "requireTotalCount",
        "totalSummary",
        "group",
        "requireGroupCount",
        "groupSummary"
      ].forEach((i) => {
        if (i in loadOptions && this.isNotEmpty(loadOptions[i])) {
          payload[i] = JSON.stringify(loadOptions[i]);
        }
      });

      return payload;
    },
    createBimObjectIdFilter(bimObjectIds) {
      if (bimObjectIds.length === 1) {
        return ["bimObjectId", "=", bimObjectIds[0]];
      } else {
        const filter = [];

        for (const bimObjectId of bimObjectIds) {
          filter.push(["bimObjectId", "=", bimObjectId]);
          filter.push("or");
        }

        return filter.slice(0, -1);
      }
    },
    fetchBimObjects(bimObjectIds) {
      const filter = this.createBimObjectIdFilter(bimObjectIds);

      return this.bimObjects.store().load({ filter: filter })
        .then((data) => data.data);
    },
    isNotEmpty(value) {
      return value !== undefined && value !== null && value !== "";
    },
    createMutation(complete, modelIds, changes) {
      return {
        bIMClassesModelsAPIPayloadDraftChanges: {
          "complete": complete,
          "modelIds": modelIds,
          "data": changes
        }
      };
    },
    createCancel(modelIds) {
      return {
        bIMClassesModelsAPIPayloadPayloadWithModelIds: {
          "modelIds": modelIds
        }
      };
    },
    createChange(key, data): ChangeData {
      return {
        key: key,
        type: "update",
        data: data
      };
    },
    createRelationshipChange(key): ChangeData {
      const row = this.bimObjects._items.filter(x => x.bimObjectId === key);
      const rowData = JSON.parse(JSON.stringify(row));
      let changeData;

      if(rowData.length > 0) {
        const thisRow = rowData[0];

        const relationshipCols = {
          levelName: thisRow["levelName"] == null ? "" : thisRow["levelName"],
          levelNumber: thisRow["levelNumber"] == null ? "" : thisRow["levelNumber"],
          levelBimObjectId: thisRow["levelBimObjectId"] == null ? "" : thisRow["levelBimObjectId"],
          levelCommonName: thisRow["levelCommonName"] == null ? "" : thisRow["levelCommonName"],
          levelDesignation: thisRow["levelDesignation"] == null ? "" : thisRow["levelDesignation"],
          levelFmGuid: thisRow["levelFmGuid"] == null ? "" : thisRow["levelFmGuid"],
          inRoomCommonName: thisRow["inRoomCommonName"] == null ? "" : thisRow["inRoomCommonName"],
          fromRoomCommonName: thisRow["fromRoomCommonName"]== null ? "" : thisRow["fromRoomCommonName"],
          roomName: thisRow["roomName"] == null ? "" : thisRow["roomName"],
          roomNumber: thisRow["roomNumber"]== null ? "" : thisRow["roomNumber"],
          toRoomCommonName: thisRow["toRoomCommonName"] == null ? "" : thisRow["toRoomCommonName"]
        };

        changeData =  {
          key: key,
          type: "update",
          data: JSON.parse(JSON.stringify(relationshipCols))
        };
      }

      return changeData === undefined ? { key: null, type: null, data: null } : changeData;
    },
    createRevertDraftChange(wholeRow, changes) {
      const rows = [];
      const cellNames = [];

      changes.forEach(row => {
        rows.push(row.bimObjectId)

        row.data.forEach(cell => {
          cellNames.push(cell.name)
        });
      });

      return {
        revertDraftChanges: {
          "wholeRow": wholeRow,
          "bimObjectIds": rows,
          "cellsToRevert": [{
            "bimObjectId": rows[0],
            "propertyNames": cellNames
          }]
        }
      };
    },
    getModelsFromQueryString() {
      const queryStringModels = this.$route.query.models;
      return Array.isArray(queryStringModels) ? queryStringModels : [queryStringModels];
    },
    getRevisionStatusFromQueryString() {
      return this.$route.query.revisionStatus;
    },
    checkIfUploadExcel() {
      const bModelIds = this.getModelsFromQueryString().length === 1;
      const bRevision = this.getRevisionStatusFromQueryString() !== "0"
      return !(bModelIds && bRevision);
    },
    getCategoriesFromStore() {
      let queryStringCategories;
      if (store.getters.getIfcCategories.length != 0) {
        sessionStorage.setItem("categories", JSON.stringify(store.getters.getIfcCategories));
        queryStringCategories = store.getters.getIfcCategories;
      } else {
        queryStringCategories = JSON.parse(sessionStorage.getItem("categories"));
      }
      if (!queryStringCategories || queryStringCategories.length === 0) {
        return null;
      }

      return Array.isArray(queryStringCategories) ? queryStringCategories : [queryStringCategories];
    },
    getIfcCategoriesAsFilters() {
      let categories = this.getCategoriesFromStore();
      const ifcTypeValue = "ifcTypeValue";
      const equal = "=";
      let filters = [];
      for(const ifcName in categories) {
        let arr = [ifcTypeValue, equal, categories[ifcName]];
        filters.push(arr);
        filters.push("or");
      }
      filters.pop();
      return filters;
    },
    revertRowChanges(rowKeys) {
      if (Array.isArray(rowKeys)) {
        this.revertDraftRowChanges = this.batchChanges.filter((v) => rowKeys.includes(v.key));
      }
    },
    createViewState(currentState, filterValue, columns) {
      const state = JSON.parse(JSON.stringify(currentState));

      state.filterValue = filterValue;

      if (columns) {
        state.columns = columns;
      }

      return state;
    },
    chooseView(view) {
      if (view) {
        this.chooseViewValue = view.viewName;
        const state = this.dataGrid.state();
        this.previousViewFilterValueJson = state.filterValue;
        this.previousViewColumnsJson = state.columns;
        this.columnsMissingInView = this.onLoadGridColumns.filter( x => !view.columns.some(y => y.name === x.name));
        this.columnsMissingInView.forEach(element => {
          view.columns.push(element);
        });
        const newState = this.createViewState(state, view.filterValue, view.columns);
        this.dataGrid.state(newState);
      } else {
        this.chooseViewValue = null;
      }
      this.setChosenViewFilterValueAndColumnsJsonFromView(view);
    },
    revertCellChange(rowKey, dataField) {
      if (rowKey && dataField) {
        const property = dataField.endsWith(".value") ? dataField.substring(0, dataField.length - ".value".length) : dataField;
        const result = this.batchChanges.filter((c) => c.key === rowKey);
        if (result.length === 1) {
          const rowChanges = result[0].data;
          const changedFields = Object.getOwnPropertyNames(rowChanges);
          if (changedFields.includes(property)) {
            if (changedFields.length > 1) {
              // Remove only the data field
              delete rowChanges[property];
            } else {
              // Remove the key
              this.revertDraftRowChanges = this.batchChanges.filter((c) => c.key == rowKey)
            }
          }
        }
      }
    },
    deleteCellValue(rowKey, dataField) {
      if (rowKey && dataField) {
        const property = dataField.endsWith(".value") ? dataField.substring(0, dataField.length - ".value".length) : dataField;
        const isPropertyValue = dataField.endsWith(".value");
        const result = this.batchChanges.filter((c) => c.key === rowKey);
        if (result.length === 1) {
          result[0].data[property] = isPropertyValue ? { value: null } : null;
          this.batchChanges = [...this.batchChanges];
        } else if (result.length === 0) {
          const data = {};
          data[property] = isPropertyValue ? { value: null } : null;
          const change = this.createChange(rowKey, data);
          this.batchChanges = [...this.batchChanges, change];
        }
      }
    },
    sendChangesToServerApiCall(changes) {
      this.changeUploadTimer = null;

      const transformedChanges = this.mapBatchChangesToBimObjectChanges(changes);
      const modelIds = this.getModelsFromQueryString();
      const payload = this.createMutation(true, modelIds, transformedChanges);

      return bimAPI.setDraftChanges(payload)
        .catch((error) => {
          console.error(error);

          if (error.response.status == 422) {
            notify(this.getTranslate("ReusableDraftChangesValidationFail"), "error", 5000);
          } else {
            notify(this.getTranslate("ReusableUploadFailed"), "error", 5000);
          }
        });
    },
    async sendRevertRowToServerApiCall(changes) {
      this.changeUploadTimer = null;
      await store.dispatch("setLoader", true);
      const transformedChanges = this.mapBatchChangesToBimObjectChanges(changes);
      if(transformedChanges.length == 0)
      {
        const length = this.selectedRowsCount
        if(length == 1){
          notify(this.getTranslate("NoDataToRevertRow"), "error", 5000);
        }
        else
        {
          notify(this.getTranslate("NoDataToRevertRows"), "error", 5000);
        }

      }

      const payload = this.createRevertDraftChange(true, transformedChanges);

      await bimAPI.revertChangesToDraft(payload)
        .catch((error) => {
          console.error(error);

          if (error.response.status == 422) {
            notify(this.getTranslate("ReusableDraftChangesValidationFail"), "error", 5000);
          } else {
            notify(this.getTranslate("ReusableUploadFailed"), "error", 5000);
          }
        });
      await this.reloadBatchChanges();
      await store.dispatch("setLoader", false);
      window.location.reload();
    },
    sendChangesToServer(changes) {
      if (this.changeUploadTimer) {
        // Already have a going timer for uploading changes - clear it
        clearTimeout(this.changeUploadTimer);
      }

      // Debounce sending the changes
      this.changeUploadTimer = setTimeout(() => {
        this.sendChangesToServerApiCall(changes);
      }, 3000);
    },
    sendRevertRowToServer(changes) {
      if (this.changeUploadTimer) {
        // Already have a going timer for uploading changes - clear it
        clearTimeout(this.changeUploadTimer);
      }

      // Debounce sending the changes
      this.changeUploadTimer = setTimeout(() => {
        this.sendRevertRowToServerApiCall(changes);
      }, 3000);
    },
    sendChangesToServerBulk(changes) {
      if (this.changeUploadTimer) {
        // Already have a going timer for uploading changes - clear it since we'll be sending them instead
        clearTimeout(this.changeUploadTimer);
      }

      // We're coming out of a dialog, so make sure stuff is done before handing back control to the user
      this.dataGrid.beginCustomLoading(this.getTranslate("ReusableAddingChanges"));

      this.sendChangesToServerApiCall(changes)
        .finally(() => {
          this.dataGrid.endCustomLoading();
          this.reloadBatchChanges();
        });
    },
    async saveChangesToServer(changes) {
      if (this.changeUploadTimer) {
        // We have a going timer for uploading changes - clear it since we'll be sending them instead
        clearTimeout(this.changeUploadTimer);
      }

      this.dataGrid.beginCustomLoading(this.getTranslate("ReusableSavingChanges"));

      const modelIds = this.getModelsFromQueryString();
      const mappedChanges = this.mapBatchChangesToBimObjectChanges(changes);
      const payload = this.createMutation(true, modelIds, mappedChanges);

      try {
        await bimAPI.saveDraftChanges(payload);
        await this.reloadBatchChanges();
      } catch (error) {
        console.error(error);

        if (error.response.status == 422) {
          notify(this.getTranslate("ReusableDraftChangesValidationFail"), "error", 5000);
        } else {
          notify(this.getTranslate("ReusableSaveFailed"), "error", 5000);
        }
      } finally {
        this.dataGrid.endCustomLoading();
        this.bimObjects = null;
        this.noDataTextCustom = this.getTranslate("PublishingData");
        notify(this.getTranslate("PublishingData"), "warning", 5000);
      }
    },
    async cancelChangesOnServer() {
      if (this.changeUploadTimer) {
        // We have a going timer for uploading changes - clear it since we'll be cancelling the changes
        clearTimeout(this.changeUploadTimer);
      }

      this.dataGrid.beginCustomLoading(this.getTranslate("ReusableCancellingChanges"));

      const modelIds = this.getModelsFromQueryString();
      const payload = this.createCancel(modelIds);

      try {
        await bimAPI.cancelDraftChanges(payload);
        await this.reloadBatchChanges();
      } catch (error) {
        console.error(error);
        notify(this.getTranslate("ReusableCancelFailed"), "error", 5000);
      } finally {
        this.dataGrid.endCustomLoading();
        window.location.reload();
      }
    },
    getTranslate(text) {
      return formatMessage(text);
    },
    convertDataFieldToFlatPropertyName(dataField) {
      if (dataField.endsWith(".value")) {
        return dataField.substring(0, dataField.length - ".value".length)
      } else {
        return dataField;
      }
    },
    convertObjectDataFieldsToPropertyNames(item) {
      const names = Object.getOwnPropertyNames(item);

      names.forEach(n => {
        if (n.endsWith(".value")) {
          item[n.substring(0, n.length - ".value".length)] = item[n];
          delete item[n];
        }
      });

      return item;
    },
    mapBimObjectChangesToBatchChanges(bimObjectChanges: BimObjectPropertyValueChanges[]) {
      if (Array.isArray(bimObjectChanges)) {
        const dg = this;
        return bimObjectChanges.map((c) => {
          const data = {};

          if (Array.isArray(c.data)) {
            c.data.forEach((d) => {
              const propertyName = dg.propertyNameToFlatName.get(d.name) || d.name;
              const dataField = dg.dataGrid.columnOption(propertyName)?.dataField;
              dg.dataGrid.columnOption(propertyName, "visible", true);
              const isPropertyValue = typeof dataField === "string" && dataField.endsWith(".value");

              data[propertyName] = isPropertyValue ? { value: d.value } : d.value;
            });
          }

          if (c.data.length > 0) {
            return dg.createChange(c.bimObjectId, data);
          }
          
        }).filter(item => item != undefined);
      } else {
        return [];
      }
    },
    mapBatchChangesToBimObjectChanges(changes) {
      const dataTypeMap = new Map();

      this.editableDataFields.forEach(edf => {
        dataTypeMap.set(edf.name, edf.dataType);
      });

      return changes.map((c) => {
        if (c.key !== null) {
          return {
            "bimObjectId": c.key,
            "data": Object.getOwnPropertyNames(c.data).map((d) => {
              return {
                "name": this.flatNameToPropertyName.get(d) || d,
                "dataType": dataTypeMap.get(d) || 0,
                "value": this.convertChangedValue(c.data[d],dataTypeMap.get(d))
              };
            })
          };
        }
      }).filter((c) => c?.bimObjectId);
    },
    convertChangedValue(value,dataType) {
      let result = Object.prototype.hasOwnProperty.call(value, "value") ? value.value : value;
      if(dataType && dataType == PropertyDataTypeEnum.DateTime){
        let date = this.convertToLocalDate(result);
        return date || result;
      }
      return result;
    },
    convertToLocalDate(inputDate){
      let timestamp = moment(inputDate, "DD-MM-YYYY");
      if (timestamp.isValid()){
        let date = new Date(inputDate)
        // Display local date
        if(!timestamp.isUTC()){
          return moment(date).local().format("YYYY-MM-DD HH:mm:ss");
        }else{
          return date;
        }
      }else{
        return undefined;
      }
    },
    isValidImage(img) {
      const image = new Image();
      image.src = img;

      return (image.width > 0 && image.height > 0);
    },
    findViewList(name) {
      const nameLowerCase = name.toLowerCase();

      return this.viewLists.filter(c => c.viewName.toLowerCase() === nameLowerCase)[0];
    },
    setChosenViewFilterValueAndColumnsJsonFromView(view) {
      let filterValue = null;
      let columns = null;

      if (view) {
        filterValue = JSON.stringify(view.filterValue);
        columns = JSON.stringify(view.columns);
      }

      this.setChosenViewFilterValueAndColumnsJson(filterValue, columns);
    },
    setChosenViewFilterValueAndColumnsJson(filterValueJson, columnsJson) {
      this.chosenViewFilterValueJson = filterValueJson;
      this.chosenViewColumnsJson = columnsJson;
    },
    setCurrentViewFilterValueAndColumnsJson(filterValueJson, columnsJson) {
      this.currentViewFilterValueJson = filterValueJson;
      this.currentViewColumnsJson = columnsJson;
    },
    setPreviousViewFilterValueAndColumnsJson(filterValueJson, columnsJson) {
      this.previousViewFilterValueJson = filterValueJson;
      this.previousViewColumnsJson = columnsJson;
    },
    onExporting(e) {
      const assetDbLogo = ConfigValues.Get("VUE_APP_ASSETDB_LOGO_B64");
      const assetDbTitle = ConfigValues.Get("VUE_APP_ASSETDB_TITLE") || "QFM BIMi / Asset+";

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("BimObjects");

      const isValidImage = this.isValidImage(assetDbLogo);

      if (isValidImage) {
        const logo = workbook.addImage({
          base64: assetDbLogo,
          extension: "png",
        });

        worksheet.addImage(logo, {
          tl: {
            col: 0,
            row: 1,
          },
          ext: { width: 64, height: 64 },
        });
      }

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        topLeftCell: { row: 4, column: 1 },
      }).then(() => {
        const headerRow = worksheet.getRow(2);
        headerRow.height = 50;
        worksheet.mergeCells(2, 1, 2, 3);

        headerRow.getCell(1).value = assetDbTitle;
        headerRow.getCell(1).font = { size: 22 };
        headerRow.getCell(1).alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "DataGrid.xlsx"
          );
        });
      });
      e.cancel = true;
    },
    onSelectionChanged(e) {
      this.internalSelectedRowKeys = e.selectedRowKeys;
      this.$emit("selection-changed", e);
    },
    onContextMenuPreparing(e) {
      this.$emit("context-menu-preparing", e);
    },
    onSaving(args) {
      args.cancel = true;

      const parsedChanges = JSON.parse(JSON.stringify(args.changes));

      this.saveChangesToServer(parsedChanges);
    },
    onEditCanceling(args) {
      args.cancel = true;

      this.cancelChangesOnServer();
    },
    onRevertRowButtonClick() {
      this.revertRowChanges(this.internalSelectedRowKeys);
    },
    onRevertCellButtonClick() {
      this.revertCellChange(this.editingCell?.key, this.editingCell?.field);
    },
    onDeleteCellValueButtonClick() {
      this.deleteCellValue(this.editingCell?.key, this.editingCell?.field);
    },
    onCustomLoad() {
      this.loadViewListsFromServer();
    },
    onDataGridContentReady(args) {
      const state = args.component.state();
      const filterValue = JSON.stringify(state.filterValue);
      const columns = JSON.stringify(state.columns);
      this.disableCreateView = false;
      if(this.onLoadGridColumns.length === 0){
        this.onLoadGridColumns = state.columns;
      }
      this.setCurrentViewFilterValueAndColumnsJson(filterValue, columns);
    },
    onClearViewButtonClick() {
      this.dataGrid.clearFilter();
      this.dataGrid.clearGrouping();
      this.dataGrid.clearSorting();
      this.dataGrid.state.filterValue = this.previousViewFilterValueJson;
      this.dataGrid.state.columns = this.previousViewColumnsJson;
      this.dataGrid.refresh();
    },
    onSaveViewButtonClick(args) {
      if (this.chooseViewValue) {
        const view = this.findViewList(this.chooseViewValue);
        if (view) {
          const state = this.dataGrid.state();
          const columns = JSON.stringify(state.columns);
          const filterValue = JSON.stringify(state.filterValue);
          view.columns = JSON.parse(columns);
          view.filterValue = JSON.parse(filterValue);
          const result = this.updateViewListToServer(view);
          if(result)
          {
            notify(args.viewName + ":-" + this.getTranslate("ViewUpdatedSuccessfully"), "success", 2000);
          }
          this.setChosenViewFilterValueAndColumnsJson(filterValue, columns);
        }
      }
    },
    onChooseViewListItemClick(args)
    {
      const chosenItem = args.viewName;
      this.isChooseViewOpened = false;
      const view = JSON.parse(JSON.stringify(this.findViewList(chosenItem)));
      this.chooseView(view);
    },
    onRemoveViewButtonClick(args) {
      this.$nextTick(function() {
        const promptDialog = custom({
          title: this.getTranslate("DeleteView") + " :-" + args.viewName,
          messageHtml: this.getTranslate("DeleteViewPrompt"),
          buttons: [{
            text: this.getTranslate("ReusableOK"),
            type: "danger",
            onClick: () => true
          },{
            text: this.getTranslate("ReusableCancelButtonLabel"),
            type: "normal",
            onClick: () => false
          }]
        });

        promptDialog.show(args)
          .then((dialogResult) => {
            if (dialogResult) {
              if (this.chooseViewValue) {
                const view = this.findViewList(this.chooseViewValue);
                if (view) {
                  this.deleteViewListOnServer(view.viewId)
                  this.viewLists = this.viewLists.filter((v) => v.viewName !== this.chooseViewValue);
                  this.onClearViewButtonClick();
                }
              }
            }
          });
      });
      args.event.stopPropagation();
    },
    onCreateViewButtonClick() {
      this.createViewNamePopoverList.changeDefaultValue("");
      this.createViewNamePopoverVisible = true;
    },
    onCreateViewNamePopoverAccepted(args) {
      this.createViewNamePopoverVisible = false;
      if (args?.viewName) {
        const state = this.dataGrid.state();
        const columns = JSON.parse(JSON.stringify(state.columns));
        const filterValue = JSON.parse(JSON.stringify(state.filterValue));
        const view = this.createViewList(args.viewId,args.viewName, filterValue, columns);
        this.saveViewListToServer(view)
          .then(() => {
            this.setChosenViewFilterValueAndColumnsJsonFromView(view);
            this.viewLists = [...this.viewLists, view];
            this.chooseViewValue = args.viewName;
            notify(args.viewName + ":-" + this.getTranslate("ViewCreatedSuccessfully"), "success", 2000);
          });
      }
      else{
        notify(this.getTranslate("ViewListNameRequiredRule"), "error", 2000);
      }
    },
    onCreateViewNamePopoverHide() {
      this.createViewNamePopoverVisible = false;
    },
    onCellPrepared(args) {
      if (args.data && args.column) {
        args.cellElement.style.padding = "7px 8px";
        if (args.isEditing) {
          const cellData = {
            key: args.key,
            field: args.column.dataField,
            value: args.value
          };

          this.editingCell = cellData;
        } else if (this.editingCell?.key === args.key && this.editingCell?.field === args.column.dataField) {
          this.editingCell = null;
        }
      }
    },
    onEditingStart(args) {
      if (args.data && args.column) {
        let columnName;
        let exists = false;
        const columnNameRgx = /^(?<columnName>.*)\.value$/;
        if (columnNameRgx.test(args.column.dataField)) {
          const matches = columnNameRgx.exec(args.column.dataField);
          columnName = matches.groups.columnName;
        } else {
          columnName = args.column.name;
        }
        for (const obj of this.editableDataFields) {
          if (obj.name == columnName) {
            exists = true;
            break;
          }
        }
        args.cancel = !exists;
        if (!args.cancel) {
          let parameterObject = this.assetParameters.filter(p=> p.flatPropertyName === args.column.name);
          let parameterCategories = parameterObject.map(o => { return o.categories; });
          args.cancel = this.checkIfCancelEdit(parameterObject,parameterCategories, args.data.ifcTypeValue);
        }
      }
    },
    checkIfCancelEdit(parameterObject,parameterCategories, targetIfcValue) {
      if(parameterObject.length > 0 && parameterObject[0].parameter.parameterType == ParameterTypeEnum.System && parameterObject[0].parameter.canBeMapped)
      {
        return false;
      }
      for (const parameterCategory of parameterCategories) {
        for (const category of parameterCategory) {
          const ifcCategoryNames = category.mappedExternalCategories.filter(c => c.categoryType === "IfcCategory").map(i => i.categoryName);
          const boolCategoryPresent = Array.isArray(ifcCategoryNames) ? ifcCategoryNames.includes(targetIfcValue) : ifcCategoryNames === targetIfcValue;
          if (boolCategoryPresent) {
            return false;
          }
        }
      }
      return true;
    },
    ensureVisible(bimObjectId) {
      this.dataGrid.navigateToRow(bimObjectId);
    },
    selectRows(keys, preserve) {
      return this.dataGrid.selectRows(keys, preserve)
        .then(() => {
          this.internalSelectedRowKeys = keys;
        })
        .catch(error => {
          console.error(error);
        });
    },
    customizeColumns(columns) {
      columns.forEach(col => {
        col.groupCellTemplate = "selectGroupTemplate";
        col.headerCellTemplate = "headerCellTemplate";
        col.filterOperations = ["=", "<>", "contains", "notcontains", "startswith", "endswith"]
        col.selectedFilterOperation = "contains";
      })
    },
    getCheckBoxText: function (info) {
      return info.column.caption + ": " + info.text + this.getNumberGroupRows(info);
    },
    getHeaderColor: function (data) {
      let columnName = data.column.name;
      let parameterWithName = this.allAssetParameters.find(x => x.parameter.name.replace(" ", "").toLowerCase() === columnName.toLocaleLowerCase());
      if(parameterWithName == undefined){
        columnName = data.column.caption;
        parameterWithName = this.allAssetParameters.find(x => x.parameter.name.replace(" ", "").toLowerCase() === columnName.toLocaleLowerCase());
      }
      return parameterWithName?.parameter?.parameterGroup?.color ?? defaultGroupColor;
    },
    getNumberGroupRows: function (info) {
      if (info.data != undefined) {
        return " (" + info.data.count + ")";
      } else {
        return "";
      }
    },
    getCheckBoxValue(info) {
      const groupedColumnNames = this.getGroupedColumns(this.dataGrid),
            groupKey = info.key,
            rowKeys = this.getKeys(info.data, [], groupedColumnNames, groupKey),
            defaultValue = this.checkIfKeysAreSelected(rowKeys, this.dataGrid.getSelectedRowKeys());

      return defaultValue;
    },
    checkBoxValueChanged: function (e) {
      let rowKeys = JSON.parse(e.element.dataset.keys);
      if (e.value != undefined) {
        if (e.value)
          this.dataGrid.selectRows(rowKeys, true);
        else
          this.dataGrid.deselectRows(rowKeys);
      }
    },
    getCheckBoxElementAttr(info) {
      const groupedColumnNames = this.getGroupedColumns(this.dataGrid),
            groupKey = info.key,
            rowKeys = this.getKeys(info.data, [], groupedColumnNames, groupKey);
      let currGroupColumn = [];

      for (let i = 0; i <= info.key.length - 1; i++) {
        currGroupColumn.push(groupedColumnNames[i]);
      }

      const editorID = this.getEditorName(currGroupColumn, groupKey, info.component, null, null)

      return {
        class: "customSelectionCheckBox",
        id: editorID,
        "data-keys": JSON.stringify(rowKeys)
      }
    },
    getGroupedColumns(dataGrid) {
      let colNames = [],
          groupedColumns = [],
          groupIndex = null;

      for (let i = 0; i < dataGrid.columnCount(); i++) {
        groupIndex = dataGrid.columnOption(i, "groupIndex");
        if (groupIndex > -1) {
          groupedColumns.push({
            dataField: dataGrid.columnOption(i, "dataField"),
            groupIndex
          });
        }
      }

      groupedColumns.sort((a, b) => (a.groupIndex > b.groupIndex) ? 1 : -1);
      groupedColumns.forEach(col => {
        colNames.push(col.dataField);
      })
      return colNames;
    },
    getKeys(data, keys, groupedColumnNames, groupKey) {
      if (!groupKey)
        groupKey = data.key;

      let dataItems = data.items || data.collapsedItems || data; // check if it's a group row that has nested rows

      try {
        for (let dataItem of dataItems) {
          let childItems = dataItem.items || dataItem.collapsedItems;
          if (childItems)
            this.getKeys(dataItem, keys, groupedColumnNames, groupKey);
          else
            keys.push(dataItem["bimObjectId"]);
        }
      } catch {
        // Expected error
      }

      return keys;
    },
    getEditorName(groupedColumnNames, groupKey, grid, isSelected, itemKey) {
      let charactersToRemove = ["&", ":"];
      let groupRowValueStr = this.getGroupRowValue(groupedColumnNames, groupKey, grid, isSelected, itemKey),
          groupRowKeyStr = this.getGroupRowKey(groupedColumnNames);
      const gridId = grid.element().id;
      let completeValue = gridId + "groupCheckBox" + groupRowKeyStr + groupRowValueStr;

      charactersToRemove.forEach(item => {
        let re = new RegExp(item, "g");
        completeValue = completeValue.replace(re, "");
      })

      completeValue = completeValue.replace(/\s+/g, "");

      return completeValue;
    },
    getGroupRowValue(groupedColumnNames, groupKey, grid, isSelected, itemKey) {
      let groupRowValueStr = ""

      if (itemKey && grid && isSelected !== undefined) {
        let rowIndex = grid.getRowIndexByKey(itemKey),
            val;

        if (rowIndex !== -1) {
          groupedColumnNames.forEach(name => {
            val = grid.cellValue(rowIndex, name);
            if (val)
              groupRowValueStr += val
          })
        }

        if (!groupRowValueStr)
          groupRowValueStr = this.getValueFromArray(groupedColumnNames, grid, itemKey, isSelected);

        return groupRowValueStr;
      } else {
        groupKey.forEach(name => {
          groupRowValueStr += name
        })
        return groupRowValueStr;
      }
    },
    getGroupRowKey(groupedColumnNames) {
      let groupRowKeyStr = ""

      groupedColumnNames.forEach(name => {
        groupRowKeyStr += name;
      })
      groupRowKeyStr = groupRowKeyStr.replace(".", "");

      return groupRowKeyStr;
    },
    getValueFromArray(groupedColumnNames, grid, itemKey, isSelected) {
      let selection = [];

      if (isSelected)
        selection = grid.getSelectedRowsData();

      if (selection == undefined || selection.length == 0)
        selection = this.data;

      let data = selection.find(e => e["bimObjectId"] === itemKey);
      if (!data)
        return null

      let returnVal = ""
      groupedColumnNames.forEach(field => {
        let isFieldObject = field.indexOf("."); // Check if field name is like "Field1.Field2.myValue"
        if (isFieldObject) {
          let splitFields = field.split("."),
              tempVal = data;
          for (let splitField of splitFields) {
            tempVal = tempVal[splitField];
            if (!(tempVal instanceof Object)) {
              break;
            }
          }
          returnVal += tempVal;
        } else {
          returnVal += data[field]
        }
      })
      return returnVal
    },
    checkIfKeysAreSelected(currentKeys, selectedKeys) {
      if (selectedKeys.length == 0)
        return false;

      let count = 0;
      for (let key of currentKeys) {
        if (selectedKeys.indexOf(key) > -1) // key is not selected
          count++;
      }

      if (count == 0)
        return false;
      else if (currentKeys.length == count)
        return true;
      else
        return undefined;
    },
    //Edit pop up methods
    showPopup() {
      this.getPopupData()
        .then(() => {
          this.editingPopup = true;
        })
        .catch(error => console.error(error));
    },
    expireRowsClick() {
      this.expireRows(this.internalSelectedRowKeys);
    },
    async expireRows(rowKeys) {
      if (Array.isArray(rowKeys)) {
        this.dataGrid.beginCustomLoading();

        const expireBimObjects = [];
        rowKeys.forEach(function (key) {
          expireBimObjects.push(
            {
              bimObjectId: key,
              expireDate: new Date()
            })
        });

        const payload = {
          expireObjectRequest: { "expireBimObjects":expireBimObjects }
        } as any;
        await bimAPI.expireObject(payload).catch(error => {
          console.error(error);

          notify(this.getTranslate("ReusableSaveFailed"), "error", 5000);
        });

        await this.reloadBatchChanges();

        this.dataGrid.endCustomLoading();
      }
    },
    async downloadIfc() {
      this.downloadIfcDisabled = true;
      this.dataGrid.beginCustomLoading();

      let sasToken = undefined;
      if(!ConfigValues.onPremise){
        const sas = await fileAPI.getToken();
        sasToken = sas.sasToken;
      }

      await this.bimObjects.store().load({ take: 1, select: ["dateModified"]}).then(async (data) => {
        await fileAPI.files({ active: false }).then((files) => {

          const modelIds = this.getModelsFromQueryString();

          modelIds.forEach(async (modelId) => {
            let downloadedModel = false;
            for (const file of files) {
              if (file.modelId == modelId && file.isExport && Date.parse(file.dateUploaded.toString()) > Date.parse(data.data[0].dateModified)) {
                await this.downloadFile(file.tenantId, sasToken, file.filePath, file.name);
                downloadedModel = true;
              }
            }

            if (!downloadedModel) {
              for (const file of files) {
                if (file.modelId == modelId && !file.isExport) {
                  await this.downloadFile(file.tenantId, sasToken, file.filePath, file.name);
                }
              }
            }
          });
        });
      });

      this.dataGrid.endCustomLoading();
      this.downloadIfcDisabled = false;
    },
    async downloadFile(tenantId, sasToken, filePath, fileName){
      if(!ConfigValues.onPremise){
        await DownloadFileFromAzure(tenantId, sasToken, filePath, fileName);
      }
      else{
        await FileUploadOnPremise.GetFile(filePath, fileName)
      }
    },
    hidePopup() {
      this.editingData = {};
      this.editingItems = [];
      this.bulkEditData = [];
      this.editingPopup = false;
    },
    onImportClick() {
      this.showImportPopup = true;
    },
    async onImportPopupShown() {
      let bimData = await this.bimObjects.store().load({ take: 1, select: ["complexBimObjectId", "buildingBimObjectId"]}).then(data => { return data.data });
      this.complexId = bimData[0].complexBimObjectId;
      this.buildingId = bimData[0].buildingBimObjectId;
      this.modelIdForImport = this.getModelsFromQueryString()[0];
    },
    onImportPopupHidden() {
      this.showImportPopup = false;
    },
    async getPopupData() {
      const keysLength = this.internalSelectedRowKeys.length;

      return this.fetchBimObjects(this.internalSelectedRowKeys)
        .then((data: PublishedDataServiceContent[]) => {
          if (keysLength == 1) {
            const changedData = this.getChangedData(data[0]);
            this.addMissingEditableDataFields(changedData);

            this.editingItems = this.getEditingSimpleItems(changedData,undefined);
            this.editingData = this.convertObjectDataFieldsToPropertyNames(changedData);
          } else {
            let mergedCategories = []
            data.forEach(element => {
              mergedCategories.push(element["ifcTypeValue"].toLowerCase());
            });
            const mergedData = this.mergeMultipleEditingData(data);
            this.editingItems = this.getEditingSimpleItems(mergedData,mergedCategories);
            this.editingItems.forEach(element => {
              if(element.editorOptions?.readOnly && mergedData[element.name]?.value ==="" && mergedData[element.name]?.dataType !== PropertyDataTypeEnum.DateTime)
              {
                if (this.valueIsPropertyValue(mergedData[element.name])) {
                  mergedData[element.name].value = this.getTranslate("DifferentValues");
                } else {
                  mergedData[element.name] = this.getTranslate("DifferentValues");
                }
              }
            });
            mergedCategories = [];
            this.editingData = mergedData;
          }
        });
    },
    getEditingSimpleItems(data,mergedCategories): SimpleItem[] {
      const items = [] as SimpleItem[];
      const objectCategory = data["ifcTypeValue"].toLowerCase();
      const listOfParameterWithObjCategory = mergedCategories === undefined ? this.getListOfParameterWithObjCategory(objectCategory)
        : this.getListOfParameterWithMergedObjCategory(mergedCategories)
      Object.getOwnPropertyNames(data).forEach(p => {
        let item : SimpleItem;

        const dataField = this.valueIsPropertyValueChange(data[p]) ? `${p}.value` : p;
        const option = this.dataGrid.columnOption(data[p]?.name ?? p);
        const caption = option?.caption || dataField;

        let isParameterWithObjCategory = false;
        isParameterWithObjCategory = listOfParameterWithObjCategory.includes(p.toLowerCase()) || listOfParameterWithObjCategory.includes(caption.toLowerCase());
        if(isParameterWithObjCategory)
        {
          item = { name: p, dataField: dataField, label: { text: caption }};
          const areMergedCatDistinct = this.areDistinct(mergedCategories);
          let readOnly = this.isDifferentValues(areMergedCatDistinct,p,data[dataField],data[p]?.value,mergedCategories,listOfParameterWithObjCategory,caption);
          this.setItemEditorOptions(item,readOnly)
          items.push(item);
        }
      });

      return items.sort((a, b) => a.label.text.localeCompare(b.label.text));
    },
    areDistinct(arr)
    {
      let n = arr?.length;
      let s = new Set();
      for (let i = 0; i < n; i++) {
        s.add(arr[i]);
      }
      return (s.size == 1);
    },
    isDifferentValues(areMergedCatDistinct,p,val1,val2,mergedCategories,listOfParameterWithObjCategory,caption){
      if(!areMergedCatDistinct){
        if(mergedCategories){
          if(this.IsDateProperty(val1,val2)){
            return false;
          }
          let x = listOfParameterWithObjCategory.filter(m => m === p.toLowerCase() || m === caption.toLowerCase())
          if(x.length === 1 && (!this.listOfSystemParameters.includes(p.toLowerCase() || caption.toLowerCase())))
          {
            return true;
          }
        }
      }
      return false;
    },
    mergeMultipleEditingData(listSelectedObjects) {
      let newEditingData = JSON.parse(JSON.stringify(listSelectedObjects[0]));
      newEditingData = this.getChangedData(newEditingData);
      this.addMissingEditableDataFields(newEditingData);
      //We start from 1 because we already have the first object loaded.
      for (let i = 1; i < listSelectedObjects.length; i++) {
        let currentObject = JSON.parse(JSON.stringify(listSelectedObjects[i]));
        currentObject = this.getChangedData(currentObject);
        this.addMissingEditableDataFields(currentObject);
        for (const property in currentObject) {
          let defaultValue = this.getDefaultMergeValue(newEditingData[property], currentObject[property])
          if(defaultValue !== undefined){
            if (this.valueIsPropertyValue(newEditingData[property])){
              newEditingData[property].value = defaultValue;
            }else{
              newEditingData[property] = defaultValue;
            }
          }
        }
      }
      return newEditingData;
    },
    getDefaultMergeValue(newEditingData,currentObject)
    {
      if (newEditingData == undefined || !this.valuesAreEquivalent(newEditingData, currentObject)) {
        const isDateProperty = this.IsDateProperty(newEditingData,currentObject)
        if(!isDateProperty && currentObject?.dataType !== PropertyDataTypeEnum.DateTime){
          return this.getTranslate("DifferentValues");
        }else{
          return "";
        }
      }else{
        return undefined;
      }
    },
    AreDatesEqual(a,b){
      const date1 = new Date(a);
      const date2 = new Date(b);

      date1.setHours(0, 0, 0, 0);
      date2.setHours(0, 0, 0, 0);

      return date1.getTime() == date2.getTime()
    },
    isValidDate(date) {
      if(!isNaN(Date.parse(date))){
        const formats = [
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          "yyyy-MM-dd'T'HH:mm:ss'Z'",
          "yyyy-MM-dd'T'HH:mm:ss",
          moment.ISO_8601,
          "MM/DD/YYYY  :)  HH*mm*ss"
        ];
        if(moment(date, formats, true).isValid())
        {
          return true;
        }
      }
      return false;
    },
    IsDateProperty(a,b){
      if((this.hasOwnValue(a) && this.isValidDate(a.value)) || (this.hasOwnValue(b) && this.isValidDate(b.value))){
        return true;
      }
      if(this.isValidDate(a) || this.isValidDate(b)){
        return true;
      }
      return false;
    },
    valuesAreEquivalent(a, b) {
      if(this.IsDateProperty(a,b)){
        return this.hasOwnValue(a) && this.hasOwnValue(b) && this.AreDatesEqual(a.value,b.value) ||
          !this.hasOwnValue(a) && !this.hasOwnValue(b) && this.AreDatesEqual(a,b)
      }
      return (this.hasOwnValue(a) && this.hasOwnValue(b) && a.value == b.value) ||
        (!this.hasOwnValue(a) && !this.hasOwnValue(b) && a == b)
    },
    getChangedData(item) {
      const itemBatchChanges = this.batchChanges.find(e => e.key == item.bimObjectId);
      const data = itemBatchChanges?.data ? JSON.parse(JSON.stringify(itemBatchChanges.data)) : {};

      // Simplify the property values where needed
      Object.getOwnPropertyNames(item).forEach(p => {
        const value = this.valueIsPropertyValue(item[p]) ? item[p].value : item[p];
        item[p] = this.valueIsPropertyValue(item[p]) ? { ...item[p], value } : item[p];
      });

      // Tack on changes
      Object.getOwnPropertyNames(data).forEach(p => {
        item[p] = this.valueIsPropertyValue(data[p]) ? { ...item[p], value: data[p] } : data[p];
      });

      return item;
    },
    valueIsPropertyValueChange(value) {
      return value && (Object as any).hasOwn(value, "value");
    },
    valueIsPropertyValue(value) {
      return value &&
        (Object as any).hasOwn(value, "id") &&
        (Object as any).hasOwn(value, "name") &&
        (Object as any).hasOwn(value, "dataType") &&
        (Object as any).hasOwn(value, "value");
    },
    hasOwnValue(value) {
      return value &&
        (Object as any).hasOwn(value, "value");
    },
    addMissingEditableDataFields(item) {
      this.editableDataFields.forEach(dataField => {
        if (item[dataField.name] == undefined) {
          if (dataField.isDefault) {
            item[dataField.name] = "";
          } else {
            item[dataField.name] = { id: 0, name: dataField.caption, dataType: dataField.dataType, value: "" };
          }
        }
      });
    },
    setItemEditorOptions(item: SimpleItem,readOnly) {
      let exists = false;
      for (const obj of this.editableDataFields) {
        if (obj.name === item.name) {
          exists = true;
          if ((Object as any).hasOwn(this.lookupValues, obj.name)) {
            item.editorType = "dxLookup";
            item.editorOptions = {
              items: this.getValidLookupValues(obj.name),
            };
          } else if (obj.dataType === PropertyDataTypeEnum.Decimal ||
            obj.dataType === PropertyDataTypeEnum.Int32 ||
            obj.dataType === PropertyDataTypeEnum.Int64) {
            item.editorOptions = {
              onKeyPress: this.numberBoxKeyDown
            };
            break;
          } else if (obj.dataType === PropertyDataTypeEnum.DateTime) {
            item.editorType = "dxDateBox";
            break;
          } else if (obj.dataType === PropertyDataTypeEnum.Bool) {
            item.editorType = "dxCheckBox";
            break;
          }
        }
      }

      if (!exists) {
        item.editorOptions = {
          readOnly: true
        };
      }
      if (readOnly) {
        item.editorOptions = {
          readOnly: true
        };
      }
    },
    getValidLookupValuesForObjectDetails(data) {
      const propertyName = this.propertyNameWithoutValue(data);
      const specificLookupValues = this.lookupValues[propertyName];

      if (specificLookupValues.some(x => x.valueInt64)) {
        return specificLookupValues.map(x => x.valueInt64);
      }
      if (specificLookupValues.some(x => x.valueDecimal)) {
        return specificLookupValues.map(x => x.valueDecimal);
      }
      if (specificLookupValues.some(x => x.valueString)) {
        return specificLookupValues.map(x => x.valueString);
      }
    },
    saveBulkChanges() {
      if (this.$refs["editDataFormRef"].instance.validate().isValid) {
        const changes = JSON.parse(JSON.stringify(this.batchChanges));

        for (let editedData of this.bulkEditData) {
          if (changes.some(x => x.key == editedData.key)) {
            // The object already has changes so we just need to update it
            for (const property in editedData.data) {
              changes.filter(x => x.key == editedData.key)[0].data[property] = editedData.data[property];
            }
          } else {
            changes.push(editedData)
          }
        }
        this.sendChangesToServerBulk(changes);

        this.loadedChanges = false;
        this.batchChanges = changes;
        this.hidePopup();
      }
    },
    editDataFormChanged(event: ChangeEvent | ValueChangedEvent, data: SimpleItemTemplateData) {
      const property = data.dataField.endsWith(".value") ? data.dataField.substring(0, data.dataField.length - ".value".length) : data.dataField;
      let value;
      if (this.isLookup(data)) {
        value = (event as ValueChangedEvent).value;
      } else {
        const htmlInputElementValue = (event.event.currentTarget as HTMLInputElement).value;
        const isPropertyValue = data.dataField.endsWith(".value");
        value = isPropertyValue ? { value: htmlInputElementValue } : htmlInputElementValue;
      }
      for (const key of this.internalSelectedRowKeys) {
        if (this.bulkEditData.length > 0 &&
          this.bulkEditData.filter(x => x.key == key).length > 0) {
          this.bulkEditData.filter(x => x.key == key)[0].data[property] = value;
        } else {
          const newData = {};
          newData[property] = value;
          const newEditData = { key: key, type: "update", data: newData };
          this.bulkEditData.push(newEditData);
        }
      }
    },
    numberBoxKeyDown(e) {
      const { event } = e;
      const str = event.key || String.fromCharCode(event.which);
      if (!/^[0-9.,]+/.test(str)) {
        event.preventDefault();
      }
    },
    getIfcNames(category) {
      return category.mappedExternalCategories.filter(m => m.categoryType === "IfcCategory").map(c => c.categoryName);
    },
    getFilteredAssetParam(param, categories) {
      if(param.categories.length == 0){
        return param;
      }

      let ifcCategoriesInParam = [];
      for (const category of param.categories) {
        const ifcNames = this.getIfcNames(category);
        if (ifcNames) {
          ifcCategoriesInParam = ifcCategoriesInParam.concat(ifcNames);
        }
      }
      if (ifcCategoriesInParam.some(ifcCategory => categories.includes(ifcCategory))) {
        return param;
      }
    },
    async getAssetParameters() {
      return parameterAPI.getAllParameters()
        .then((response) => {
          let assetParametersResponse = response.sort((a, b) => (a.parameter.name.toLowerCase() > b.parameter.name.toLowerCase()) ? 1 : -1);
          this.allAssetParameters = assetParametersResponse;
          this.allAssetParameters.forEach((x: ParameterWithSourceNames) => {
            if(x.parameter.parameterType === ParameterTypeEnum.System){
              this.listOfSystemParameters.push(x.parameter.name.toLowerCase());
            }
          });
          let categories = this.getCategoriesFromStore();
          if (categories && categories.length > 0) {
            let assetParametersFilterByCategories = assetParametersResponse.filter(param => this.getFilteredAssetParam(param, categories));
            this.assetParameters = assetParametersFilterByCategories;
          } else {
            this.assetParameters = assetParametersResponse;
          }
          this.assetParametersColumnCreation(false);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getValidLookupValues(data) {
      let specificLookupValues;
      if ((Object as any).hasOwn(data, "column")) {
        specificLookupValues = this.lookupValues[data.column.name];
      } else {
        specificLookupValues = this.lookupValues[data];
      }
      if (specificLookupValues.some(x => x.valueInt64)) {
        return specificLookupValues.map(x => x.valueInt64);
      }
      if (specificLookupValues.some(x => x.valueDecimal)) {
        return specificLookupValues.map(x => x.valueDecimal);
      }
      if (specificLookupValues.some(x => x.valueString)) {
        return specificLookupValues.map(x => x.valueString);
      }
    },
    _changeSelectedColumnsRendering(parameters) {
      parameters.forEach(parameter => {
        const column = this.dataGrid.columnOption(parameter.name);
        if (column) {
          this.$_setDropdownColumnEditorForLookupColumn(parameter, column);
        }
      });
    },
    $_isLookupParameter: function (parameter: Parameter) {
      return !!parameter.rule?.ruleValues?.length ?? false;
    },
    $_setDropdownColumnEditorForLookupColumn: function (parameter: Parameter, column: Column<PublishedDataService, string>) {
      this.lookupValues[column.name] = parameter.rule.ruleValues;
      this.dataGrid.columnOption(column.caption, { editCellTemplate: "lookupEditor" });
    },
    async $_setupModelsColumns() {
      const response = await parameterAPI.getParameterGroups();
      this.parameterGroups = response.parameterGroups.sort((a, b) => a.name.localeCompare(b.name));
    },
    getListOfParameterWithMergedObjCategory(mergedCategories)
    {
      const listOfParameterWithObjCategory = []
      this.allAssetParameters.forEach((x: ParameterWithSourceNames) => {
        if(x.categories.length > 0 ){
          x.categories!.forEach(pc => {
            pc.mappedExternalCategories!.forEach(mec => {
              if(mergedCategories.includes(mec.categoryName?.toLowerCase())){
                listOfParameterWithObjCategory.push(x.parameter.name.toLowerCase());
              }
            })
          });
        }else{
          listOfParameterWithObjCategory.push(x.parameter.name.toLowerCase());
        }
      });
      return listOfParameterWithObjCategory;
    },
    getListOfParameterWithObjCategory(objectCategory)
    {
      const listOfParameterWithObjCategory = []
      this.allAssetParameters.forEach((x: ParameterWithSourceNames) => {
        if(x.categories.length > 0){
          x.categories!.forEach(pc => {
            pc.mappedExternalCategories!.forEach(mec => {
              if(mec.categoryName?.toLowerCase() === objectCategory?.toString().toLowerCase()){
                listOfParameterWithObjCategory.push(x.parameter.name.toLowerCase());
              }
            })
          });
        }else{
          listOfParameterWithObjCategory.push(x.parameter.name.toLowerCase());
        }
      });
      return listOfParameterWithObjCategory;
    }
  }
});
