<template>
  <div class="content-block">
    <div
      class="content-wrap"
    >
      <div
        :class="show3d ? 'content-left' : 'content-full'"
      >
        <DxToolbar id="toolbar">
          <DxItem
            widget="dxSelectBox"
            :options="selectBoxOptions"
            location="before"
          />
          <DxItem
            widget="dxDropDownButton"
            location="before"
            :items="colors"
            :options="colorOptions"
            :visible="false"
          />
          <template #colorpicker="{ data }">
            <div class="custom-color-picker">
              <i
                v-for="(itemColor, i) in data"
                :key="i"
                :class="
                  itemColor
                    ? 'color dx-icon dx-icon-square'
                    : 'color dx-icon dx-icon-square dx-theme-text-color'
                "
                :style="{ color: itemColor }"
                aria-hidden="true"
                @click="onColorClick(itemColor)"
              />
            </div>
          </template>
          <DxItem
            location="after"
            widget="dxSwitch"
            :options="view3D"
          />
        </DxToolbar>
        <DataGrid
          ref="dataGrid"
          @selection-changed="onSelectionChanged"
          @filter-changed="onFilterChanged"
          @context-menu-preparing="onContextMenuPreparing"
        />
      </div>
      <div
        v-if="show3d"
        class="content-right"
      >
        <asset-viewer
          ref="assetView"
          :client="client"
          :src="assetsToView"
          :selected-items="selectedBimItems"
          :default-show-spaces="false"
          @selectionChanged="selectionChanged"
          @modelLoaded="onModelLoaded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DataGrid from "../components/data-grid/DataGrid.vue";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { AssetViewer } from "@swg/lib";
import { ViewerClient, defaultAPI } from "../utils/api";
import { formatMessage } from "devextreme/localization";

function union(setA, setB) {
  let _union = new Set(setA);
  for (let elem of setB) {
    _union.add(elem);
  }
  return _union;
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
      parseInt(result[1], 16) / 255,
      parseInt(result[2], 16) / 255,
      parseInt(result[3], 16) / 255,
    ]
    : null;
}

const colors = [
  null,
  "#980000",
  "#ff0000",
  "#ff9900",
  "#ffff00",
  "#00ff00",
  "#00ffff",
  "#4a86e8",
  "#0000ff",
  "#9900ff",
  "#ff00ff",
  "#ff3466",
];

export default {
  components: {
    DataGrid,
    DxToolbar,
    DxItem,
    "asset-viewer": AssetViewer,
  },
  data() {
    return {
      colorData: colors,
      view3D: {
        value: true,
        onValueChanged: () => {
          this.show3d = !this.show3d;
        },
      },
      backButtonOptions: {
        type: "back",
        onClick: () => {
          console.log("Back");
        },
      },
      colorOptions: {
        items: colors,
        onInitialized: ({ component }) => {
          this.colorPicker = component;
        },
        icon: "palette",
        stylingMode: "text",
        dropDownOptions: { width: "auto" },
        dropDownContentTemplate: "colorpicker",
      },

      show3d: true,
      viewMode: 1,
      bimId: "",
      client: ViewerClient,
      assetsToView: [],
      scope: "default",
      revisionId: "",
      gridSelectedRowsData: [],
      selectedBimItems: [],
      filteredIds: []
    };
  },
  computed: {
    selectBoxOptions() {
      return {
        width: 200,
        valueExpr: "id",
        displayExpr: "name",
        value: 1,
        items: [
          { id: 0, name: formatMessage("ViewAll") },
          { id: 1, name: formatMessage("HideFiltered") },
          { id: 2, name: formatMessage("XRayFiltered") },
        ],
        onItemClick: this.selectBoxClick,
      };
    },
  },
  async mounted() {
    const models = this.$route.query.models;

    if (Array.isArray(models)) {
      this.bimId = models[0];
    } else {
      this.bimId = models;
    }

    const modelsArray = [];

    if (Array.isArray(models)) {
      modelsArray.push(...models);
    } else if (typeof models === "string") {
      modelsArray.push(models);
    }

    this.assetsToView = await this.getModelViewItems(modelsArray);
  },

  methods: {
    async getModelViewItems(modelIds) {
      if (Array.isArray(modelIds) && modelIds.length > 0) {
        const modelNames = new Map();
        let filter = [];

        if (modelIds.length === 1) {
          filter.push("bimObjectId","=",modelIds[0]);
        } else {
          filter.push(["bimObjectId","=",modelIds[0]]);
          for (let i = 1; i < modelIds.length; i++) {
            filter.push("or");
            filter.push(["bimObjectId","=",modelIds[i]]);
          }
        }

        const payload = {
          filter,
          take: modelIds.length,
          select: ["bimObjectId", "commonName"]
        };

        try {
          const data = await defaultAPI.postPublisheddataserviceget({
            postPublisheddataservicegetRequest: payload
          });

          if (Array.isArray(data?.data)) {
            for (const item of data.data) {
              if (typeof item.bimObjectId === "string") {
                modelNames.set(item.bimObjectId, item.commonName || "");
              }
            }
          }
        } catch (e) {
          console.log("Failed to fetch model names:");
          console.log(e);
        }

        return modelIds.map(element => {
          return {
            modelId: element,
            name: modelNames.get(element) || ""
          }
        });
      }

      return [];
    },
    selectBoxClick(e) {
      this.viewMode = e.itemData.id;
      this.apply3dFilter();
    },
    onColorClick(color) {
      this.colorPicker.close();

      const assetView = this.$refs["assetView"].$refs["assetView"];
      // Quit out if this isn't in the view.
      if (assetView == null) return;

      var col = hexToRgb(color);

      assetView.color(this.filteredIds, col);
    },
    indicate(id) {
      const assetView = this.$refs["assetView"].$refs["assetView"];

      var count = 24;

      const cb = () => {
        assetView.highlight(id, count % 2 == 0);
        count--;
        if (count > 0) {
          setTimeout(cb, 200);
        }
      }

      cb();
    },
    onContextMenuPreparing(e) {
      const assetView = this.$refs["assetView"].$refs["assetView"];
      // Quit out if this isn't in the view.
      if (assetView == null || e.target != "content") return;

      if (!e.items) e.items = [];

      const id = e.row.data["bimObjectId"].toUpperCase();

      e.items.push({
        text: formatMessage("ViewFit"),
        items: [
          {
            text: formatMessage("Object"),
            onItemClick: () => {
              assetView.viewFit(id);
              this.indicate(id);
            },
          },
          {
            text: formatMessage("All"),
            onItemClick: () => {
              assetView.viewFit();
            },
          },
          {
            text: formatMessage("SelectedItems"),
            onItemClick: () => {
              assetView.viewFit(
                this.selectedBimItems.map((x) => x.toUpperCase())
              );
            },
          },
        ],
      });

      e.items.push({
        text: formatMessage("Hide"),
        items: [
          {
            text: formatMessage("Object"),
            onItemClick: () => {
              assetView.setVisible(id, false);
            },
          },
          {
            text: formatMessage("Others"),
            onItemClick: () => {
              assetView.hideAll();
              assetView.setVisible(id, true);
            },
          },
          {
            text: formatMessage("SelectedItems"),
            onItemClick: () => {
              assetView.setVisible(
                this.selectedBimItems.map((x) => x.toUpperCase()),
                false
              );
            },
          },
          {
            text: formatMessage("NotSelectedItems"),
            onItemClick: () => {
              assetView.setVisible(
                this.notSelectedIds().map((x) => x.toUpperCase()),
                false
              );
            },
          },
          {
            text: formatMessage("All"),
            onItemClick: () => {
              assetView.hideAll();
            },
          },
        ],
      });

      e.items.push({
        text: formatMessage("Show"),
        items: [
          {
            text: formatMessage("Object"),
            onItemClick: () => {
              assetView.setVisible(id, true);
            },
          },
          {
            text: formatMessage("SelectedItems"),
            onItemClick: () => {
              assetView.setVisible(
                this.selectedBimItems.map((x) => x.toUpperCase()),
                true
              );
            },
          },
          {
            text: formatMessage("NotSelectedItems"),
            onItemClick: () => {
              assetView.setVisible(
                this.notSelectedIds().map((x) => x.toUpperCase()),
                true
              );
            },
          },
          {
            text: formatMessage("All"),
            onItemClick: () => {
              assetView.showAll();
            },
          },
        ],
      });

      e.items.push({
        text: formatMessage("XRay"),
        items: [
          {
            text: formatMessage("Object"),
            onItemClick: () => {
              assetView.setXRay([id], true);
            },
          },
          {
            text: formatMessage("SelectedItems"),
            onItemClick: () => {
              assetView.setXRay(
                this.selectedBimItems.map((x) => x.toUpperCase()),
                true
              );
            },
          },
          {
            text: formatMessage("NotSelectedItems"),
            onItemClick: () => {
              assetView.setXRay(
                this.notSelectedIds().map((x) => x.toUpperCase()),
                true
              );
            },
          },
          {
            text: formatMessage("Others"),
            onItemClick: () => {
              assetView.XRayAll();
              assetView.setXRay([id], false);
            },
          },
          {
            text: formatMessage("All"),
            onItemClick: () => {
              assetView.XRayAll();
            },
          },
          {
            text: formatMessage("None"),
            onItemClick: () => {
              assetView.XRayReset();
            },
          },
        ],
      });

      e.items.push({
        text: formatMessage("ViewInSpace"),
        onItemClick: () => {
          assetView.viewInSpace(id);
          this.indicate(id);
        }
      });

      e.items.push({
        text: formatMessage("SelectNone"),
        onItemClick: () => {
          assetView.clearSelection();
        },
      });

      e.items.push({
        text: formatMessage("SelectParent"),
        onItemClick: () => {
          const parentId = assetView.modelData.viewer.metaScene.metaObjects[id]?.parent?.id;

          assetView.selectParent(parentId);
        },
      });

      e.items.push({
        text: formatMessage("ResetSlices"),
        onItemClick: () => {
          assetView.clearSlices();
        },
      });
    },
    apply3dFilter() {
      const assetView = this.$refs["assetView"].$refs["assetView"];
      // Quit out if this isn't in the view.
      if (assetView == null) return;
      if (this.viewMode == 0) {
        assetView.showAll();
        assetView.XRayReset();
      } else if (this.viewMode == 1) {
        assetView.XRayReset();
        assetView.hideAll();
        assetView.setVisible(this.filteredIds, true);
      } else if (this.viewMode == 2) {
        assetView.showAll();
        assetView.XRayAll();
        assetView.setXRay(this.filteredIds, false);
      }
    },
    onModelLoaded() {
      this.apply3dFilter();
    },
    onSelectionChanged(e) {
      // Tell the viewer
      this.selectedBimItems = Array.from(
        this.gridItemsToIds(e.selectedRowsData, (c) => c.toLowerCase())
      );
    },
    onFilterChanged(filteredRowKeys) {
      this.filteredIds = filteredRowKeys.map(c => c.toUpperCase());
      this.apply3dFilter();
    },

    selectionChanged(items, added) {
      // Viewer selection changed
      var converted = items.map((v) => {
        return v.toLowerCase();
      });

      const dataGrid = this.$refs["dataGrid"];

      dataGrid.selectRows(converted, false)
        .then(() => {
          if (added.length > 0) {
            dataGrid.ensureVisible(added[0].toLowerCase());
          }
        });
    },
    gridItemsToBimIds(data) {
      var selectedItems = new Set();

      if (data != null) {
        data.forEach((item) => {
          selectedItems.add(item["bimObjectId"].toLowerCase());
        });
      }

      return Array.from(selectedItems);
    },
    gridItemsToIds(data, fn) {
      var selectedItems = new Set();

      if (data != null) {
        data.forEach((item) => {
          const col = item["bimObjectId"];
          if (col != null) {
            selectedItems.add(fn ? fn(col) : col);
          } else {
            if (item.collapsedItems) {
              var subItems = this.gridItemsToIds(item.collapsedItems, fn);
              selectedItems = union(selectedItems, subItems);
            }
          }
        });
      }
      return selectedItems;
    },
    notSelectedIds() {
      const selectionSet = new Set(this.selectedBimItems);

      return this.filteredIds.filter((item) => {
        return !selectionSet.has(item.toLowerCase());
      });
    }
  },
};
</script>

<style scoped>
#toolbar {
  padding-left: 10px;
  padding-right: 10px;
}

.content-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.content-left {
  width: 50%;
  padding-right: 10px;
}

.content-right {
  width: 50%;
  display: flex;
  overflow: hidden;
}

.content-full {
  width: 100%;
}

.mainView {
  flex-grow: 1;
}

.addedBtns {
  width: 32px;
  height: 32px;
  top: 0px;
}

</style>
