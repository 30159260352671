
import { formatMessage } from "devextreme/localization";
import DxButton from "devextreme-vue/button";
import router from "../../router";
import { defineComponent } from "vue";
import auth from "@/authsrc/auth";

export default defineComponent({
  components: {
    DxButton
  },
  data() {
    return {
      parametersLabel: this.getTranslate("Parameters"),
      administratorLabel: this.getTranslate("Administrator"),
      backupRestoreLabel: this.getTranslate("BackupRestore"),
      categoryLabel: this.getTranslate("Category"),
      permissions: auth.getPermissions(),
      parametersItems: [
        { text: "ListParameters", icon: "menu" },
        { text: "ParameterGroups", icon: "menu" }
      ],
      settingsItems: [
        { text: "ListUsers", icon: "menu" },
        { text: "ListPermissions", icon: "menu" }
      ],
      backupRestoreItems: [
        { text: "BackupRestore", icon: "menu" },
      ],
      categoriesItems: [
        { text: "ListCategories", icon: "menu" },
      ]
    }
  },
  methods: {
    getTranslate(text) {
      return formatMessage(text);
    },
    isPermitted(itemText: string) {
      let result = false;
      switch (itemText) {
        case "ListParameters":
          result = this.permissions.parameters.canView;
          break;
        case "ParameterGroups":
          result = this.permissions.parameterGroups.canView;
          break;
        case "ListUsers":
          result = this.permissions.users.canView;
          break;
        case "ListPermissions":
          result = this.permissions.permissions.canView;
          break;
        case "BackupRestore":
          result = this.permissions.permissions.canView;
          break;
        case "ListCategories":
          result = this.permissions.categories.canView;
          break;
      }
      return result;
    },
    onActionClick(event) {
      switch (event) {
        case "ListParameters":
          this.showParametersList();
          break;
        case "ParameterGroups":
          this.showParameterGroups();
          break;
        case "ListUsers":
          this.showUsersList();
          break;
        case "ListPermissions":
          this.showPermissionsList();
          break;
        case "BackupRestore":
          this.showBackupRestoreProgress();
          break;
        case "ListCategories":
          this.showCategoriesList();
          break;
      }
    },
    showParameterGroups() {
      router.push({
        path: "/settings/parameter-groups",
      });
    },
    showParametersList() {
      router.push({
        path: "/settings/parameter-list",
      });
    },
    showUsersList() {
      router.push({
        path: "/settings/user-list",
      });
    },
    showPermissionsList() {
      router.push({
        path: "/settings/permission-list",
      });
    },
    showBackupRestoreProgress() {
      router.push({
        path: "/settings/backup-restore",
      });
    },
    showCategoriesList(){
      router.push({
        path:"/settings/category-list",
      });
    },
  }
}
)