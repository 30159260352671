
import CategoriesList from "../components/sync/CategoriesList.vue";
import ParameterMapping from "../components/sync/ParameterMapping.vue";
import ValidationResults from "../components/sync/ValidationResults.vue";
import ComparisonResults from "../components/sync/ComparisonResults.vue";
import DxButton from "devextreme-vue/button";
import { formatMessage } from "devextreme/localization";
import { DxPopover } from "devextreme-vue/popover";
import DxTextBox from "devextreme-vue/text-box";
// @ts-ignore
import store from "../store";
import notify from "devextreme/ui/notify";
import Enums from "../enums/index";
import { bimAPI, propertyMappingAPI, validationAPI } from "@/utils/api";

export default {
  components: {
    CategoriesList,
    DxButton,
    DxPopover,
    DxTextBox,
    ParameterMapping,
    ValidationResults,
    ComparisonResults,
  },
  data() {
    return {
      step: 1,
      defaultVisible: false,
      newFilterName: "",
      availableCategories: [],
      availableParameters: [],
      availableModelData:{},
      selectedCategories: [],
      selectedFilter: null,
      filterCategories: [],
      searchMode: "contains",
      filters: [],
      modelId: null,
      validationResults: {},
      comparisonResults:[],
      deleteImportPopoverVisible: false,
      validationComplete: false,
      comparisonComplete: false,
      showDeleteButton: false
    };
  },
  async created() {

    this.modelId = this.$route.query.modelId;
    await this.getModelById(this.modelId, true);
    if (store.getters.getSelectedModel.revisionStatus != 1) {
      this.availableModelData = { modelId: store.getters.getSelectedModel.bimObjectId, revisionId: store.getters.getSelectedModel.revisionId, type: 0 };
    }
  },
  unmounted() {
    store.dispatch("setFMGuidParameter", {});
  },
  methods: {
    categoriesChange(event) {
      this.selectedCategories = event;
      this.selectedCategories.length = event.length;
    },
    categoriesLoadedHandler(event){
      this.availableCategories = event;
    },
    hideSpinner() {
      this.defaultVisible = false;
      store.dispatch("setLoader", false);
    },
    toggleDefault() {
      if (!this.selectedFilter) {
        this.defaultVisible = !this.defaultVisible;
        return;
      }
    },
    async backToFilesList(revertDraft) {
      const bimObject = store.getters.getSelectedModel;
      if (revertDraft) {
        if (bimObject.revisionStatus === 1) {
          store.dispatch("setLoader", true);
          await bimAPI.cancelDraftChanges({
            bIMClassesModelsAPIPayloadPayloadWithModelIds: {
              modelIds: [bimObject.bimObjectId]
            }
          })
            .catch( (error) => {
              console.log(error);
              const position = "bottom center";
              const direction = "up-push";
              const displayTime = 5000;
              notify({ message: "Error canceling draft for models: " + bimObject.bimObjectId, type: "error", displayTime }, {
                position,
                direction
              });
            })
        }
      }
      store.dispatch("setLoader", false);
      this.$router.go(-1);
    },
    newFilterNameChanged(event) {
      this.newFilterName = event.value;
    },
    filterSelected(event) {
      this.selectedFilter = event.selectedItem;
      this.filterCategories = event.selectedItem.categoriesSelected;
    },
    getTranslate(text) {
      return formatMessage(text);
    },
    getParameters() {
      let modelId = this.$route.query.modelId;
      const categories = this.selectedCategories.map((category) => {
        return {
          categoriesSelectedId: category.categoryId,
        };
      });
      propertyMappingAPI.addFilterAndSelectedCategories({
        filterWithSelectedCategories: {
          categoryFilterId: "",
          filterName: "Default",
          categoriesSelected: categories,
          modelId: store.getters.getSelectedModel.bimObjectId,
          revisionId: store.getters.getSelectedModel.revisionId
        }
      })
        .then(() => {
          notify(this.getTranslate("FileStatusSucceeded"), "success", 3000);

          store.dispatch("setLoader", false);
          propertyMappingAPI.getPropertiesByModelId({
            getPropertiesByModelIdRequest: {
              modelId: modelId,
              categories: categories,
            }
          } as any)
            .then((response) => {
              this.availableParameters = response;
              this.availableParameters.forEach((parameter, index) => {
                parameter.parameterId = index;
                if (parameter.propertyMapping && parameter.propertyMapping !== null) {
                  parameter["mapping"] = {};
                  parameter.mapping["name"] = parameter.propertyMapping.destinationProperty;
                  if (parameter.propertyMappingId) {
                    parameter.mapping["id"] = parameter.propertyMappingId;
                  }
                  if (parameter.propertyMapping.rules) {
                    parameter.propertyMapping.rules.forEach((ruleObject) => {
                      parameter = this.resolveMappingRules(parameter, ruleObject);
                    });
                  }
                  delete parameter.propertyMapping;
                }
              });
              this.step = 2;
              notify(this.getTranslate("FileStatusSucceeded"), "success", 3000);
            })
            .catch((error) => {
              console.error(error);
            });
          store.dispatch("setLoader", true);
        })
        .catch((error) => {
          if (error.response.status == 422) {
            notify(this.getTranslate("ReusableDraftChangesValidationFail"), "error", 5000);
          }
          console.error(error);
        });
    },
    resolveMappingRules(parameter, ruleObject) {
      const ruleName = Object.keys(ruleObject)[0];
      if (ruleName === "IsFmGuid" && ruleObject[ruleName] === true) {
        store.dispatch("setFMGuidParameter", parameter);
        parameter.mapping["fmGuid"] = ruleObject[ruleName];
      } else if (ruleName === "DataType") {
        const dataType = Object.keys(Enums.PropertyDataTypeEnum).find(
          (key) => Enums.PropertyDataTypeEnum[key] === ruleObject
        );
        parameter.mapping["type"] = dataType;
      } else if (ruleName === "setRule") {
        parameter.mapping["setRule"] = ruleObject;
      } else {
        parameter.mapping[
          ruleName.charAt(0).toLowerCase() + ruleName.slice(1)
        ] = ruleObject[ruleName];
      }
      return parameter;
    },
    async getModelById(modelId, revertToComparison) {
      await bimAPI.getModelById({ modelId: modelId })
        .then((response) => {
          store.dispatch("setSelectedModel", response);
          this.hideSpinner();
          if(response.revisionStatus == 1 && revertToComparison) {
            this.goToComparisonCheck();
          }
        })
        .catch((error) => {
          console.log(error);
          this.hideSpinner();
        });
    },
    async goToValidationCheck() {
      store.dispatch("setLoader", true);
      await this.getValidationResults();
      this.step = 3;
    },
    async goToComparisonCheck() {
      store.dispatch("setLoader", true);
      this.applyMappingAndCreateDraftToCompare()
      await this.getComparisonResults();
      await this.getModelById(this.modelId, false);
      this.step = 4;
    },
    async getValidationResults() {
      store.dispatch("setLoader", true);
      this.validationResults = await validationAPI.getValidationResultSummary({
        revisionId: store.getters.getSelectedModel.revisionId,
        modelId: store.getters.getSelectedModel.bimObjectId
      })
        .then((response) => {
          store.dispatch("setLoader", false);
          this.validationComplete = (((response.totalPropertyCount / response.totalPropertiesValidated) *100) === 100);
          notify(this.getTranslate("FileStatusSucceeded"), "success", 3000);
          return response;
        })
        .catch((error) => {
          store.dispatch("setLoader", false);
          console.error(error);
        });
    },
    async getComparisonResults() {
      store.dispatch("setLoader", true);
      this.comparisonResults = await validationAPI.getComparisonSummary({
        revisionId: store.getters.getSelectedModel.revisionId,
        modelId: store.getters.getSelectedModel.bimObjectId
      })
        .then((response) => {
          store.dispatch("setLoader", false);
          notify(this.getTranslate("FileStatusSucceeded"), "success", 3000);
          console.log(response.length);
          if(response.length > 0){
            this.comparisonComplete = true;
          }
          return response;
        })
        .catch((error) => {
          store.dispatch("setLoader", false);
          console.error(error);
        });
    },
    applyMappingAndCreateDraftToCompare() {
      propertyMappingAPI.applyPropertyMapping( { bIMClassesModelsAPIPayloadApplyPropertyMapping: {
        revisionId: store.getters.getSelectedModel.revisionId,
        modelId: store.getters.getSelectedModel.bimObjectId,
      }});
    },
    finaliseImport(){
      bimAPI.saveDraftChanges({
        bIMClassesModelsAPIPayloadDraftChanges: {
          complete: false,
          modelIds: [store.getters.getSelectedModel.bimObjectId],
          data: []
        }});
      notify(this.getTranslate("FinaliseSucceeded"), "success", 3000);
      this.backToFilesList(false);
    },
    showDeleteImportModal() {
      this.deleteImportPopoverVisible = true;
    },
    onDeleteImportPopoverHide() {
      this.deleteImportPopoverVisible = false;
    },
    deleteImport() {
      store.dispatch("setLoader", true);
      propertyMappingAPI.deletePropertyMapping({
        modelId: store.getters.getSelectedModel.bimObjectId,
        revisionId: store.getters.getSelectedModel.revisionId,
      } as any)
        .then(() => {
          this.backToFilesList(false);
          store.dispatch("setLoader", false);
        })
        .catch((error) => {
          console.error(error);
          store.dispatch("setLoader", false);
          notify(formatMessage("GetFilesError"), "error", 3000);
        });
    },
    onConfirmCancelPopoverHide() {
      this.deleteImportPopoverVisible = false;
    },
  },
};
