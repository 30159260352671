
import {
  DxColumn,
  DxDataGrid,
  DxEditing,
  DxFilterPanel,
  DxFilterRow,
  DxForm,
  DxHeaderFilter,
  DxItem,
  DxLabel,
  DxLookup,
  DxPager,
  DxPaging,
  DxPopup,
  DxSearchPanel,
  DxToolbar,
  DxRequiredRule,
} from "devextreme-vue/data-grid";
import { ParameterTypeEnum, PropertyDataTypeEnum, PropertyDataTypeForLookupsEnum } from "@/enums";
import CustomStore from "devextreme/data/custom_store";
import { formatMessage } from "devextreme/localization";
import notify from "devextreme/ui/notify";
import DxTagBox from "devextreme-vue/tag-box";
import { parameterAPI } from "@/utils/api";
import DxSwitch from "devextreme-vue/switch";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import DxButton from "devextreme-vue/button";
import DxCheckBox from "devextreme-vue/check-box";
import { saveAs } from "file-saver";
import { uuid } from "vue-uuid";
import store from "@/store";
import ExcelJS from "exceljs";
import auth from "@/authsrc/auth";
import { getAllDropdownCategories } from "../../utils/categories"
import CategoryTagBoxComponent from "./CategoryTagBoxComponent.vue";

const ruleValuesLookupRefKey = "rule-values-lookup";
const parametersListRefKey = "parameters-list"

const loadPropertyDataType = (items) => {
  let enums = [];
  for (let item in items) {
    enums.push({ id: items[item], name: item.toString(), visible: true });
  }
  return enums;
};


export default {
  components: {
    DxRequiredRule,
    DxButton,
    DxCheckBox,
    DxColumn,
    DxDataGrid,
    DxEditing,
    DxFileUploader,
    DxFilterPanel,
    DxFilterRow,
    DxForm,
    DxHeaderFilter,
    DxItem,
    DxLabel,
    DxLookup,
    DxPager,
    DxPaging,
    DxPopup,
    DxSearchPanel,
    DxSwitch,
    DxTagBox,
    DxToolbar,
    CategoryTagBoxComponent,
  },
  data() {
    return {

      categoriesData: new CustomStore({
        key: "CategoryId",
        loadMode: "raw",
        load: async () => {
          try {
            const defaultCategory = {
              CategoryId:"00000000-0000-0000-0000-000000000000" ,
              CategoryName: "All Categories",
            };
            const response = await getAllDropdownCategories();
            response.push(defaultCategory);
            return response;
          } catch (error) {
            console.error(error);
          }
        },
      }),
      parameters: new CustomStore({
        load: async () => {
          try {
            const response = await parameterAPI.getAllParameters();
            response.forEach(res => {
              res.categories = res.categories.map((element) =>  this._parseRawcategoriesOnLoad(element));
              res.sourceNames.forEach(sn => {
                sn.toString = () => this._concatSetWithName(sn);
              });
            });
            return response;
          } catch (error) {
            console.error(error);
          }
        },
        update: async (data, updateData) => {
          let newData = {} as any;
          Object.assign(newData, data);
          if (Object.hasOwnProperty.call(newData, "parameter")){
            if (Object.hasOwnProperty.call(newData.parameter, "tenantId")){
              delete newData.parameter.tenantId
            }
          }
          // Assign parameters
          if (Object.hasOwnProperty.call(updateData, "parameter")) {
            updateData.parameter.rule = this.getRule(updateData.parameter?.rule);
            newData.parameter = {
              ...newData.parameter,
              ...updateData.parameter,
              rule: {
                ...newData.parameter.rule,
                ...updateData.parameter.rule
              },
            }
          }

          if (updateData.parameter?.rule?.setRule === undefined) {
            newData.parameter.rule = null;
          }

          if (Object.hasOwnProperty.call(updateData, "sourceNames")) {
            newData.sourceNames = [
              ...updateData.sourceNames
            ]
          }

          if (Object.hasOwnProperty.call(updateData, "categories")) {
            newData.categories = [
              ...updateData.categories
            ]}

          if (Object.hasOwnProperty.call(newData, "categories")) {
            newData.categories = newData.categories.map((item) => {
              if(Object.hasOwnProperty.call(item, "categoryId"))
              {
                return item
              }
              else
              {
                return this._parseRawcategories(item);
              }

            });
          }
          const allCategory = newData.categories.filter(m => m.categoryId ==="00000000-0000-0000-0000-000000000000")
          const index = newData.categories.indexOf(allCategory[0]);
          if (index > -1) {
            newData.categories.splice(index, 1);
          }
          return parameterAPI.updateParameter({ parameterWithSourceNames: newData });
        },
        insert: async (data) => {
          if (data.parameter?.rule?.ruleValues) {
            const ruleValues = data.parameter.rule.ruleValues;
            data.parameter.rule.dataType = this._specifyDataType(ruleValues);
            data.parameter.rule.setRule = 1;
          }
          try {
            const allCategory = data.categories.filter(m => m.categoryId ==="00000000-0000-0000-0000-000000000000")
            const index = data.categories.indexOf(allCategory[0]);
            if (index > -1) {
              data.categories.splice(index, 1);
            }
            await parameterAPI.createParameter({ parameterWithSourceNames: data });
            notify(formatMessage("Success"), "success", 5000);
            return data;
          } catch (error) {
            console.error(error);
            if (error.response.status == 409) {
              notify(formatMessage("ParameterAlreadyExists", data.parameter.name), "error", 5000);
            }
            else{
              notify(formatMessage("Error"), "error", 3000);
            }
          }
        },
        remove: async (selected) => {
          if (!selected.parameter) {
            return;
          }

          const parameterId = selected.parameter.parameterId;

          try {
            await parameterAPI.deleteParameter({ parameterId: parameterId });
          } catch (error) {
            console.error(error);
          }
        },
      }),
      labels: {
        createParameterLabel: formatMessage("CreateParameter"),
        nameLabel: formatMessage("ReusableNameLabel"),
        reusableLookupLabel: formatMessage("ReusableLookupLabel"),
        reusableLookupValuesLabel: formatMessage("ReusableLookupValuesLabel"),
        selectFileLabel: formatMessage("SelectFile"),
        importExcelLabel: formatMessage("ImportExcel"),
        exportExcelLabel: formatMessage("ExportExcel"),
        excelTemplateLabel: formatMessage("ExcelTemplate"),
        reusablePaginationLabel: formatMessage("ReusablePaginationLabel")
      },
      propertyDataType: loadPropertyDataType(PropertyDataTypeEnum),
      addParameterButtonOptions: {
        text: formatMessage("CreateParameter"),
      },
      toggleLookupSwitchFlag: false,
      lookupValuesVisible: false,
      ruleValuesLookupRefKey,
      parametersListRefKey,
      currentEditingRow: {
        dataType: undefined,
      },
      permissions: auth.getPermissions(),

      calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        if (target === "search" && typeof (filterValue) === "string") {
          return [this.dataField, "contains", filterValue];
        }
        return function(data) {
          return (data.categories || []).indexOf(filterValue) !== -1;
        };
      },
    };
  },
  methods: {

    getRule(updateDataParameterRule) {
      if (updateDataParameterRule) {
        if (updateDataParameterRule.ruleValues.length > 0) {
          const ruleValues = updateDataParameterRule.ruleValues;
          updateDataParameterRule.dataType = this._specifyDataType(ruleValues);
          updateDataParameterRule.setRule = 1;
        }
        else {
          updateDataParameterRule.setRule = null;
        }
        return updateDataParameterRule;
      }
      return null;
    },

    cellTemplate(container, options) {
      const noBreakSpace = "\u00A0";
      const text = (options.value || []).map((element) => options.column.lookup.calculateCellValue(element)).join(", ");
      container.textContent = text || noBreakSpace;
      container.title = text;
    },

    onValueChanged(value, cellInfo) {
      const parsedvalue = [];
      value.forEach(element => {

        parsedvalue.push(this._parseRawcategories(element));
      });
      cellInfo.setValue(parsedvalue);
      cellInfo.component.updateDimensions();
    },

    _parseRawcategories(element) {
      return {
        categoryId: element
      };
    },
    _parseRawcategoriesOnLoad(element) {
      return element.categoryId;
    },
    lookup() {
      return this.$refs[ruleValuesLookupRefKey].instance;
    },
    parametersList() {
      return this.$refs[parametersListRefKey].instance;
    },
    onEditorPreparing(event) {
      if (event.dataField === "parameter.dataType" && event.parentType === "dataRow") {
        this._overrideDataTypeOnValueChanged(event);
      }
    },
    calculateValueForRuleValues(value) {
      return !!value.parameter?.rule?.ruleValues && value.parameter?.rule?.setRule === 1;
    },
    getTranslate(text) {
      return formatMessage(text);
    },
    onParameterSourceNameValueChanged(value, data) {
      const parsedSourceNames = value.value.map((text) => {
        if (typeof (text) === "object") {
          return text;
        }
        return this._parseRawSourceNames(text);
      });
      data.setValue(parsedSourceNames);
      data.component.updateDimensions();
    },
    onLookupValueChanged(event, data) {
      data.setValue(event.value);
      data.component.updateDimensions();
    },
    onOptionChanged(event) {
      if (event.name === "editing" && event.fullName === "editing.changes") {
        for (const value of event.value) {
          this.onRowChanged(value)
        }
      }
    },
    onRowChanged(value) {
      if (value?.data?.parameter?.dataType !== undefined) {
        this.currentEditingRow.dataType = value.data.parameter.dataType;
        this.currentEditingRow.parameterType = value.data.parameter.parameterType;
      }
    },
    onInitNewRow(event) {
      event.data = {
        ...event.data,
        parameter: {
          dataType: 0
        }
      }
      this.lookupValuesVisible = false;
      this.labels.createParameterLabel = formatMessage("CreateParameter");
      if(!this.currentEditingRow.canBeMapped){
        this.currentEditingRow.canBeMapped = true;
      }
    },
    onEditingStart(event) {
      this.currentEditingRow.dataType = event?.data?.parameter?.dataType;
      this.currentEditingRow.parameterType = event?.data?.parameter?.parameterType;
      this.currentEditingRow.canBeMapped = event?.data?.parameter?.canBeMapped;
      this.toggleLookupSwitchFlag = !!event.key.parameter?.rule?.ruleValues && event.key.parameter?.rule.setRule === 1;
      this.lookupValuesVisible = this.toggleLookupSwitchFlag;
      this.toggleDataType(this.toggleLookupSwitchFlag);
      this.labels.createParameterLabel = formatMessage("EditParameter");
    },
    onLookupSwitchChanged(event, data) {
      if (event.value) {
        data.setValue(1);
      } else {
        data.setValue(null);
      }
      this.lookupValuesVisible = event.value;
      this.toggleDataType(event.value);
    },
    onAddParameterClick() {
      this.toggleLookupSwitchFlag = false;
      this.toggleDataType(this.toggleLookupSwitchFlag);
    },
    onEditCanceling() {
      this.toggleDataType(false);
    },
    toggleDataType(isLookupValueSwitchOn) {
      if (isLookupValueSwitchOn) {
        const enums = PropertyDataTypeForLookupsEnum;
        this.propertyDataType.forEach(property => {
          property.visible = property.id === enums.String || property.id === enums.Decimal || property.id === enums.Int64 || property.id === enums.Int32;
        })
      } else {
        this.propertyDataType.forEach(property => property.visible = true);
      }
    },
    onImportFile(file) {
      this.lookup().reset();
      const reader = new FileReader();
      reader.readAsArrayBuffer(file.value[0]);
      reader.onload = () => {
        const buffer = reader.result as ArrayBuffer;
        const dataType = this.currentEditingRow.dataType;
        const workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(buffer).then(workbook => {
          const ruleValues = [];
          const firstWorksheet = workbook.worksheets[0];
          this.lookup().beginUpdate();
          firstWorksheet.eachRow((row) => {
            const lookupValue = row.values[1];
            if (lookupValue === undefined || lookupValue === null || lookupValue.trim().length === 0) {
              return;
            }
            ruleValues.push(this._createRuleValue(dataType, lookupValue));
          });
          this.lookup().option("value", ruleValues);
          this.lookup().endUpdate();
        }).catch(() => {
          notify(formatMessage("InvalidFile"), "error", 5000);
        });
      };
    },
    onImportParametersList(file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file.value[0]);
      reader.onload = () => {
        const buffer = reader.result as ArrayBuffer;
        const workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(buffer).then(workbook => {
          const parameters = [];
          const firstWorksheet = workbook.worksheets[0];
          const column = {
            id: 1,
            name: 2,
            dataType: 3,
            collections: 4
          };
          this._validateImportParametersList(firstWorksheet, column);
          firstWorksheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              return;
            }
            const parameter = this._parseExcelRowToParameterObject(row, column);
            let sourceNames = [];
            if (row.values[column.collections]?.toUpperCase() === "SOURCE NAMES:") {
              sourceNames = this._parseSourceNamesToParameterObject(row, column);
            }
            let categories = [];
            categories = this._parseCategoriesToParameterObject(row, column);
            parameters.push({ parameter, sourceNames,categories });
          });
          store.dispatch("setLoader", true);
          this._importParametersList({ parameters: parameters });
        });
      };
    },
    exportToExcel(fileName, values) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet();
      worksheet.insertRows(1, values);
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          fileName
        );
      });
    },
    exportListTemplate() {
      const values = this.prepareTemplate();
      this.exportParameterListToExcel("Parameter list template.xlsx", values);
    },
    prepareTemplate() {
      console.log("prepareTemplate");
      return [
        { "parameter": { "name": "Parameter name 1", "dataType": "0" }, "sourceNames": [{ "name": "Source Name 1", "set": "Source Set 1" }],
          "categories": [{ "name": "Category Name 1" }]},
        { "parameter": { "parameterId": "40794abc-87e1-487d-ba8d-70304e35d358", "name": "Parameter name 2", "dataType": "3" },
          "sourceNames": [{ "name": "Source Name 1" }],"categories": [{ "name": "Category Name 2" }]},
        { "parameter": { "parameterId": "b0eab559-90e9-4db2-be7a-35024c11e0a0", "name": "Parameter name 3", "dataType": "2" },
          "sourceNames": [{ "name": "Name 1", "set": "Set 1" }, { "name": "Name 2", "set": "Set 2" }, { "name": "Name 3", "set": "Set 3" }],
          "categories": [{ "name": "Category3" },{ "name": "Category4" }]}
      ];
    },
    exportParameterListToExcel(fileName, values) {
      const headers = [["ID", "Name", "Data type","Collections"]];
      const excelValues = values.map(r => {
        const sourceNames = r.sourceNames.map(x => this._concatSetWithName(x));
        const categories = r.categories.map(x => this._concatCategories(x));
        return [r.parameter.parameterId, r.parameter.name, this.propertyDataType[r.parameter.dataType].name, "Source Names:", ...sourceNames,"Categories:",...categories];
      });
      const cells = headers.concat(excelValues);
      this.exportToExcel(fileName, cells);
    },
    _concatCategories(category) {
      if (!category) {
        return undefined;
      }
      else
      {
        if((Object.hasOwnProperty.call(category, "categoryName")))
        {
          return category.categoryName.toString()
        }
        if((Object.hasOwnProperty.call(category, "name")))
        {
          return category.name.toString()
        }
      }
    },
    onExportParametersList() {
      parameterAPI.getAllParameters()
        .then((response) => {
          this.exportParameterListToExcel("parameters.xlsx", response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    exportLookupValues() {
      const dataType = this.currentEditingRow.dataType;
      const values = this.lookup().option("value").map(value => [this.ruleValueToString(dataType, value)]);
      this.exportToExcel("values.xlsx", values);
    },
    _createRuleValue(dataType, stringValue) {
      let ruleValue = {
        ruleValueId: uuid.v4(),
        valueString: undefined,
        valueDecimal: undefined,
        valueInt64: undefined,
        valueInt32: undefined
      };
      switch (dataType) {
        case PropertyDataTypeEnum.Int64:
          ruleValue.valueInt64 = Number.parseInt(stringValue);
          break;
        case PropertyDataTypeEnum.Decimal:
          ruleValue.valueDecimal = Number.parseFloat(stringValue);
          break;
        case PropertyDataTypeEnum.String:
          ruleValue.valueString = stringValue;
          break;
        case PropertyDataTypeEnum.Int32:
          ruleValue.valueInt32 = Number.parseInt(stringValue);
          break;
        default:
          return undefined;
      }
      return ruleValue;
    },
    ruleValueToString(dataType, ruleValue) {
      switch (dataType) {
        case PropertyDataTypeEnum.Int32:
          return ruleValue.valueInt32.toString();
        case PropertyDataTypeEnum.Int64:
          return ruleValue.valueInt64.toString();
        case PropertyDataTypeEnum.Decimal:
          return ruleValue.valueDecimal.toString();
        case PropertyDataTypeEnum.String:
          return ruleValue.valueString;
        default:
          return undefined;
      }
    },
    displayExpressionLookupValue(item) {
      const dataType = this.currentEditingRow.dataType;
      switch (dataType) {
        case PropertyDataTypeEnum.Int32:
          return item.valueInt32;
        case PropertyDataTypeEnum.Int64:
          return item.valueInt64;
        case PropertyDataTypeEnum.Decimal:
          return item.valueDecimal;
        case PropertyDataTypeEnum.String:
          return item.valueString;
        default:
          return undefined;
      }
    },
    onCustomLookupValueCreating(event) {
      let text;
      if (event.text === undefined || event.text === null || event.text.trim().length === 0) {
        text = NaN;
      } else {
        text = event.text;
      }

      const dataType = this.currentEditingRow.dataType;
      event.customItem =
        {
          ruleValueId: uuid.v4(),
          valueInt32: dataType === PropertyDataTypeEnum.Int32 ? +text : undefined,
          valueInt64: dataType === PropertyDataTypeEnum.Int64 ? +text : undefined,
          valueDecimal: dataType === PropertyDataTypeEnum.Decimal ? +text : undefined,
          valueString: dataType === PropertyDataTypeEnum.String ? text : undefined
        }
    },
    onRowPrepared(row) {
      if (row.rowType === "data" && row.data.parameter.parameterType === ParameterTypeEnum.System) {
        row.rowElement.classList.add("system-parameter");
      }
    },
    isSystemParameter() {
      return this.currentEditingRow.parameterType === ParameterTypeEnum.System;
    },
    CannotBeMappedParameter() {
      if(this.currentEditingRow.canBeMapped != undefined)
      {
        return !this.currentEditingRow.canBeMapped;
      }
      return false;
    },
    isNotSupportedDataType(){
      if(this.currentEditingRow.dataType != undefined){
        const DataTypeEnum = PropertyDataTypeEnum;
        const isBool = this.currentEditingRow.dataType === DataTypeEnum.Bool;
        const isDateTime = this.currentEditingRow.dataType === DataTypeEnum.DateTime;
        if (isBool || isDateTime) return true;
      }
      return false;
    },
    _overrideDataTypeOnValueChanged(event) {
      const defaultValueChangeHandler = event.editorOptions.onValueChanged;
      event.editorOptions.onValueChanged = (args) => {
        this.lookup().reset();
        defaultValueChangeHandler(args);
      }
    },
    _validateFirstRow(row) {
      const errorResults = [];
      if (row.getCell(1).value?.toUpperCase() !== "ID") {
        errorResults.push("ID");
      }
      if (row.getCell(2).value?.toUpperCase() !== "NAME") {
        errorResults.push("Name");
      }
      if (row.getCell(3).value?.toUpperCase() !== "DATA TYPE") {
        errorResults.push("Data Type");
      }
      if (row.getCell(4).value?.toUpperCase() !== "COLLECTIONS") {
        errorResults.push("Collections");
      }
      return errorResults;
    },
    _parseExcelRowToParameterObject(row, column) {
      return {
        parameterId: row.values[column.id],
        name: row.values[column.name],
        dataType: this._parseDataTypeRowToAPIDataType(row.values[column.dataType]),
        parameterType: ParameterTypeEnum.Tenant
      };
    },
    _parseSourceNamesToParameterObject(row, column) {
      const sourceNames = [];
      const numberOfColumnInRowHavingValues = row.values.length - column.collections - 1;
      const indexOfFirstColumnHavingCategories = row.values.indexOf("Categories:");
      if (numberOfColumnInRowHavingValues < 1) {
        return sourceNames;
      } else {
        const indexOfFirstColumnHavingSourceNameValue = column.collections + 1;

        const indexOfLastColumnHavingSourceNameValue = indexOfFirstColumnHavingCategories > 0 ? indexOfFirstColumnHavingCategories -1 :column.collections + numberOfColumnInRowHavingValues;
        for (let columnIndex = indexOfFirstColumnHavingSourceNameValue; columnIndex <= indexOfLastColumnHavingSourceNameValue; columnIndex++) {
          const text = row.values[columnIndex];
          const sourceName = this._parseRawSourceNames(text);
          sourceNames.push(sourceName);
        }
        return sourceNames;
      }
    },
    _parseCategoriesToParameterObject(row, column) {
      const categories = [];
      const numberOfColumnInRowHavingValues = row.values.length - column.collections - 1;
      const indexOfFirstColumnHavingCategories = row.values.indexOf("Categories:");
      if (indexOfFirstColumnHavingCategories < 1) {
        return categories;
      } else {
        const indexOfFirstColumnHavingCategoryValue = indexOfFirstColumnHavingCategories + 1;
        const indexOfLastColumnHavingCategoryValue = column.collections + numberOfColumnInRowHavingValues;
        for (let columnIndex = indexOfFirstColumnHavingCategoryValue; columnIndex <= indexOfLastColumnHavingCategoryValue; columnIndex++) {
          const text = row.values[columnIndex];
          const category = this._parseRawCategories(text);
          categories.push(category);
        }
        return categories;
      }
    },
    _parseRawSourceNames(text) {
      if (text.includes("/")) {
        const index = text.indexOf("/");
        return {
          name: text.substring(index + 1),
          set: text.substring(0, index)
        };
      }
      return {
        name: text
      };
    },
    _parseRawCategories(text) {
      return {
        categoryName: text,
      };
    },
    _parseDataTypeRowToAPIDataType(friendlyNameDataType) {
      switch (friendlyNameDataType) {
        case "String":
          return PropertyDataTypeEnum.String;
        case "Decimal":
          return PropertyDataTypeEnum.Decimal;
        case "Bool":
          return PropertyDataTypeEnum.Bool;
        case "Int32":
          return PropertyDataTypeEnum.Int32
        case "Int64":
          return PropertyDataTypeEnum.Int64;
        case "DateTime":
          return PropertyDataTypeEnum.DateTime
        default:
          return -1;
      }
    },
    _validateImportParametersList(firstWorksheet, column) {
      const validationErrors = {
        id: 0,
        name: 0,
        dataType: 0,
        collections: 0
      }
      firstWorksheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) {
          const errorResults = this._validateFirstRow(row);
          if (errorResults.length > 0) {
            notify(formatMessage("MissingColumnNamesInTheFirstRow", errorResults.join(", ")), "error", 5000);
            throw Error();
          }
        } else {
          this._countValidationErrorsPerRow(row, column, validationErrors);
        }
      });
      const formattedMessages = this._formatValidationErrors(validationErrors);
      if (formattedMessages.length > 0) {
        notify(formattedMessages.join("\n"), "error", 5000);
        throw Error();
      }
    },
    _formatMessageOfImportingParametersListValidationError(numberOfErrors, columnName) {
      if (numberOfErrors === 1) {
        return formatMessage("ImportParametersListValidationErrorSingularForm", numberOfErrors, columnName);
      } else {
        return formatMessage("ImportParametersListValidationErrorPluralForm", numberOfErrors, columnName);
      }
    },
    _importParametersList(payload) {
      parameterAPI.updateAllParameters({ updateAllParametersRequest: payload })
        .then((response) => {
          this.parametersList().refresh(true);
          store.dispatch("setLoader", false);

          if(response.length > 0){
            notify(formatMessage("PartialSuccess"), "warning", 5000);
          }
          else{
            notify(formatMessage("Success"), "success", 5000);
          }
        })
        .catch((error) => {
          console.error(error);
          store.dispatch("setLoader", false);
          notify(formatMessage("Error"), "error", 5000);
        });
    },
    _countValidationErrorsPerRow(row, column, validationErrors) {
      if (row.values[column.id] && (row.values[column.id].split("-").length !== 5 || !row.values[column.id].match(/[0-9A-F]{8}-(?:[0-9A-F]{4}-){3}[0-9A-F]{12}/i))) {
        ++validationErrors.id;
      }
      if (!row.values[column.name] || !row.values[column.name].trim()) {
        ++validationErrors.name;
      }
      if (this._parseDataTypeRowToAPIDataType(row.values[column.dataType]) === -1) {
        ++validationErrors.dataType;
      }
      return validationErrors;
    },
    _formatValidationErrors(validationErrors) {
      const formattedMessages = [];
      if (validationErrors?.id !== 0) {
        formattedMessages.push(this._formatMessageOfImportingParametersListValidationError(validationErrors.id, "ID"));
      }
      if (validationErrors?.name !== 0) {
        formattedMessages.push(this._formatMessageOfImportingParametersListValidationError(validationErrors.name, "Name"));
      }
      if (validationErrors?.dataType !== 0) {
        formattedMessages.push(this._formatMessageOfImportingParametersListValidationError(validationErrors.dataType, "Data type"));
      }
      if (validationErrors?.collections !== 0) {
        formattedMessages.push(this._formatMessageOfImportingParametersListValidationError(validationErrors.collections, "Collections"));
      }
      return formattedMessages;
    },
    _concatSetWithName(sourceName) {
      if (sourceName.set) {
        return `${sourceName.set}/${sourceName.name}`;
      }
      return sourceName.name;
    },
    getCategoryValue(cellInfo){
      let updatedCellInfoValue = cellInfo.value.length > 0 ? cellInfo.value : cellInfo.value.push("00000000-0000-0000-0000-000000000000")
      console.log(updatedCellInfoValue);
      return cellInfo.value;
    },
    _specifyDataType(ruleValues): number {
      if (ruleValues.some(x => x.valueInt64)) {
        return PropertyDataTypeEnum.Int64;
      }
      if (ruleValues.some(x => x.valueInt32)) {
        return PropertyDataTypeEnum.Int32;
      }
      if (ruleValues.some(x => x.valueDecimal)) {
        return PropertyDataTypeEnum.Decimal;
      }
      if (ruleValues.some(x => x.valueString)) {
        return PropertyDataTypeEnum.String;
      } else {
        return NaN;
      }
    }
  },
};
