
import DxPopup from "devextreme-vue/popup";
import CreateProject from "../components/project/CreateProject.vue";
import { formatMessage } from "devextreme/localization";
import ComplexList from "../components/ComplexList.vue";
import { defineComponent } from "vue";
import CategoriesList from "../components/sync/CategoriesList.vue";
import store from "../store";
import { DxButton } from "devextreme-vue/button";
const complexListRefKey = "complex-list"

const typeToStepDict = {
  0: 1,
  1: 2,
  5: 3
};

export default defineComponent({
  components: {
    DxPopup,
    DxButton,
    CreateProject,
    ComplexList,
    CategoriesList
  },
  data() {
    return {
      isAddComplexModalVisible: false,
      isCategoriesPopupVisible: false,
      modalStep: 0,
      wizardData: {},
      editMode: false,
      modalTitle: formatMessage("ReusableCreateComplexLabel"),
      categoriesPopupTitle: formatMessage("Categories"),
      complexListKey: 0,
      paths: {
        dataGrid: "/data-grid/",
        dataGridAnd3d: "/data-grid-3d/",
        viewer: "/viewer/"
      },
      complexListRefKey,
      modelIdsDataProp: [],
      selectedCategories: [],
      include3d: false,
      archiveVisible: false,
      modelIdsData: [],
      clearCategories: false
    };
  },
  methods: {
    showPopup(complex, objectType, editMode) {
      this.modalStep = typeToStepDict[objectType];
      this.wizardData = complex;
      this.isAddComplexModalVisible = true;
      this.editMode = editMode;
      this.changeModalTitle(this.modalStep, editMode);
    },
    onCategoriesPopupHiding() {
      store.dispatch("setIfcCategories", {});
      this.modelIdsData = [];
      this.modelIdsDataProp = [];
      this.isCategoriesPopupVisible= false;
      this.hideSpinner();
      this.complexList().reloadData();
    },
    onPopupHiding() {
      this.isAddComplexModalVisible = false;
      this.complexListKey++;
      if(this.modalStep > 0){
        this.complexList().reloadData();
      }
    },
    hideCreateProjectPopup() {
      this.isAddComplexModalVisible = false;
    },
    changeModalTitle(modalStep, editMode) {
      this.modalTitle = editMode ? formatMessage("ReusableEdit") : formatMessage("ReusableCreate");
      this.modalTitle += " ";
      this.modalStep = modalStep;
      if (this.modalStep === 2) {
        this.modalTitle += formatMessage("ReusableEntity");
      } else if (this.modalStep === 3) {
        this.modalTitle += formatMessage("ReusableModelsLabel");
      } else {
        this.modalTitle += formatMessage("ReusableComplexLabel");
      }
    },
    showSpinner() {
      store.dispatch("setLoader", true);
    },
    hideSpinner() {
      store.dispatch("setLoader", false);
    },
    goToDataGrid() {
      this.isCategoriesPopupVisible= false;
      this.hideSpinner();
      this.$router.push({
        path: this.include3d ? this.paths.dataGridAnd3d : this.paths.dataGrid,
        query: {
          models: this.modelIdsData.map(mId=>mId.modelId),
          showExpired: this.archiveVisible,
          revisionStatus: this.modelIdsData[0].revisionStatus,
          revisionId: this.modelIdsData[0].revisionId
        },
      });
    },
    categoriesPopupShown() {
      // need this workaround to ensure binding happens when dom element is rendered, otherwise prop is not triggered
      this.showSpinner();
      this.modelIdsDataProp = this.modelIdsData;
    },
    openCategoriesSelection(modelList, include3d, archiveVisible) {
      this.isCategoriesPopupVisible = true;
      this.modelIdsData = modelList;
      this.include3d = include3d;
      this.archiveVisible = archiveVisible;
    },
    async categoriesSelected(event) {
      this.selectedCategories = event;
      this.selectedCategories.length = event.length;
      const ifcCategories = this.selectedCategories.map(s => s.mappedExternalCategories).flat().filter(c => c.categoryType === "IfcCategory").map(i => i.categoryName);
      store.dispatch("setIfcCategories", ifcCategories);
    },
    categoriesLoadedHander(){
      this.hideSpinner();
    },
    formatMessage,
    complexList: function() {
      return this.$refs[this.complexListRefKey];
    },
  },
});
