
import DxButton from "devextreme-vue/button";
import {
  DxForm,
  DxGroupItem,
  DxItem,
  DxLabel,
  DxPatternRule,
  DxRequiredRule,
  DxSimpleItem,
} from "devextreme-vue/form";
import DxDateBox from "devextreme-vue/date-box";
import DxTabs from "devextreme-vue/tabs";
import auth from "../../authsrc/auth";
import store from "../../store";
// @ts-ignore
import ConfigValues from "../../utils/configValues";
import notify from "devextreme/ui/notify";
import validationEngine from "devextreme/ui/validation_engine";
import { ShareServiceClient } from "@azure/storage-file-share";
import { uuid } from "vue-uuid";
import { formatMessage } from "devextreme/localization";
import DxLoadIndicator from "devextreme-vue/load-indicator";
import { v4 as uuidv4 } from "uuid";
// @ts-ignore
import FileUploadOnPremise from "../../utils/fileUploadOnPremise";
import { bimAPI, defaultAPI, fileAPI } from "@/utils/api";
import {
  GetBimObjectsByTypeResponseComplexRevisionStatusEnum,
  BimObjectObjectTypeEnum,
  BimObjectWithParent,
  BimObject,
  BimObjectExternalIdTypeEnum,
  BimObjectStatusEnum,
  ExpireBimObject, BimObjectWithParentUsedIdentifierEnum
} from "@swg/api";
import moment from "moment";
import * as _ from "lodash";

const position = "bottom center";
const direction = "up-push";

export default {
  name: "CreateProject",
  components: {
    DxButton,
    DxTabs,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxItem,
    DxLabel,
    DxPatternRule,
    DxRequiredRule,
    DxLoadIndicator,
    DxDateBox
  },
  props: {
    popupVisible: { type: Boolean, required: true },
    editMode: { type: Boolean, required: true },
    startStep: { type: Number, required: true },
    wizardData: { type: Object, required: true }
  },
  emits: ["input", "changeModalTitle", "hideCreatingProjectPopup"],
  data() {
    let entitiesOptions = this.getEntitiesOptions([]);
    return {
      complex: {},
      entities: [],
      compareEntities:[],
      differentModels:[],
      differentEntities:[],
      entity: {},
      entitiesOptions,
      modalStep: 1,
      selectedTabIndex: 0,
      entitiesForTabs: [],
      modelsOptions: [],
      model: {},
      entityLimit: 50,
      modelLimit: 50,
      sleepDelay:10000,
      entityId: 0,
      sas: "",
      idInputPlaceholder: this.returnPlaceholder("ReusableIdLabel"),
      externalGuidInputPlaceholder: this.returnPlaceholder("ReusableGuidLabel"),
      fmGuidInputPlaceholder: this.returnPlaceholder("ReusableFmGuidLabel"),
      guidPattern: "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}",
      nameInputOptions: {
        placeholder: formatMessage("ReusableEntityInputNamePlaceholder"),
      },
      waitingForResponse: false,
      userWarnedAboutMissingFile: false,
      permissions: auth.getPermissions(),
      revisionStatus: GetBimObjectsByTypeResponseComplexRevisionStatusEnum
    };
  },
  watch: {
    popupVisible: function (value) {
      if (value) {
        if (this.wizardData) {
          this.loadWizardData();
        }
      } else {
        this.setEmptyFormItems();
      }
    },
    entities: {
      handler() {
        this.differentModels = [];
        this.entities.forEach(e => {
          const compareEntity = this.compareEntities.find(x => x.bimObjectId === e.bimObjectId)
          if(compareEntity != undefined){
            const noChange = _.isEqual(e, compareEntity);
            if(!noChange){
              this.differentEntities.push(e.bimObjectId);
            }

            if(e.models != undefined && compareEntity.models != undefined){
              const dif = _.differenceWith(e.models, compareEntity.models, _.isEqual);
              this.differentModels.push(dif.map(d => d.bimObjectId));
            }
          }
        });
      },
      deep: true,
    },
  },
  async created() {
    this.setEmptyFormItems();
    if (this.wizardData) {
      this.loadWizardData();
    }

    if (!ConfigValues.onPremise) {
      const data = await fileAPI.getToken();
      this.sas = data.sasToken;
    }
  },
  methods: {
    sleep(milliseconds){
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    save(step: number) {
      switch (step) {
        case 1:
          this.saveComplex();
          break;
        case 2:
          this.saveEntities();
          break;
        case 3:
          this.saveModels();
          break;
      }
    },
    uploadingFileDisabled(oneModel) {
      return oneModel.revisionStatus === GetBimObjectsByTypeResponseComplexRevisionStatusEnum.Draft;
    },
    setCompareEntities(entities){
      this.compareEntities = _.cloneDeep(entities);
      this.differentEntities = [];
      this.differentModels = [];
    },
    async saveComplex() {
      const complexForm = this.$refs["complexForm"].instance;
      let validationResult = validationEngine.validateGroup(complexForm);
      if (!validationResult.isValid) {
        return;
      }

      let createComplexResponseCode = await this.createOrEditObject(this.complex, BimObjectObjectTypeEnum.Complex);

      if (createComplexResponseCode >= 400) {
        if (createComplexResponseCode == 422) {
          notify({
            message: this.getTranslate("ComplexValidationFailed") + this.complex.name,
            type: "error",
            displayTime: 3000
          }, { position, direction });
        } else {
          this.hidePopup();
          notify({
            message: this.getTranslate("ComplexCreateFailed") + this.complex.name,
            type: "error",
            displayTime: 3000
          }, { position, direction });
        }
      } else {
        this.modalStep++;
        this.$emit("changeModalTitle", this.modalStep);
      }
    },
    checkEntitiesForms() {
      let valid = true;
      if (this.entities.length) {
        for (let i = 0; i < this.entities.length; i++) {
          const entityForm = this.$refs["entitiesForms" + i].instance;
          let validationResult = validationEngine.validateGroup(entityForm);
          if (!validationResult.isValid) {
            valid = false;
          }
        }
      }
      return valid;
    },
    async saveEntities() {
      if (!this.checkEntitiesForms()) {
        return;
      }
      if (this.entities.length) {
        await this.validateAddEntities();
      }
    },
    async validateAddEntities() {
      let validationError = false;

      for (let newEntity of this.entities) {
        if(newEntity.bimObjectId == undefined || this.differentEntities.find(m => m == newEntity.bimObjectId.toString()) != undefined){
          let createEntityCode = await this.createOrEditObject(newEntity, BimObjectObjectTypeEnum.Building);

          if (createEntityCode == 422) {
            validationError = true;
            notify({
              message: this.getTranslate("ComplexValidationFailed") + this.complex.name,
              type: "error",
              displayTime: 3000
            }, { position, direction });
          } else if (createEntityCode >= 400) {
            this.hidePopup();
            notify({
              message: this.getTranslate("ComplexCreateFailed") + newEntity.name,
              type: "error",
              displayTime: 3000
            }, { position, direction });
          }
        }
      }

      if (!validationError) {
        this.modalStep++;
        this.$emit("changeModalTitle", this.modalStep);
        this.addEntitiesForTabs();
      }
    },
    addEntitiesForTabs() {
      if (!this.entitiesForTabs.length) {
        this.entitiesForTabs = this.entities.map((entityTab) => ({
          ...entityTab,
          text: entityTab.entityId + 1 + ". " + entityTab.name,
          icon: "mergecells",
          models: entityTab.models ?? [Object.assign({}, this.model)],
        }));

      } else {
        const entitiesForTabsIds = this.entitiesForTabs.map(
          (tabItem) => tabItem.entityId
        );
        const newEntitesForTabs = [];
        this.entities.forEach((entityItem) => {
          if (entitiesForTabsIds.includes(entityItem.entityId)) {
            newEntitesForTabs.push({
              ...this.entitiesForTabs.find(
                (tab) => tab.entityId === entityItem.entityId
              ),
              name: entityItem.name,
              text: entityItem.entityId + 1 + ". " + entityItem.name,
            });
          } else {
            newEntitesForTabs.push({
              ...entityItem,
              text: entityItem.name,
              icon: "mergecells",
              models: [Object.assign({}, this.model)],
            });
          }
        });
        this.entitiesForTabs = newEntitesForTabs;
      }
    },
    isNextButtonDisabled() {
      if (this.modalStep === 2) {
        if (!this.entities.length) {
          return true;
        } else {
          return this.entities.map((item) => item.name).includes("");
        }
      }
    },
    isCompleteButtonDisabled() {
      let disabled = false;
      this.entitiesForTabs.forEach((entityCheck) => {
        entityCheck.models.forEach((modelCheck) => {
          if (modelCheck.name === "") {
            disabled = true;
          }
        });
      });
      return disabled;
    },
    addEntity() {
      if(!this.entities.length){
        this.entityId++;
      }else{
        this.entityId = this.entities.length;
      }
      this.entity.entityId = this.entityId;
      this.entities.push(JSON.parse(JSON.stringify(this.entity)));
      this.entitiesOptions = this.getEntitiesOptions(this.entities);
    },
    getEntitiesOptions(currentEntities) {
      let options = [];
      for (let i = 0; i < currentEntities.length; i++) {
        options.push(this.generateNewEntityOptions(i));
      }
      return options;
    },
    generateNewEntityOptions(index) {
      return {
        icon: "remove",
        type: "danger",
        onClick: () => {
          this.entities.splice(index, 1);
          this.entitiesOptions = this.getEntitiesOptions(this.entities);
        },
      };
    },
    addModel(tabIndex) {
      this.entitiesForTabs[tabIndex].models.push(Object.assign({}, this.model));
      this.modelsOptions = this.getModelsOptions(
        tabIndex,
        this.entitiesForTabs[tabIndex].models
      );
    },
    getModelsOptions(tabIndex, models) {
      let options = [];
      for (let i = 0; i < models.length; i++) {
        options.push(this.generateNewModelOptions(tabIndex, i));
      }
      return options;
    },
    generateNewModelOptions(tabIndex, index) {
      return {
        type: "danger",
        icon: "remove",
        class: "removeButton",
        onClick: () => {
          this.entitiesForTabs[tabIndex].models.splice(index, 1);
          this.modelsOptions = this.getEntitiesOptions(
            this.entitiesForTabs[tabIndex].models
          );
        },
      };
    },
    isAddEntityDisabled() {
      return !this.permissions.entities.canCreate || this.entities.length === this.entityLimit;
    },
    isAddModelDisabled(tabIndex) {
      return !this.permissions.models.canCreate || this.entitiesForTabs[tabIndex].models.length === this.modelLimit;
    },
    onFilePicked(event, entityIndex, modelIndex) {
      this.entitiesForTabs[entityIndex].models[modelIndex].file = event.target.files[0];
    },
    getUploadedFileName(entityIndex, modelIndex) {
      return this.entitiesForTabs[entityIndex].models[modelIndex]?.file?.name;
    },
    async saveModels() {
      if (this.isCompleteButtonDisabled()) {
        notify({ message: this.getTranslate("ModelNameError"), type: "error", displayTime: 3000 }, {
          position,
          direction
        })
        return;
      }
      await store.dispatch("setLoader", true);

      const allModels = this.entities.flatMap(x => x.models)

      let existNewModelWithoutFile = allModels.some(x => !x.created && !x.file.name);

      if (!this.userWarnedAboutMissingFile && existNewModelWithoutFile) {
        notify({ message: this.getTranslate("WarningAboutMissingFile"), type: "warning", displayTime: 6000 }, {
          position,
          direction
        });
        this.userWarnedAboutMissingFile = true;
      } else {
        if (ConfigValues.onPremise) {
          await this.uploadFilesOnPremise();
        } else {
          await this.uploadFileCloud();
        }
      }
      this.waitingForResponse = false;
      await store.dispatch("setLoader", false);
    },
    async cancelWizard() {
      if (this.complex.created && !this.editMode) {
        await store.dispatch("setLoader", true);
        await bimAPI.deleteObject({ objectId: this.complex.bimObjectId })
          .catch((error) => {
            console.log("Error deleting just created object: " + this.complex.name);
            console.log(error);
            this.waitingForResponse = false;
            return error.response.status;
          });
        await store.dispatch("setLoader", false);
      }
      this.hidePopup();
    },
    async uploadFileCloud() {
      const tenantID = auth.getTenantID();
      const serviceClientWithSAS = new ShareServiceClient(this.sas);
      let allModelValidationError = false;

      let directoryClient = serviceClientWithSAS.getShareClient(
        ConfigValues.Get("VUE_APP_FILE_STORAGE_SHARE_NAME")
      ).rootDirectoryClient;
      // jump to subdirectory tenantID/complexGuid/

      directoryClient = await this.createAllSubDirectories(directoryClient, tenantID)
        .catch((error) => {
          notify({ message: this.getTranslate("DirectoryFailed") + error, type: "error", displayTime: 5000 },
                 { position,
                   direction });
        })

      let index = 0;
      let delay = false;
      for(const newEntity of this.entitiesForTabs) {
        // jump to subdirectory tenantID/complexGuid/entityGuid
        await this.createSubDirectoryIfNotExist(
          directoryClient,
          this.entities[index].bimObjectId
        );
        const directoryClientEntity = directoryClient.getDirectoryClient(
          this.entities[index].bimObjectId
        );

        for(const newModel of newEntity.models){
          let validationError = false;
          const fileToUpload = newModel.file;
          const hasFileUpload = newModel.file != undefined;

          if(hasFileUpload || newModel.bimObjectId == undefined || this.differentModels.find(m => m == newModel.bimObjectId.toString()) != undefined){
            if(delay){
              await this.sleep(this.sleepDelay);
            }else{
              delay = true;
            }

            const modelCreated = await this.createOrEditModel(
              newModel,
              this.entities[index].bimObjectId,
              hasFileUpload
            );

            if (modelCreated.status == 422) {
              validationError = true;
              allModelValidationError = true
            }
            if (!validationError) {
              // add create bimmodel here
              if (fileToUpload && fileToUpload.name !== undefined) {

                //Validate Revision should be New or Published for IFC file
                const RevisionStatusEnum = GetBimObjectsByTypeResponseComplexRevisionStatusEnum;
                if (newModel.revisionStatus != undefined && !(newModel.revisionStatus == RevisionStatusEnum.New
                  || newModel.revisionStatus == RevisionStatusEnum.Published)) {
                  notify(formatMessage("IFCFileRevisionValidationRule", fileToUpload.name), "error", 5000);
                  return;
                }
                //Validate file size here
                const SevenHundredMegaBytes = 700 * 1024 * 1024;
                if (fileToUpload.size > SevenHundredMegaBytes) {
                  notify(formatMessage("IFCFileSizeValidationRule", fileToUpload.name), "error", 5000);
                  return;
                }
                //validate file name here
                if (!/^[0-9a-zA-Z\s+.#_\-,;()'%~$@]+$/.test(fileToUpload.name)) {
                  notify(this.getTranslate("IFCFileNameValidationRuleForCloud"), "error", 3000);
                  return;
                }
                const fileClient = directoryClientEntity.getFileClient(
                  fileToUpload.name
                );
                if (!(await fileClient.exists())) {
                  await this.createAndUploadFiles(fileClient, fileToUpload, index, modelCreated.bimObjectId, modelCreated.revisionId);
                } else {
                  notify({ message: this.getTranslate("FileAlreadyExists") + fileToUpload.name, type: "warning", displayTime: 5000 }, { position, direction });
                }
              }
              else {
                notify({ message: this.getTranslate("FileUploadFailedNoFile") + newModel.name, type: "warning", displayTime: 5000 }, { position, direction });
                await store.dispatch("setLoader", false);
              }
            } else {
              notify({
                message: this.getTranslate("ComplexValidationFailed") + newModel.name,
                type: "error",
                displayTime: 3000
              }, { position, direction });
              await store.dispatch("setLoader", false);
            }
          }else{console.log("nosave");}
        };
        index++;
      };
      if (!allModelValidationError) {
        this.hidePopup();
      }
    },
    async createAllSubDirectories(directoryClient, tenantID) {
      // jump to subdirectory tenantID/complexGuid/
      await this.createSubDirectoryIfNotExist(directoryClient, tenantID);
      directoryClient = directoryClient.getDirectoryClient(tenantID);
      await this.createSubDirectoryIfNotExist(directoryClient, this.complex.bimObjectId);
      directoryClient = directoryClient.getDirectoryClient(this.complex.bimObjectId);

      return directoryClient;
    },
    async createSubDirectoryIfNotExist(directoryClient, subdirectoryName) {
      let filesAndDirectoriesArray = directoryClient.listFilesAndDirectories();
      const directories = [];
      for await (const item of filesAndDirectoriesArray) {
        if (item.kind === "directory") {
          directories.push(item.name);
        }
      }
      if (
        !directories.find((directoryName) => directoryName === subdirectoryName)
      ) {
        await directoryClient.createSubdirectory(subdirectoryName);
      }
    },
    async createAndUploadFiles(fileClient, fileToUpload, index, bimObjectId, revisionId) {
      await fileClient.create(fileToUpload.size);
      const fileBlob = new Blob([fileToUpload]); //type should be (fileToUpload.type)
      const fileId = uuid.v4();
      const filePath =
        this.complex.bimObjectId +
        "/" +
        this.entities[index].bimObjectId;
      await fileClient.uploadData(fileBlob).then(async () => {
        await this.validateIfcFile(
          fileId,
          "00000000-0000-0000-0000-000000000000",
          fileToUpload,
          bimObjectId,
          revisionId,
          filePath,
          ConfigValues.Get("VUE_APP_B2C_TENANT_NAME")
        );
      });
    },
    async uploadFilesOnPremise() {
      let entityIndex = 0;
      let delay = false;
      for(const newEntity of this.entitiesForTabs) {
        const path = "" + this.complex.bimObjectId + "/" + this.entities[entityIndex].bimObjectId;
        await FileUploadOnPremise.CreateDirectory(path);

        for(const newModel of newEntity.models){
          const fileToUpload = newModel.file;

          const hasFileUpload = newModel.file != undefined;

          if(hasFileUpload || newModel.bimObjectId == undefined || this.differentModels.find(m => m == newModel.bimObjectId.toString()) != undefined){
            if(delay){
              await this.sleep(this.sleepDelay);
            }else{
              delay = true;
            }

            const model = await this.createOrEditModel(newModel, this.entities[entityIndex].bimObjectId, hasFileUpload);

            if (fileToUpload && fileToUpload.name !== undefined) {

              //Validate Revision should be New or Published for IFC file
              const RevisionStatusEnum = GetBimObjectsByTypeResponseComplexRevisionStatusEnum;
              if (newModel.revisionStatus != undefined && !(newModel.revisionStatus == RevisionStatusEnum.New
                || newModel.revisionStatus == RevisionStatusEnum.Published)) {
                notify(formatMessage("IFCFileRevisionValidationRule", fileToUpload.name), "error", 5000);
                return;
              }
              //Validate file size here
              const SevenHundredMegaBytes = 700 * 1024 * 1024;
              if (fileToUpload.size > SevenHundredMegaBytes) {
                notify(formatMessage("IFCFileSizeValidationRule", fileToUpload.name), "error", 5000);
                return;
              }

              await FileUploadOnPremise.UploadFile(path, fileToUpload).then(() => {
                // after file uploaded
                this.validateIfcFile(
                  uuid.v4(),
                  "00000000-0000-0000-0000-000000000000",
                  fileToUpload,
                  model.bimObjectId,
                  model.revisionId,
                  path,
                  ConfigValues.Get("VUE_APP_B2C_TENANT_NAME")
                );
              }, (error) => {
                notify({ message: this.getTranslate("FileUploadFailed") + error, type: "warning", displayTime: 5000 }, {
                  position,
                  direction
                });
              });
            }
          }
        };
        entityIndex++;
      };
      this.hidePopup();
    },
    async createOrEditObject(object, objectType: BimObjectObjectTypeEnum) {
      this.waitingForResponse = true;

      if (object.fmGuid == "") {
        object.fmGuid = uuidv4();
      }

      if (object.externalGuid == "") {
        object.externalGuid = uuidv4();
      }

      const requestBody: BimObjectWithParent = {
        parentId: "",
        usedIdentifier: null,
        bimObject: {
          tenantId: "00000000-0000-0000-0000-000000000000",
          objectType: objectType,
          status: BimObjectStatusEnum.Active,
          externalIdType: BimObjectExternalIdTypeEnum.Guid,
          externalGuid: object.externalGuid,
          fmGuid: object.fmGuid,
          designation: object.id === "" ? null : object.id,
          commonName: object.name,
          revisionId: undefined,
          categoryName: "Site"
        },
      };
      if (objectType === BimObjectObjectTypeEnum.Building) {
        requestBody.parentId = this.complex.bimObjectId;
        requestBody.usedIdentifier = BimObjectWithParentUsedIdentifierEnum.Id;
        requestBody.bimObject.revisionId = this.complex.revisionId;
        requestBody.bimObject.categoryName = "Building";
      }

      if (object.created) {
        const editBimObject: BimObject = {
          bimObjectId: object.bimObjectId,
          objectType: objectType,
          designation: object.id,
          commonName: object.name,
          fmGuid: object.fmGuid,
          externalGuid: object.externalGuid,
          expiredDate: object.expiredDate,
          categoryName: object.categoryName
        };
        await bimAPI.editObject({ hasFileUpload: false, bimObject: editBimObject })
          .then((response) => {
            return response.status;
          })
          .catch((error) => {
            console.log("Error editing object: " + this.complex.name);
            console.log(error);
            this.waitingForResponse = false;
            return error.response.status;
          });

        await this.$_expireObject(object);

        this.waitingForResponse = false;
      } else {
        await bimAPI.addObject({ bimObjectWithParent: requestBody })
          .then((response) => {
            this.waitingForResponse = false;

            object.created = true;
            object.bimObjectId = response.bimObjectId;
            object.revisionId = response.revisionId;

            return response.status;
          })
          .catch((error) => {
            console.log("Error creating object: " + object.name);
            console.log(error);
            this.waitingForResponse = false;
            return error.response.status;
          });
      }
    },
    async createOrEditModel(object, entityGuid, hasFileUpload) {
      this.waitingForResponse = true;
      if (object.created) {
        const editBimObject: BimObject = {
          bimObjectId: object.bimObjectId,
          objectType: BimObjectObjectTypeEnum.Model,
          name: object.name,
          expiredDate: object.expiredDate
        };
        const result = await bimAPI.editObject({ hasFileUpload: hasFileUpload, bimObject: editBimObject })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            console.error("Error creating object: " + object.name);
            console.error(error);
            return error.response;
          });
        await this.$_expireObject(object);
        return result;
      } else {
        const payload: BimObjectWithParent = {
          parentId: entityGuid,
          usedIdentifier: BimObjectWithParentUsedIdentifierEnum.Id,
          bimObject: {
            tenantId: "00000000-0000-0000-0000-000000000000",
            objectType: BimObjectObjectTypeEnum.Model,
            status: BimObjectStatusEnum.Active,
            externalIdType: BimObjectExternalIdTypeEnum.Guid,
            commonName: object.name,
            name: object.name,
            revisionId: this.complex.revisionId,
          },
        };
        const result = await bimAPI.addObject({ bimObjectWithParent: payload })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            console.log("Error creating object: " + object.name);
            console.log(error);
            return error.response;
          });
        return result;
      }
    },
    async validateIfcFile(
      fileId,
      tenantId,
      file,
      modelId,
      revisionId,
      filePath,
      tenantName
    ) {
      let payload = {
        revisionId: revisionId,
        fileId: fileId,
        modelId: modelId,
        filePath: filePath,
        fileName: file.name,
        tenantName: tenantName,
      };

      notify({ message: this.getTranslate("FileUploadStarted") + file.name, type: "success", displayTime: 5000 }, {
        position,
        direction
      });

      await defaultAPI.validateFile({ validateFileRequest: payload })
        .then(() => {
          store.dispatch("setLoader", false);
          notify({ message: this.getTranslate("FileValidationFinished") + file.name, type: "success", displayTime: 5000 }, {
            position,
            direction
          });
        })
        .catch((error) => {
          notify({
            message: this.getTranslate("FileValidationError") + file.name,
            type: "warning",
            displayTime: 5000
          }, { position, direction });
          store.dispatch("setLoader", false);
          this.hidePopup();
          console.error(error);
        });
    },
    hidePopup() {
      this.$emit("hideCreatingProjectPopup");
    },
    backButtonPressed() {
      this.modalStep--;
      this.$emit("changeModalTitle", this.modalStep);
    },
    returnPlaceholder(placeholderName) {
      return { placeholder: formatMessage(placeholderName) };
    },
    popupScrollFix(event) {
      event.stopPropagation();
    },

    getTranslate(text) {
      return formatMessage(text);
    },
    formatMessage,
    loadWizardData() {
      this.complex = this.wizardData;
      this.entities = this.wizardData.entities.map((item, index) => ({ ...item, entityId: index }));
      this.setCompareEntities(this.entities);
      this.addEntitiesForTabs();
      this.modalStep = this.startStep;
    },
    setEmptyFormItems() {
      this.modalStep = 1;
      this.selectedTabIndex = 0;
      this.userWarnedAboutMissingFile = false;
      this.complex = {
        name: "",
        id: "",
        fmGuid: "",
        externalGuid: "",
        created: false
      };
      this.model = {
        name: "",
        file: {},
      };

      this.entity = {
        entityId: 0,
        name: "",
        id: "",
        fmGuid: "",
        externalGuid: "",
        text: "",
        models: [Object.assign({}, this.model)],
      };
      this.entities = [Object.assign({}, this.entity)];
      this.setCompareEntities(this.entities);
      this.entitiesForTabs = [];
    },
    async $_expireObject(object) {
      if (object.expiredDate) {
        const request: ExpireBimObject = {
          bimObjectId: object.bimObjectId,
          expireDate: moment(object.expiredDate).utc(true).toDate()
        }
        await bimAPI.expireObject({ expireObjectRequest: { expireBimObjects: [request]}})
          .catch((error) => {
            console.error(error);
          });
      }
    }
  },
};
