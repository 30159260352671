
import { formatMessage } from "devextreme/localization";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import { defineComponent } from "vue";
import { DxTextArea } from "devextreme-vue/text-area";
import { DxButton } from "devextreme-vue";
import ConfigValues from "../../utils/configValues";
import notify from "devextreme/ui/notify";
// @ts-ignore
import FileUploadOnPremise from "@/utils/fileUploadOnPremise";
import { fileAPI, defaultAPI } from "@/utils/api";
import { ShareServiceClient } from "@azure/storage-file-share";
import store from "../../store";
import { uuid } from "vue-uuid";
import auth from "../../authsrc/auth";

const position = "bottom center";
const direction = "up-push";

export default defineComponent({
  name: "ImportExcel",
  components: {
    DxFileUploader,
    DxTextArea,
    DxButton
  },
  props: {
    modelIdProp: {
      type: Object,
    },
    complexIdProp: {
      type: Object,
    },
    buildingIdProp: {
      type: Object,
    }
  },
  emits: ["fileSelected"],
  data() {
    return {
      selectedFile: null,
      submitButtonText: "Upload",
      excelHints: this.getTranslate("ImportExcelHelp"),
      modelId: null,
      buildingId: null,
      complexId: null,
      routePath: "/ifcfiles"
    };
  },
  watch: {
    modelIdProp(newValue) {
      this.modelId = newValue;
    },
    complexIdProp(newValue) {
      this.complexId = newValue;
    },
    buildingIdProp(newValue) {
      this.buildingId = newValue;
    }
  },
  methods: {
    getTranslate(text) {
      return formatMessage(text);
    },
    async uploadFilesOnPremise() {
      let fileToUpload = this.selectedFile;
      const path = "" + this.complexId +"/" + this.buildingId +"/" + this.modelId;
      await FileUploadOnPremise.CreateDirectory(path);

      await FileUploadOnPremise.UploadFile(path, fileToUpload).then(() => {
        // after file uploaded
        this.validateExcelFile(
          uuid.v4(),
          "00000000-0000-0000-0000-000000000000",
          fileToUpload,
          this.modelId,
          this.$route.query.revisionId,
          path
        );
      }, (error) => {
        notify({ message: this.getTranslate("FileUploadFailed") + error, type: "warning", displayTime: 5000 }, {
          position,
          direction
        });
        this.onUploadSuccess();
      });
      store.dispatch("setLoader", false);
    },
    onUploadSuccess() {
      this.$router.push(this.routePath);
    },
    async uploadFileCloud() {
      const tenantID = auth.getTenantID();
      const data = await fileAPI.getToken();
      const sas = data.sasToken;
      const serviceClientWithSAS = new ShareServiceClient(sas);

      let directoryClient = serviceClientWithSAS.getShareClient(ConfigValues.Get("VUE_APP_FILE_STORAGE_SHARE_NAME")).rootDirectoryClient;

      directoryClient = await this.createAllSubDirectories(directoryClient, tenantID)
        .catch((error) => {
          store.dispatch("setLoader", false);
          notify({ message: this.getTranslate("DirectoryFailed") + error, type: "error", displayTime: 5000 }, { position, direction });
        });
      const fileClient = directoryClient.getFileClient(this.selectedFile.name);
      if ((await fileClient.exists())) {
        store.dispatch("setLoader", false);
        notify({ message: this.getTranslate("FileAlreadyExists") + this.selectedFile.name, type: "error", displayTime: 5000 }, { position, direction });
      } else {
        await this.createAndUploadFiles(fileClient, this.selectedFile);
      }
    },
    async createAndUploadFiles(fileClient, fileToUpload) {
      await fileClient.create(fileToUpload.size);
      const fileBlob = new Blob([fileToUpload]);
      const fileId = uuid.v4();
      const filePath =  this.complexId + "/" + this.buildingId;

      await fileClient.uploadData(fileBlob).then(async () => {
        await this.validateExcelFile(
          fileId,
          "00000000-0000-0000-0000-000000000000",
          fileToUpload,
          this.modelId,
          this.$route.query.revisionId,
          filePath
        );
      });
      store.dispatch("setLoader", false);
      this.onUploadSuccess();
    },
    async createAllSubDirectories(directoryClient, tenantID) {
      // jump to subdirectory tenantid/complexid/buildingid
      await this.createSubDirectoryIfNotExist(directoryClient, tenantID);
      directoryClient = directoryClient.getDirectoryClient(tenantID);
      await this.createSubDirectoryIfNotExist(directoryClient, this.complexId);
      directoryClient = directoryClient.getDirectoryClient(this.complexId);
      await this.createSubDirectoryIfNotExist(directoryClient, this.buildingId);
      directoryClient = directoryClient.getDirectoryClient(this.buildingId);

      return directoryClient;
    },
    async createSubDirectoryIfNotExist(directoryClient, subdirectoryName) {
      let filesAndDirectoriesArray = directoryClient.listFilesAndDirectories();
      const directories = [];
      for await (const item of filesAndDirectoriesArray) {
        if (item.kind === "directory") {
          directories.push(item.name);
        }
      }
      if (!directories.find((directoryName) => directoryName === subdirectoryName)) {
        await directoryClient.createSubdirectory(subdirectoryName);
      }
    },
    onFileSelected(event) {
      if(!event.value[0].name.endsWith(".xlsx")) {
        notify({ message: this.getTranslate("InvalidFileType"), type: "error", displayTime: 5000 }, { position, direction });
      } else {
        this.selectedFile = event.value[0];
        this.submitButtonText = "Upload "+event.value[0].name;
      }
    },
    async onSubmitClicked() {
      if(this.selectedFile) {
        store.dispatch("setLoader", true);
        if (ConfigValues.onPremise) {
          await this.uploadFilesOnPremise();
        } else {
          await this.uploadFileCloud();
        }
      } else {
        notify({ message: this.getTranslate("PleaseSelect"), type: "error", displayTime: 5000 }, { position, direction });
      }
    },
    async validateExcelFile(
      fileId,
      tenantId,
      file,
      modelId,
      revisionId,
      filePath
    ) {
      let payload = {
        revisionId: revisionId,
        fileId: fileId,
        modelId: modelId,
        filePath: filePath,
        fileName: file.name
      };

      notify({ message: this.getTranslate("FileUploadStarted") + file.name, type: "success", displayTime: 5000 }, {
        position,
        direction
      });

      await defaultAPI.validateFile({ validateFileRequest: payload })
        .then(() => {
          store.dispatch("setLoader", false);
          notify({ message: this.getTranslate("FileValidationFinished") + file.name, type: "success", displayTime: 5000 }, {
            position,
            direction
          });
        })
        .catch((error) => {
          notify({
            message: this.getTranslate("FileValidationError") + file.name,
            type: "warning",
            displayTime: 5000
          }, { position, direction });
          console.error(error);
        });
    },
  }
});
