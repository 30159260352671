import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b96bfee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-block" }
const _hoisted_2 = { class: "content-block" }
const _hoisted_3 = { class: "categories-step dx-card responsive-paddings" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComplexList = _resolveComponent("ComplexList")!
  const _component_CreateProject = _resolveComponent("CreateProject")!
  const _component_DxPopup = _resolveComponent("DxPopup")!
  const _component_CategoriesList = _resolveComponent("CategoriesList")!
  const _component_DxButton = _resolveComponent("DxButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ComplexList, {
      ref: _ctx.complexListRefKey,
      onCreateProject: _ctx.showPopup,
      onGoToDataGrid: _ctx.openCategoriesSelection
    }, null, 8, ["onCreateProject", "onGoToDataGrid"]),
    _createVNode(_component_DxPopup, {
      visible: _ctx.isAddComplexModalVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isAddComplexModalVisible) = $event)),
      name: "createProjectModal",
      title: _ctx.modalTitle,
      height: "auto",
      "max-width": "1300px",
      "max-height": "800px",
      "content-template": "popup-content",
      "close-on-outside-click": false,
      onHidden: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPopupHiding()))
    }, {
      "popup-content": _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_CreateProject, {
            "popup-visible": _ctx.isAddComplexModalVisible,
            "edit-mode": _ctx.editMode,
            "start-step": _ctx.modalStep,
            "wizard-data": _ctx.wizardData,
            onHideCreatingProjectPopup: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideCreateProjectPopup())),
            onChangeModalTitle: _ctx.changeModalTitle
          }, null, 8, ["popup-visible", "edit-mode", "start-step", "wizard-data", "onChangeModalTitle"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "title"]),
    _createVNode(_component_DxPopup, {
      visible: _ctx.isCategoriesPopupVisible,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isCategoriesPopupVisible) = $event)),
      name: "selectCategoriesModal",
      title: _ctx.categoriesPopupTitle,
      height: "auto",
      "max-width": "1300px",
      "max-height": "800px",
      "hide-on-outside-click": false,
      "content-template": "categories-popup-content",
      onShown: _ctx.categoriesPopupShown
    }, {
      "categories-popup-content": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("div", _hoisted_3, [
            _createVNode(_component_CategoriesList, {
              "bim-data-array-prop": _ctx.modelIdsDataProp,
              "clear-categories-prop": _ctx.clearCategories,
              onChangedSelectedCategories: _cache[3] || (_cache[3] = ($event: any) => (_ctx.categoriesSelected($event))),
              onCategoriesLoaded: _cache[4] || (_cache[4] = ($event: any) => (_ctx.categoriesLoadedHander()))
            }, null, 8, ["bim-data-array-prop", "clear-categories-prop"]),
            _createVNode(_component_DxButton, {
              id: "nextButton",
              class: "next-btn",
              type: "success",
              text: _ctx.formatMessage('ReusableNextButtonLabel'),
              disabled: !_ctx.selectedCategories.length,
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goToDataGrid()))
            }, null, 8, ["text", "disabled"]),
            _createVNode(_component_DxButton, {
              id: "cancelButton",
              class: "cancel-btn",
              type: "",
              text: _ctx.formatMessage('ReusableCancelButtonLabel'),
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onCategoriesPopupHiding()))
            }, null, 8, ["text"])
          ], 512), [
            [_vShow, _ctx.isCategoriesPopupVisible]
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "title", "onShown"])
  ]))
}